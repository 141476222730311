import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { UserService } from '../services/portal/user.service';
import { LocalStorageService } from '../services/shared/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(
    private router: Router,
    private _userService: UserService,
    private _localStorageService: LocalStorageService
  ) {}
  public async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    let authenticated: boolean = await this._userService.isAuthenticated();
    if (authenticated) {
      return true;
    } else {
      this.router.navigate(['/login/portal']);
      return false;
    }
  }
  public async canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    let authenticated: boolean = await this._userService.isAuthenticated();

    const lastIndexOfSlash = state.url.lastIndexOf('/');
    const unAuthUrl =
      state.url.substring(0, lastIndexOfSlash) + '/not-authorized';
    if (authenticated) {
      //Does user have the permission
      const requiredRoles = childRoute.data['permissions'];
      let userCurrPermission =
        this._localStorageService.getData<string>('currentPermission') ||
        'none';
      if (requiredRoles.includes(userCurrPermission)) {
        return true;
      } else {
        this.router.navigate([unAuthUrl]);
        return false;
      }
    } else {
      this.router.navigate(['/login/portal']);
      return false;
    }
  }
}
