import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

interface IForm {
  id: string;
  label: string;
  placeholder: string;
  maxLength: number;
  minLength: number;
  type: string;
  imp: boolean;
}

@Component({
  selector: 'app-contact-form-consent',
  templateUrl: './contact-form-consent.component.html',
})
export class ContactFormConsentComponent implements OnInit {
  private apiUrl: string = environment.apiUrlCommon;
  private studyId: string;
  @Input() settings: any = {};
  @Input() selectedSite: any = {};

  contactMethod!: string;
  success: boolean = false;
  fields: IForm[] = [
    {
      id: 'name',
      label: 'Förnamn och efternamn',
      placeholder: 'T.ex. Anders Karlsson',
      maxLength: 250,
      minLength: 2,
      type: 'text',
      imp: false,
    },
    {
      id: 'phone',
      label: 'Telefonnummer (endast siffror)',
      placeholder: 'T.ex. 0701231212',
      maxLength: 13,
      minLength: 8,
      type: 'tel',
      imp: true,
    },
    {
      id: 'email',
      label: 'E-post',
      placeholder: 'T.ex. joe.doe@example.com',
      maxLength: 255,
      minLength: 2,
      type: 'email',
      imp: false,
    },
    {
      id: 'postalNumber',
      label: 'Postnummer',
      placeholder: 'T.ex. 177 44',
      maxLength: 6,
      minLength: 2,
      type: 'text',
      imp: false,
    },
  ];

  constructor(
    private router: Router,
    private http: HttpClient,
  ) {
    this.studyId = this.router.url.split('/')[3];
  }

  ngOnInit(): void {
    if (this.settings && this.settings.consentFlowMethod) {
      this.contactMethod = this.settings.consentFlowMethod;
    }
  }

  get shouldDisplayButtons(): boolean {
    return (
      (this.settings.consentFlowMethod === 'contact' && this.settings.showInterestOption) ||
      (this.settings.consentFlowMethod === 'interest-form' && this.settings.showContactOption)
    );
  }

  toggleForm(method: string) {
    this.contactMethod = method;
  }

  onSubmitForm(form: NgForm) {
    const interestFormData = {
      studyId: this.studyId,
      siteId: this.selectedSite.id,
      ...form, // Make sure to use form.value
    };

    this.http
      .post<any>(
        `${this.apiUrl}/studies/${this.studyId}/sites/${this.selectedSite.id}/study-interest`,
        interestFormData,
      )
      .subscribe({
        next: () => (this.success = true),
        error: (error) => console.error('Error submitting form', error),
      });
  }
}
