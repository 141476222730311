<div *ngIf="isLoading">
  <p class="text-sm text-gray-900">Läkaren signerar, vänligen vänta tills processen är klar.</p>
  <div class="mt-4 flex justify-center">
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <style>
        .spinner_Uvk8 {
          animation: spinner_otJF 1.6s cubic-bezier(0.52, 0.6, 0.25, 0.99) infinite;
        }
        .spinner_ypeD {
          animation-delay: 0.2s;
        }
        .spinner_y0Rj {
          animation-delay: 0.4s;
        }
        @keyframes spinner_otJF {
          0% {
            transform: translate(12px, 12px) scale(0);
            opacity: 1;
          }
          75%,
          100% {
            transform: translate(0, 0) scale(1);
            opacity: 0;
          }
        }
      </style>
      <path
        class="spinner_Uvk8"
        d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,20a9,9,0,1,1,9-9A9,9,0,0,1,12,21Z"
        transform="translate(12, 12) scale(0)"
      />
      <path
        class="spinner_Uvk8 spinner_ypeD"
        d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,20a9,9,0,1,1,9-9A9,9,0,0,1,12,21Z"
        transform="translate(12, 12) scale(0)"
      />
      <path
        class="spinner_Uvk8 spinner_y0Rj"
        d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,20a9,9,0,1,1,9-9A9,9,0,0,1,12,21Z"
        transform="translate(12, 12) scale(0)"
      />
    </svg>
  </div>
</div>

<div *ngIf="!isLoading" class="mt-4">
  <ng-container *ngIf="errorMessage; else loading">
    <app-alerts
      [type]="'danger'"
      [message]="errorMessage"
      [closeable]="false"
      [largeText]="false"
      class="col-span-2"
    ></app-alerts>
  </ng-container>

  <ng-template #loading>
    <p class="text-sm text-gray-900">Det tog för lång tid utan någon respons.</p>
  </ng-template>
  <div class="mt-6 flex justify-center">
    <button
      type="button"
      (click)="restartPolling()"
      class="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-sky-600 shadow-sm ring-1 ring-inset ring-sky-300 hover:bg-sky-50"
    >
      Klicka här för att starta igen
    </button>
  </div>
</div>

<ng-container *ngIf="isComplete">
  <p>Klart, går vidare till nästa steg.</p>
</ng-container>
