import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakProfile } from 'keycloak-js';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  public isPatientLoggedIn = false;
  public userProfile: KeycloakProfile | null = null;
  public userRoles: any;
  showProfileDropdown?: boolean;
  mobileNavigation?: boolean;

  constructor(
    private router: Router,
    private readonly keycloakService: KeycloakService
  ) {}

  public async ngOnInit() {
    this.isPatientLoggedIn = await this.keycloakService.isLoggedIn();
    if (this.isPatientLoggedIn) {
      this.userProfile = await this.keycloakService.loadUserProfile();
      this.userRoles = this.keycloakService.getUserRoles();
    }
    this.router.events.subscribe(async (event) => {
      if (event.constructor.name === 'NavigationEnd') {
        this.isPatientLoggedIn = await this.keycloakService.isLoggedIn();
      }
    });
  }
  /* 
    ---- 
    Functions
    ---- 
  */
  openMenu() {
    this.mobileNavigation = true;
  }
  closeMenu() {
    this.mobileNavigation = false;
  }
  onOpenProfile() {
    this.showProfileDropdown = !this.showProfileDropdown;
  }
  /* 
    ---- 
    APIs
    ---- 
  */
  public logout() {
    localStorage.removeItem('redirectUri');
    this.keycloakService.logout(window.location.origin);
  }
}
