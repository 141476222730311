import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-input-field',
  templateUrl: './input-field.component.html',
})
export class InputFieldComponent {
  @Input() label: string = '';
  @Input() placeholder: string = '';
  @Input() id: string = '';
  @Input() maxLength: number | null = null;
  @Input() control!: AbstractControl;
  @Input() type: string = 'text';
  @Input() errorMessages: { [key: string]: string } = {};

  getErrorMessage(): string | null {
    if (this.control.errors) {
      for (const error in this.control.errors) {
        if (this.errorMessages[error]) {
          return this.errorMessages[error];
        }
      }
    }
    return null;
  }
}
