import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { IConsentFlowsRequest } from 'src/app/core/models/consent.model';
import { IFpi } from 'src/app/core/models/fpi.model';
import { ISite } from 'src/app/core/models/site.model';
import { ISettings } from 'src/app/core/models/study-settings.model';
import { ConsentFlowService } from 'src/app/core/services/portal/consent-flow.service';
import { ConsentService } from 'src/app/core/services/portal/consent.service';
import { DownloadFileService } from 'src/app/core/services/shared/download-file.service';

@Component({
  selector: 'app-final-step',
  templateUrl: './final-step.component.html',
})
export class FinalStepComponent {
  @Input() settings!: ISettings;
  @Input() fpi!: IFpi;
  @Input() consentFlowType: string = '';
  @Output() stepComplete: EventEmitter<void> = new EventEmitter();

  consent: IConsentFlowsRequest | null = null;
  dependent: any;
  site: ISite | null = null;
  private subscriptions: Subscription = new Subscription();

  constructor(
    private _consentService: ConsentService,
    private _consentFlowService: ConsentFlowService,
    private _downloadFileService: DownloadFileService,
  ) {}

  ngOnInit() {
    this.subscriptions.add(
      this._consentFlowService.consentFlow$.subscribe((consent) => {
        if (!consent) {
          this._consentFlowService.consent$.subscribe((consent) => {
            this.consent = consent;
          });
        } else {
          this.consent = consent;
        }
      }),
    );

    this.subscriptions.add(
      this._consentFlowService.dependent$.subscribe((resp) => {
        if (resp && resp.dependent) {
          this.dependent = resp.dependent;
        }
      }),
    );
    this.subscriptions.add(
      this._consentFlowService.currentSiteSelection$.subscribe((site) => {
        this.site = site;
      }),
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  onOpenLinkInNewWindow(url: string) {
    window.open(url, '_blank');
  }

  downloadConsentPDF() {
    if (this.consent && this.consent.id) {
      this._consentService
        .getConsentPDF(this.fpi.studyId, this.fpi.siteId, this.consent.id)
        .subscribe((result: any) => {
          const filename: string = this._downloadFileService.getFileName(result);
          const binaryData = [];
          binaryData.push(result.body);
          const downloadLink = document.createElement('a');
          const blob = new Blob(binaryData, { type: 'application/pdf' });
          const url = window.URL.createObjectURL(blob);
          downloadLink.href = url;
          downloadLink.setAttribute('download', filename);
          document.body.appendChild(downloadLink);
          downloadLink.click();
        });
    }
  }

  signNext() {
    this.stepComplete.emit();
  }
}
