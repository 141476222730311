<div class="divide-y-2 divide-gray-200">
  <h2 class="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">Vanliga frågor</h2>
  <div class="mt-6 pt-10">
    <dl class="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-12">
      <div *ngFor="let item of faqs">
        <dt class="text-lg leading-6 font-medium text-gray-900">{{ item.question }}</dt>
        <dd class="mt-2 text-base text-gray-500">{{ item.answer }}</dd>
      </div>
    </dl>
  </div>
</div>