import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class StudyService {
  private apiUrlCommon: string = environment.apiUrlCommon + '/studies';
  private apiUrlCom: string = environment.apiUrlCommon;

  constructor(private http: HttpClient) {}

  //  -- studies (open)
  getOpenStudies() {
    return this.http.get<any>(`${this.apiUrlCommon}?status=open`);
  }
  //  -- studies and settings (open)
  getOpenStudiesSettings() {
    return this.http.get<any>(`${this.apiUrlCommon}-settings?status=open`);
  }
  //  -- study
  getStudy(studyId: string) {
    return this.http.get<any>(`${this.apiUrlCom}/study/${studyId}`);
  }
  //  -- study setting
  getStudySetting(studyId: string): Observable<any> {
    return this.http.get<any>(`${this.apiUrlCommon}/${studyId}/setting`);
  }
  //  -- study setting
  getStudySlug(slug: string) {
    return this.http.get<any>(`${this.apiUrlCom}/study/slug/${slug}`);
  }
  //  -- Study sites
  getStudySites(studyId: string) {
    return this.http.get<any>(`${this.apiUrlCommon}/${studyId}/sites`);
  }
  //  -- Study site info
  getStudySiteInfo(studyId: string, siteId: string) {
    return this.http.get<any>(`${this.apiUrlCom}/${studyId}/sites/${siteId}`);
  }
  //  -- Study site fpis
  getLatestFpi(studyId: string, siteId: string) {
    return this.http.get<any>(`${this.apiUrlCommon}/${studyId}/sites/${siteId}/fpis?latest-published=true`);
  }
  //  -- Study site fpi pdf
  getFpiPDF(studyId: string, siteId: string, fpiId: string) {
    return this.http.get(`${this.apiUrlCommon}/${studyId}/sites/${siteId}/fpis/${fpiId}/pdf`, {
      responseType: 'blob',
    });
  }
}
