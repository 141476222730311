import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EicGuard } from './core/guards/eic.guard';
import { PortalGuard } from './core/guards/portal.guard';

/* Components */
import { NavbarComponent } from './layout/portal/navbar/navbar.component';
import { PageNotFoundComponent } from './routes/errors/page-not-found.component';
import { NotAuthorizedComponent } from './routes/errors/not-authorized.component';
import { FaqComponent } from './routes/portal/faq/faq.component';
import { ProfileComponent } from './routes/portal/profile/profile.component';
import { AboutComponent } from './routes/portal/about/about.component';
import { AuthGuard } from './core/guards/auth.guard';
import { AdminGuard } from './core/guards/admin.guard';

const routes: Routes = [
  /* Portal */
  {
    path: '',
    component: NavbarComponent,
    children: [
      {
        path: 'about',
        component: AboutComponent,
      },
      {
        path: '',
        loadChildren: () => import('./routes/portal/studies/studies.module').then((m) => m.StudiesModule),
        canActivate: [PortalGuard],
      },
      {
        path: 'faq',
        component: FaqComponent,
      },
      {
        path: 'profile',
        component: ProfileComponent,
        canActivate: [PortalGuard, AuthGuard],
        data: { roles: ['patient'] },
      },
    ],
  },
  /* Auth */
  {
    path: 'login',
    loadChildren: () => import('./routes/auth/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'create-account',
    loadChildren: () => import('./routes/auth/create-account/create-account.module').then((m) => m.CreateAccountModule),
    canActivate: [AuthGuard],
  },
  /* Eic */
  {
    path: 'eic',
    loadChildren: () => import('./routes/eIC/eic.module').then((m) => m.EicModule),
    canActivate: [EicGuard],
    data: { roles: ['doctor'] },
  },
  /* System Admin */
  {
    path: 'admin',
    loadChildren: () => import('./routes/admin/admin.module').then((m) => m.AdminModule),
    canActivate: [AdminGuard],
    data: { roles: ['admin'] },
  },
  /* Error pages */
  {
    path: 'not-authorized',
    component: NotAuthorizedComponent,
  },
  {
    path: '**',
    component: PageNotFoundComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      anchorScrolling: 'enabled',
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
