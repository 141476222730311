import { ViewportScroller } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { firstValueFrom } from 'rxjs/internal/firstValueFrom';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { IFpi } from 'src/app/core/models/fpi.model';
import { EConsentFlowMethod, ISettings } from 'src/app/core/models/study-settings.model';
import { IStudy } from 'src/app/core/models/study.model';
import { IUser } from 'src/app/core/models/user.model';
import { StudyService } from 'src/app/core/services/portal/study.service';
import { UserService } from 'src/app/core/services/portal/user.service';
import { ConsentService } from 'src/app/core/services/portal/consent.service';
import { ConsentFlowService } from 'src/app/core/services/portal/consent-flow.service';
import { EFlowType } from 'src/app/core/models/consent.model';
import { HelperService } from 'src/app/core/services/shared/helper.service';

@Component({
  selector: 'app-study-journey',
  templateUrl: './study-journey.component.html',
})
export class StudyJourneyComponent implements OnInit, OnDestroy {
  private studyId!: string;
  study?: IStudy;
  settings?: ISettings;
  isUserLoggedIn: boolean = false;
  userProfile?: IUser;
  fpiSelected?: IFpi;
  consentFlowType?: EFlowType;
  showLogin: boolean = false;
  showStepper: boolean = false;
  ageNotInSpanMessage: string = '';

  constructor(
    private activatedRoute: ActivatedRoute,
    private readonly keycloakService: KeycloakService,
    private viewportScroller: ViewportScroller,
    private _consentService: ConsentService,
    private _consentFlowService: ConsentFlowService,
    private _userService: UserService,
    private _studyService: StudyService,
    private _helperService: HelperService
  ) {
    this.activatedRoute.params.subscribe((params) => {
      this.studyId = params['studyId'];
    });
    this.activatedRoute.queryParams.subscribe((qParams) => {
      this.showLogin = qParams['site'] ? true : false;
    });
  }

  async ngOnInit() {
    await this.loadStudyData();
    await this.checkLoginStatus();

    this.activatedRoute.fragment.subscribe((fragment) => {
      if (fragment) {
        setTimeout(() => this.viewportScroller.scrollToAnchor(fragment), 100);
      }
    });
  }

  ngOnDestroy() {
    this._consentFlowService.setConsent(null);
    this._consentFlowService.setConsentFlow(null);
    this._consentFlowService.setConsentTransitionLink(null);
    this._consentFlowService.setConsentInitiated(null);
    this._consentFlowService.setFpiRead(false);
    this._consentFlowService.setResetFamilyFlow(false);
    this._consentFlowService.setUserChildren(null);
    this._consentFlowService.setDependent(null);
    this._consentFlowService.setCurrentSiteSelection(null);
  }

  private async checkLoginStatus() {
    this.isUserLoggedIn = await this.keycloakService.isLoggedIn();
    if (this.isUserLoggedIn) {
      const username = this.keycloakService.getUsername();
      try {
        const response = await firstValueFrom(this._userService.getUserInfo(username));
        this.userProfile = response.data;
      } catch (error) {
        console.error('Error fetching user info', error);
      }
    }
  }

  private async loadStudyData() {
    forkJoin({
      study: this._studyService.getStudy(this.studyId),
      settings: this._studyService.getStudySetting(this.studyId),
    }).subscribe({
      next: ({ study, settings }) => {
        this.study = study;
        this.settings = settings;
      },
      error: (error) => {
        console.error('Error loading study data', error);
      },
    });
  }

  onFpiSelected(fpi: IFpi) {
    this.fpiSelected = fpi;
    if (this.settings) {
      this.determineConsentType(this.settings);
    }
  }

  checkPatientAge(settings: ISettings) {
    if (this.userProfile) {
      const adultAge = this._helperService.getAge(this.userProfile.username);
      const insideAgeSpan = adultAge >= settings.fromAge && adultAge <= settings.toAge;
      this._consentFlowService.setAdultAgeCheck(insideAgeSpan);
      return insideAgeSpan;
    }
    return;
  }
  /*
    **Step-by-Step logic determining consent flow**
    1. Check studyAge and fromAge & toAge to decide potential default flow:
      - If fromAge is equal to or greater than studyAge, default to patient flow.
      - If fromAge is less than studyAge, then consider family flow if other conditions are met.
    
    2.  Invoke /family-tree API to check if the user has children:
      - If the user has children and fromAge is less than studyAge, default to family flow.
      - If no children are returned or fromAge is greater than or equal to studyAge, default to patient flow.
  */
  determineConsentType(settings: ISettings) {
    if (
      settings.consentFlowMethod === EConsentFlowMethod.InterestForm ||
      settings.consentFlowMethod === EConsentFlowMethod.Contact
    ) {
      this.consentFlowType = EFlowType.DoctorPatient;
      return;
    } else if (settings.consentFlowMethod === EConsentFlowMethod.ESignature) {
      const { fromAge, childAgeLimit } = settings;

      if (fromAge >= childAgeLimit) {
        if (!this.checkPatientAge(settings)) {
          this.showStepper = true;
          this.ageNotInSpanMessage = `Du kan inte delta i den här studien; accepterad åldersgräns är ${settings.fromAge} - ${settings.toAge} år`;
          return;
        } else {
          this.consentFlowType = EFlowType.Patient;
          return;
        }
      }

      if (this.fpiSelected) {
        const { id: fpiId, studyId, siteId } = this.fpiSelected;
        this._consentService
          .getDependents({ studyId, siteId, fpiId })
          .subscribe(
            (dependents) => {
              const flowType = dependents.dependentsWithConsents?.length > 0 ? EFlowType.Family : EFlowType.Patient;
              if (!this.checkPatientAge(settings) && flowType === EFlowType.Patient) {
                this.showStepper = true;
                this.ageNotInSpanMessage = `Du kan inte delta i den här studien; accepterad åldersgräns är ${settings.fromAge} - ${settings.toAge} år`;
                return;
              } else {
                this.consentFlowType = flowType;
                return;
              }
            }
          );
      }
    }
  }
}
