<button
  (click)="onClick.emit()"
  [type]="type"
  [disabled]="disabled"
  [ngClass]="[
    fullWidth ? 'w-full justify-center' : '',
    textLarge ? 'text-base' : 'text-sm',
    color
      ? btnColor
      : 'border border-gray-300 bg-white text-gray-700 hover:bg-gray-50 disabled:bg-inherit'
  ]"
  class="inline-flex items-center rounded-md px-4 py-2 font-medium shadow-sm disabled:opacity-50 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2 active:opacity-75"
>
  {{ label }}
</button>
