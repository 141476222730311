<div class="flex">
  <!-- Loading left -->
  <div class="animate-pulse hidden md:block">
    <div class="rounded-md bg-slate-200 h-[550px] w-80"></div>
  </div>

  <!-- Loading right -->
  <div class="animate-pulse w-full pb-6 sm:pl-6 lg:pl-8 xl:pl-6">
    <div class="flex items-center space-x-6">
      <div class="rounded-full bg-slate-200 h-10 w-16"></div>
      <div class="flex-1 space-y-6 py-1">
        <div class="h-4 bg-slate-200 rounded"></div>
      </div>
    </div>
    <div class="py-10 flex-1 space-y-6 py-1">
      <div class="h-3 bg-slate-200 rounded"></div>
      <div class="space-y-3">
        <div class="grid grid-cols-3 gap-4">
          <div class="h-3 bg-slate-200 rounded col-span-2"></div>
          <div class="h-3 bg-slate-200 rounded col-span-1"></div>
        </div>
        <div class="h-3 bg-slate-200 rounded"></div>
        <div class="grid grid-cols-3 gap-4">
          <div class="h-3 bg-slate-200 rounded col-span-2"></div>
        </div>
      </div>
    </div>
  </div>
</div>
