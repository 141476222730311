import { Component, Input, OnInit, HostListener } from '@angular/core';

@Component({
  selector: 'app-fpi-viewer',
  templateUrl: './fpi-viewer.component.html',
})
export class FpiViewerComponent implements OnInit {
  private _fpiPDF!: string;
  private _loadingEmbedPdf: boolean = false;

  @Input() studyName?: string;
  @Input() fpiDownloadLink?: string;

  @Input()
  set fpiPDF(value: string) {
    this._fpiPDF = value;
  }
  get fpiPDF(): string {
    return this._fpiPDF;
  }

  @Input()
  set loadingEmbedPdf(value: boolean) {
    this._loadingEmbedPdf = value;
  }
  get loadingEmbedPdf(): boolean {
    return this._loadingEmbedPdf;
  }

  viewerHeight: string = '500px';

  ngOnInit() {
    this.resizeViewerHeight();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.resizeViewerHeight();
  }

  resizeViewerHeight() {
    const height = window.innerHeight;
    this.viewerHeight = `${height - 100}px`;
  }

  viewFpiPdf() {
    window.open(this.fpiPDF);
  }
}
