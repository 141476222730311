<div *ngIf="selectedOption !== ''" class="absolute right-8 -top-[42px] sm:right-10">
  <button
    type="button"
    (click)="onChangeSelection()"
    class="rounded bg-white px-2 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:text-sm"
  >
    Ändra val
  </button>
</div>

<fieldset *ngIf="selectedOption === ''">
  <legend class="sr-only">Select flow</legend>
  <div class="space-y-4">
    <label
      *ngFor="let option of flowOptions"
      [ngClass]="[
        selectedOption === option.value ? 'border-transparent border-sky-500 ring-2 ring-sky-500' : 'border-gray-300',
        guardianConsent?.status === 'complete' && option.value === 'patient'
          ? 'cursor-not-allowed bg-gray-50'
          : 'cursor-pointer bg-white'
      ]"
      class="relative block rounded-lg border px-6 py-4 shadow-sm focus:outline-none sm:flex sm:justify-between"
    >
      <input
        type="radio"
        name="flow-option"
        (change)="selectFlow(option)"
        [value]="option.value"
        class="sr-only"
        [disabled]="guardianConsent?.status === 'complete' && option.value === 'patient'"
      />
      <span class="flex items-center">
        <span class="flex flex-col text-sm">
          <span class="font-medium text-gray-900">{{ option.title }}</span>
          <span class="text-gray-500">
            <span class="block sm:inline">{{ option.text }}</span>
          </span>
        </span>
      </span>
      <span
        [ngClass]="[selectedOption === option.value ? 'border border-sky-500' : 'border-2 border-transparent']"
        class="pointer-events-none absolute -inset-px rounded-lg"
        aria-hidden="true"
      ></span>
    </label>
  </div>
</fieldset>

<div *ngIf="selectedOption === 'family'">
  <p class="mt-4 text-sm">
    Här kan du lämna samtycke för barn som du är vårdnadshavare för. För barnet måste båda vårdnadshavarna signera
    samtycket (om du inte har ensam vårdnad). Om den andra vårdnadshavaren redan lämnat samtycke så ser du detta under
    "Påbörjade signeringar".
  </p>
  <p class="mt-4 text-sm font-medium">Påbörjade signeringar</p>
  <app-alerts
    *ngIf="errorMessage.signatureExists != ''"
    [margin]="'mt-2'"
    [type]="'danger'"
    [largeText]="false"
    [message]="errorMessage.signatureExists"
    (onClick)="errorMessage.signatureExists = ''"
  ></app-alerts>
  <p *ngIf="childrenWithConsents.length === 0" class="mt-1 text-sm italic">Det finns inga påbörjade samtycken</p>

  <div class="mt-2 overflow-hidden bg-white shadow sm:rounded-md">
    <ul role="list" class="divide-y divide-gray-200">
      <li *ngFor="let data of childrenWithConsents; let i = index">
        <a role="button" (click)="onContinueOtherParent(data)" type="button" class="block hover:bg-gray-50">
          <div class="flex items-center p-2">
            <div class="flex min-w-0 flex-1 items-center">
              <div class="min-w-0 flex-1 px-2 md:grid md:grid-cols-2">
                <div>
                  <p class="truncate text-sm font-medium text-gray-600">
                    {{ data.dependent.firstName }} {{ data.dependent.lastName }}
                  </p>
                  <p class="text-xs text-gray-500">
                    <span class="truncate">
                      {{ data.dependent.personalNumber | slice: 0 : 8 }}
                      {{ ' - ' }}
                    </span>
                    <span *ngFor="let _ of [].constructor(4)" class="text-lg tracking-wide text-gray-900">&bull;</span>
                  </p>
                </div>
                <div class="hidden md:block">
                  <div class="flex">
                    <div class="flex-shrink-0">
                      <svg
                        *ngIf="data.consents[0]?.signatures.length === 1"
                        class="mr-1.5 h-5 w-5 flex-shrink-0 text-blue-400"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm8.706-1.442c1.146-.573 2.437.463 2.126 1.706l-.709 2.836.042-.02a.75.75 0 01.67 1.34l-.04.022c-1.147.573-2.438-.463-2.127-1.706l.71-2.836-.042.02a.75.75 0 11-.671-1.34l.041-.022zM12 9a.75.75 0 100-1.5.75.75 0 000 1.5z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>
                    <div class="ml-2">
                      <p class="text-xs text-gray-900">
                        Samtycket skapades
                        {{ data.consents[0]?.createdAt | date: 'YYYY-MM-dd' }}
                      </p>
                      <div class="mt-1 text-xs text-gray-500">
                        <p>Samtycke behöver signeras</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <svg
                class="h-5 w-5 text-gray-400"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
          </div>
        </a>
      </li>
    </ul>
  </div>

  <p class="mt-4 text-sm font-medium">Välj det barn som du vill samtycka för</p>
  <fieldset>
    <div class="relative mt-2 -space-y-px rounded-md bg-white">
      <label
        *ngFor="let child of ongoingChildren; let i = index"
        [ngClass]="[
          selectedDependent === child ? 'z-10 border-sky-200 bg-sky-50' : 'border-gray-200',
          i === 0 ? 'rounded-tl-md rounded-tr-md' : '',
          i === child.dependent.length - 1 ? 'rounded-bl-md rounded-br-md' : '',
          selectedDependent === child ? 'z-10 border-indigo-200 bg-indigo-50' : 'border-gray-200'
        ]"
        class="relative flex cursor-pointer flex-col border p-4 focus:outline-none md:grid md:grid-cols-3 md:pl-4 md:pr-6"
      >
        <span class="flex items-center text-sm">
          <input
            *ngIf="child.consents.length === 0 || child.consents[0]?.status === 'initiated'"
            type="radio"
            name="child"
            (change)="selectDependent(child)"
            [value]="child.dependent"
            class="h-4 w-4 border-gray-300 text-sky-600 focus:ring-sky-500"
          />
          <span *ngIf="child.consents[0]?.status === 'complete'">
            <app-stepper-icons [status]="'greencheck'"></app-stepper-icons>
          </span>
          <span
            [id]="child.dependent.personalNumber"
            [ngClass]="[selectedDependent === child ? 'text-sky-900' : 'text-gray-900']"
            class="ml-3 font-medium"
            >{{ child.dependent.firstName }} {{ child.dependent.lastName }}</span
          >
        </span>
        <span [id]="child.dependent.personalNumber" class="ml-6 pl-1 text-sm md:ml-0 md:pl-0 md:text-center">
          <span
            [ngClass]="[selectedDependent === child ? 'text-sky-900' : 'text-gray-900']"
            class="font-medium tracking-wide"
            >{{ child.dependent.personalNumber | slice: 0 : 8 }} {{ ' - ' }}
          </span>
          <span *ngFor="let _ of [].constructor(4)" class="text-lg tracking-wide text-gray-900">&bull;</span>
        </span>
      </label>
    </div>
  </fieldset>
</div>

<app-alerts
  *ngIf="errorMessage.age != ''"
  [margin]="'mt-2 mb-2'"
  [type]="'danger'"
  [message]="errorMessage.age"
  (onClick)="errorMessage.age = ''"
></app-alerts>

<div class="flex justify-end">
  <span *ngIf="selectedOption === 'family'" class="relative mt-4 inline-flex">
    <button
      type="button"
      (click)="nextStep(); errorMessage.signatureExists = ''"
      [disabled]="!selectedDependent?.dependent"
      class="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:opacity-50"
    >
      Nästa steg
    </button>

    <span *ngIf="selectedDependent.dependent" class="absolute top-0 right-0 -mt-1 -mr-1 flex h-3 w-3">
      <span class="absolute inline-flex h-full w-full animate-ping rounded-full bg-sky-400 opacity-75"></span>
      <span class="relative inline-flex h-3 w-3 rounded-full bg-sky-500"></span>
    </span>
  </span>
</div>
