<span
  [ngClass]="[
    rounded ? 'rounded-full' : 'rounded-md',
    color != ''
      ? 'bg-' + color + '-100 ' + 'text-' + color + '-800'
      : 'bg-gray-100 text-gray-800',
    large ? 'px-4 text-lg' : 'px-2.5 text-sm'
  ]"
  class="inline-flex items-center py-0.5 font-medium"
>
  {{ label }}
</span>
