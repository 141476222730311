import { ViewportScroller } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakProfile } from 'keycloak-js';
import {
  concat,
  delay,
  interval,
  Observable,
  retryWhen,
  startWith,
  Subscription,
  switchMap,
  take,
  throwError,
} from 'rxjs';
import { ISettings } from 'src/app/core/models/study-settings.model';
import { ConsentService } from 'src/app/core/services/portal/consent.service';
import { StudyService } from 'src/app/core/services/portal/study.service';
import { UserService } from 'src/app/core/services/portal/user.service';
import { DownloadFileService } from 'src/app/core/services/shared/download-file.service';
import { HelperService } from 'src/app/core/services/shared/helper.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-family-electronic-consent',
  templateUrl: './family-electronic-consent.component.html',
})
export class FamilyElectronicConsentComponent implements OnInit {
  private studyId: string;
  private apiUrl: string = environment.apiUrlCommon;
  public isPatientLoggedIn = false;
  public userProfile: any;
  public studySettings!: ISettings;
  patientConsents: any = [];
  study: any = {};
  // -- Step 1
  eSignatureStep: number = 1;
  flowOptions: any[] = [];
  selectedOption: string = '';
  // -- Step 2
  loadingDependents: boolean = false;
  error: boolean = false;
  success: boolean = false;
  dependentsConsents: any[] = [];
  dependents: any[] = [];
  selectedDependent: any = {};
  otherGuardianSignature: boolean = false;
  // -- Step 3
  siteId: string = '';
  studySites: any = [];
  site: any = {};
  fpis: any[] = [];
  fpi: any = {};
  loadingEmbedPdf: boolean = false;
  fpiPDF: string = '';
  fpiDownloadLink: string = '';
  readFpi: boolean = false;
  signatureExists: boolean = false;
  errorSigning: boolean = false;
  signatureLoading: any = {
    bankid: false,
  };
  chosenIdentityProvider: string = '';
  // -- Step 4
  guardians: any = {};
  consentFlowData: any;
  pollingData: any;
  timeInterval = new Subscription();
  newConsent: any;
  // -- General
  errorMessage = {
    signatureExists: '',
    age: '',
  };

  constructor(
    private router: Router,
    private _studyService: StudyService,
    private _consentService: ConsentService,
    private _downloadFileService: DownloadFileService,
    private readonly keycloakService: KeycloakService,
    private _helperService: HelperService,
    private _userService: UserService,
    private viewportScroller: ViewportScroller,
  ) {
    this.studyId = this.router.url.split('/')[3];
  }

  public async ngOnInit() {
    this.getStudySettings();
    this.getPatientStudies();
    this.getStudySites();
    this.getStudy();

    this.isPatientLoggedIn = await this.keycloakService.isLoggedIn();
    if (this.isPatientLoggedIn) {
      this._userService.getUserInfo(this.keycloakService.getUsername()).subscribe((response: any) => {
        this.userProfile = response.data;
      });
    }
  }
  /*
   *Functions
   */
  generateConsentOptions(allowFamilyFlow: boolean) {
    const family = {
      id: 1,
      title: 'Samtycke som vårdnadshavare',
      text: 'Samtyck för ditt barn att gå med i studien',
      value: 'family',
    };
    const patient = {
      id: 2,
      title: 'Samtycke för dig',
      text: 'Samtyck för dig själv att gå med i studien',
      value: 'patient',
    };
    if (allowFamilyFlow) {
      this.flowOptions.push(patient, family);
    } else {
      this.flowOptions.push(patient);
    }
  }
  checkAge() {
    if (
      (this.eSignatureStep === 1 && this.selectedOption === 'patient') ||
      (this.eSignatureStep === 2 && this.selectedOption === 'family')
    ) {
      let patient =
        this.selectedOption === 'patient'
          ? this.userProfile?.username
          : this.selectedDependent.dependent.personalNumber;

      let patientAge = this._helperService.getAge(patient);

      let fromAge = this.studySettings.fromAge;

      let toAge = this.studySettings.toAge;

      if (patientAge < fromAge || patientAge > toAge!) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
  nextStep(stepNumber?: number) {
    if (this.checkAge()) {
      this.errorMessage.age = `Studien har en åldersgräns från ${this.studySettings.fromAge} - ${this.studySettings.toAge} år.`;
      return;
    }
    if (this.eSignatureStep === 1 && this.selectedOption === 'patient') {
      this.eSignatureStep = 3;
    } else if (this.eSignatureStep === 1 && this.selectedOption === 'family') {
      this.onGetDependents();
      this.eSignatureStep++;
    } else {
      this.viewportScroller.scrollToPosition([0, 0]);
      this.eSignatureStep++;
    }
  }
  prevStep() {
    if (this.eSignatureStep === 3 && this.selectedOption === 'patient') {
      this.eSignatureStep = 1;
    } else {
      this.eSignatureStep--;
      this.otherGuardianSignature = false;
      this.selectedDependent = {};
      if (this.studySites.length > 1) {
        this.siteId = '';
        this.fpis = [];
        this.fpiPDF = '';
      }
      this.readFpi = false;
    }
  }
  selectFlow(option: any) {
    this.selectedOption = option.value;
  }
  selectDependent(person: any) {
    this.selectedDependent = person;
  }
  resetFlow() {
    this.eSignatureStep = 1;
    this.selectedOption = '';
    this.otherGuardianSignature = false;
    this.selectedDependent = {};
    if (this.studySites.length > 1) {
      this.siteId = '';
      this.fpis = [];
      this.fpiPDF = '';
    }
    this.readFpi = false;
    this.newConsent = '';
  }
  consentFlowComplete() {
    this.consentFlowData = {};
    this.pollingData = {};
    this.signatureLoading = {
      bankid: false,
      siths: false,
    };
    if (this.studySites.length > 1) {
      this.siteId = '';
      this.fpis = [];
      this.fpiPDF = '';
    }
    this.readFpi = false;
    this.stop();
  }
  stop() {
    this.timeInterval.unsubscribe();
  }
  /*
   *APIs
   */
  getStudy() {
    this._studyService.getStudy(this.studyId).subscribe((response) => {
      this.study = response;
    });
  }
  getStudySettings() {
    this._studyService.getStudySetting(this.studyId).subscribe((settings: ISettings) => {
      this.studySettings = settings;
      this.generateConsentOptions(settings.allowFamilyConsent);
    });
  }
  getPatientStudies() {
    this._userService.getMyStudies().subscribe((response) => {
      this.patientConsents = response;
    });
  }
  getStudySites() {
    this._studyService.getStudySites(this.studyId).subscribe((response) => {
      this.studySites = response.filter((site: any) => site.active);
      if (this.studySites.length === 1) {
        this.siteId = response[0].id;
        this.onChangeSite(response[0].id);
      }
    });
  }
  onChangeSite(siteId: string) {
    this.siteId = siteId;
    this._studyService.getLatestFpi(this.studyId, siteId).subscribe((response) => {
      this.fpis = response;
      this.getSiteInfo(siteId);
      if (response.length) {
        this.fpi = this.fpis[0];
        this.generateFpiDownloadLink();
        this.getFpiDocument(this.fpi.id);
      } else {
      }
    });
  }
  getSiteInfo(siteId: string) {
    this._studyService.getStudySites(this.studyId).subscribe((response) => {
      let filterSite = response.filter((site: { id: string }) => site.id === siteId);
      this.site = filterSite[0];
    });
  }
  generateFpiDownloadLink() {
    this.fpiDownloadLink = `${this.apiUrl}/studies/${this.studyId}/sites/${this.siteId}/fpis/${this.fpi.id}/pdf`;
  }
  getFpiDocument(fpiId: string) {
    this.loadingEmbedPdf = true;
    this._studyService.getFpiPDF(this.studyId, this.siteId, fpiId).subscribe((result: Blob) => {
      const blob = new Blob([result], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      setTimeout(() => {
        this.loadingEmbedPdf = false;
      }, 1800);
      this.fpiPDF = url;
    });
  }
  downloadConsentPDF(siteId: string, consentId: any) {
    this._consentService.getConsentPDF(this.studyId, siteId, consentId).subscribe((result: any) => {
      let filename: string = this._downloadFileService.getFileName(result);
      let binaryData = [];
      binaryData.push(result.body);
      let downloadLink = document.createElement('a');
      const blob = new Blob(binaryData, { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      downloadLink.href = url;
      downloadLink.setAttribute('download', filename);
      document.body.appendChild(downloadLink);
      downloadLink.click();
    });
  }
  onOpenLinkInNewWindow(url: string) {
    window.open(url, '_blank');
  }
  /*
   *Electronic consent flows
   */
  generateSignatureHelptext(signMethod: string) {
    switch (signMethod) {
      case 'bankid':
        this.chosenIdentityProvider = 'BankID applikationen';
        break;
      case 'siths':
        this.chosenIdentityProvider = 'Net iD Access';
        break;
      default:
        this.chosenIdentityProvider = 'vald e-legitimation';
        break;
    }
  }
  guardianSignatureExists(data: any) {
    let signature = data.consents[0].signatures;
    let signaturesExists = signature.filter(
      (data: { personalNumber: string }) => data.personalNumber === this.userProfile?.username,
    );
    if (signaturesExists.length > 0) {
      this.errorMessage.signatureExists = `Du har redan gett samtycke för ${data.dependent.firstName}. Den andra vårdnadshavaren behöver nu också logga in och samtycka för att detta samtycke ska bli giltigt.`;
      this.signatureExists = true;
      return true;
    } else {
      return false;
    }
  }
  onContinueGuardianTwo(data: any) {
    if (this.guardianSignatureExists(data)) {
      return;
    } else {
      this.consentFlowData = data.consents[0];
      this.siteId = this.consentFlowData.siteId;
      this.onChangeSite(this.siteId);
      this.otherGuardianSignature = true;
      this.selectedDependent = data;
      this.nextStep();
    }
  }
  checkConsentExists(fpiVersion: string) {
    let personalNumber = this.userProfile?.username;
    let patientConsentStudy = this.patientConsents.filter(
      (item: any) => item.consent.patientPersonalNumber === personalNumber && item.consent.studyId === this.studyId,
    );
    if (patientConsentStudy.length === 0) {
      return false;
    }
    let patientConsentStudySite = patientConsentStudy.filter((item: any) => item.consent.siteId === this.siteId);
    if (patientConsentStudySite.length === 0) {
      this.errorMessage.signatureExists = `Du är redan, eller har varit, deltagare i denna studie`;
      return true;
    }
    let patientConsentStudySiteVersion = patientConsentStudySite.filter(
      (item: any) => item.consent.fpiVersion === fpiVersion,
    );
    if (patientConsentStudySiteVersion.length > 0) {
      if (this.studySettings?.multipleConsentOnSameVersion) return false;
      this.errorMessage.signatureExists = `Du är redan, eller har varit, deltagare i denna studie`;
      return true;
    } else {
      return false;
    }
  }
  onInitConsentFlow(signMethod: string) {
    if (this.checkConsentExists(this.fpi.version)) {
      return;
    }
    var contextData = {
      fpiId: this.fpi.id,
      type: this.selectedOption,
    };

    if (this.otherGuardianSignature) {
      this.onOtherGuardianSignature(signMethod);
    } else {
      this._consentService.initializeConsentFlow(this.studyId, this.siteId, contextData).subscribe((response) => {
        this.consentFlowData = response;
        if (this.selectedOption === 'family') {
          this.onGuardianSignature(signMethod);
        } else {
          this.onPatientSignature(signMethod);
        }
      });
    }
  }
  // ---- patient only
  onPatientSignature(signMethod: string) {
    this.errorSigning = false;
    this.signatureLoading[signMethod] = true;
    this.generateSignatureHelptext(signMethod);
    var patientSignatureData = {
      signatureMethod: signMethod,
    };
    this._consentService
      .patientSignature(this.studyId, this.siteId, this.consentFlowData.id, patientSignatureData)
      .subscribe({
        next: (response) => {
          this.pollingData = response;
          this.onCollectPatientSignature('complete');
        },
        error: (e) => {
          this.stop();
          this.signatureLoading = {
            bankid: false,
            siths: false,
          };
          this.errorSigning = true;
        },
      });
  }
  onCollectPatientSignature(signatureType: string) {
    if (signatureType === 'complete') {
      var step = 0;
    } else {
      var step = 1;
    }
    this.timeInterval = interval(3000)
      .pipe(
        startWith(0),
        switchMap(() =>
          this._consentService.collectPatientSignature(this.studyId, this.siteId, this.consentFlowData.id),
        ),
        retryWhen((errors: Observable<any>) => {
          return errors.pipe(delay(1000), take(60), (o) =>
            concat(
              o,
              throwError(() => `There was no result after 10 attempts`),
            ),
          );
        }),
      )
      .subscribe({
        next: (response: any) => {
          if (response.status === signatureType) {
            if (signatureType === 'complete') this.newConsent = response;
            this.signatureLoading = {
              bankid: false,
              siths: false,
            };
            this._helperService.updateUserCompletedSignature(true);
            this.nextStep(step);
            this.consentFlowComplete();
          }
        },
        error: (e) => {
          this.stop();
          this.signatureLoading = {
            bankid: false,
            siths: false,
          };
        },
      });
  }
  // ---- family
  onGetGuardians(personalNumber: string) {
    this._consentService.getGuardians(personalNumber).subscribe((response) => {
      this.guardians = response;
    });
  }
  onGetDependents() {
    this.loadingDependents = true;
    this.error = false;
    this.success = false;
    this._consentService.getDependents().subscribe({
      next: (response: any) => {
        this.dependents = response.dependentsWithConsents.filter(
          (data: { consents: string | any[] }) => data.consents.length === 0,
        );
        this.dependentsConsents = response.dependentsWithConsents.filter(
          (data: { consents: string | any[] }) => data.consents.length > 0 && data.consents[0]?.status != 'complete',
        );
        this.success = true;
        this.loadingDependents = false;
      },
      error: (e) => {
        this.loadingDependents = false;
        this.error = true;
        console.error('Something went wrong, try again', e);
      },
    });
  }
  onGuardianSignature(signMethod: string) {
    this.errorSigning = false;
    this.signatureLoading[signMethod] = true;
    this.generateSignatureHelptext(signMethod);
    var dependentSignatureData = {
      dependentPersonalNumber: this.selectedDependent.dependent.personalNumber,
      signatureMethod: signMethod,
    };

    if (this.selectedDependent.consents.length === 0) {
      var collectString = 'parent-signed';
    } else {
      var collectString = 'complete';
    }
    this._consentService
      .guardianSignature(this.studyId, this.siteId, this.consentFlowData.id, dependentSignatureData)
      .subscribe({
        next: (response) => {
          this.pollingData = response;
          this.onCollectGuardianSignature(collectString);
        },
        error: (e) => {
          this.stop();
          this.signatureLoading = {
            bankid: false,
            siths: false,
          };
          this.errorSigning = true;
        },
      });
  }
  onOtherGuardianSignature(signMethod: string) {
    this.errorSigning = false;
    this.signatureLoading[signMethod] = true;
    this.generateSignatureHelptext(signMethod);
    var signatureMethod = {
      signatureMethod: signMethod,
    };
    if (this.selectedDependent.consents.length === 0) {
      var collectString = 'parent-signed';
    } else {
      var collectString = 'complete';
    }
    this._consentService
      .otherGuardianSignature(this.studyId, this.siteId, this.consentFlowData.id, signatureMethod)
      .subscribe({
        next: (response) => {
          this.pollingData = response;
          this.onCollectGuardianSignature(collectString);
        },
        error: (e) => {
          this.stop();
          this.signatureLoading = {
            bankid: false,
            siths: false,
          };
          this.errorSigning = true;
        },
      });
  }
  onCollectGuardianSignature(signatureType: string) {
    if (signatureType === 'complete') {
      var step = 0;
    } else {
      var step = 1;
    }
    this.timeInterval = interval(3000)
      .pipe(
        startWith(0),
        switchMap(() =>
          this._consentService.collectGuardianSignature(this.studyId, this.siteId, this.consentFlowData.id),
        ),
        retryWhen((errors: Observable<any>) => {
          return errors.pipe(delay(1000), take(60), (o) =>
            concat(
              o,
              throwError(() => `There was no result after 10 attempts`),
            ),
          );
        }),
      )
      .subscribe((response: any) => {
        if (response.status === signatureType || response.status === 'complete') {
          if (this.selectedDependent.consents.length === 0 && response.status === 'complete') {
            this.otherGuardianSignature = true;
          }
          if (signatureType === 'complete') {
            this.newConsent = response;
            this._helperService.updateUserCompletedSignature(true);
          }
          this.signatureLoading = {
            bankid: false,
            siths: false,
          };
          this.nextStep(step);
          this.consentFlowComplete();
        }
      });
  }
}
