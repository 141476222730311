import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { UserService } from 'src/app/core/services/portal/user.service';

@Component({
  selector: 'app-result',
  templateUrl: './result.component.html',
})
export class ResultComponent implements OnInit {
  private studyId: string;
  results: any = [];

  constructor(
    private router: Router,
    private _userService: UserService,
    private keycloakService: KeycloakService,
  ) {
    this.studyId = this.router.url.split('/')[3];
  }

  ngOnInit(): void {
    this.getPatientResults();
  }

  getPatientResults() {
    this._userService.getUserResults(this.studyId, this.keycloakService.getUsername()).subscribe((response) => {
      this.results = response;
    });
  }
}
