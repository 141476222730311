import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-text-button',
  templateUrl: './text-button.component.html',
})
export class TextButtonComponent implements OnInit {
  @Output() onClick = new EventEmitter();
  @Input() label!: string;
  @Input() disabled?: boolean = false;
  @Input() type?: string = 'button';

  ngOnInit(): void {}
}
