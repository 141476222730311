<div class="py-8 sm:py-16">
  <div class="text-center">
    <svg
      class="mx-auto h-12 w-12 text-sky-600"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
    >
      <path
        fill-rule="evenodd"
        d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm8.706-1.442c1.146-.573 2.437.463 2.126 1.706l-.709 2.836.042-.02a.75.75 0 01.67 1.34l-.04.022c-1.147.573-2.438-.463-2.127-1.706l.71-2.836-.042.02a.75.75 0 11-.671-1.34l.041-.022zM12 9a.75.75 0 100-1.5.75.75 0 000 1.5z"
        clip-rule="evenodd"
      />
    </svg>

    <h3 class="mt-2 text-lg font-medium text-gray-900">Logga in</h3>
    <p class="mt-1 max-w-xl mx-auto text-base text-gray-500">
      Du måste vara inloggad för att kunna läsa all information på denna sida. Loggar du in för första gången på
      minforskning.se får du skapa en personlig profil och ange dina kontaktuppgifter.
    </p>
    <div class="max-w-md mx-auto mt-6 space-y-8">
      <button
        *ngFor="let method of loginMethods"
        (click)="login(method.hint)"
        [type]="method.type"
        [class]="
          method.class +
          ' group relative w-full flex justify-center rounded-lg px-3 py-3 text-base font-semibold leading-6'
        "
      >
        <span class="absolute left-2 inset-y-0 flex items-center pl-3">
          <img class="h-8 w-8" [src]="method.logo" [alt]="method.name" />
        </span>
        {{ method.name }}
      </button>
    </div>
  </div>
</div>
