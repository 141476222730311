import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-study-consent',
  templateUrl: './study-consent.component.html',
})
export class StudyConsentComponent implements OnInit {
  private apiUrl: string = environment.apiUrlCommon;
  private studyId: string;

  settings: any = {};

  constructor(
    private router: Router,
    private r: ActivatedRoute,
    private http: HttpClient,
  ) {
    this.studyId = this.router.url.split('/')[3];
  }

  ngOnInit(): void {
    this.getStudySettings();
  }
  /* Functions */
  navigateTo(e: any) {
    this.router.navigate([`${e.target.value}`], { relativeTo: this.r });
  }
  /* Apis */
  getStudySettings() {
    this.http.get<any>(`${this.apiUrl}/studies/${this.studyId}/setting`).subscribe((response) => {
      this.settings = response;
    });
  }
}
