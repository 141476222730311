<div class="bg-white shadow overflow-hidden sm:rounded-lg">
  <div class="px-4 py-5 sm:px-6">
    <h3 class="text-lg leading-6 font-medium text-gray-900">
      Kontaktinformation
    </h3>
    <p *ngIf="patientSite.site" class="mt-1 max-w-2xl text-sm text-gray-500">
      {{ patientSite.site.name }}
    </p>
  </div>
  <div
    *ngIf="patientSite.site"
    class="border-t border-b border-gray-200 px-4 py-5 sm:px-6"
  >
    <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
      <div class="sm:col-span-1">
        <dt class="text-sm font-medium text-gray-700">Lokal kontakt</dt>
        <dd class="mt-1 text-sm text-gray-900">
          {{ patientSite.site.localContactName }}
        </dd>
        <dd class="mt-1 text-sm text-gray-900">
          {{ patientSite.site.localContactEmail }}
        </dd>
        <dd class="mt-1 text-sm text-gray-900">
          {{ patientSite.site.localContactPhone }}
        </dd>
      </div>
      <div class="sm:col-span-1">
        <dt class="text-sm font-medium text-gray-700">Besöksadress</dt>
        <dd class="mt-1 text-sm text-gray-900">
          {{ patientSite.site.streetAddress }}
        </dd>
        <dd class="mt-1 text-sm text-gray-900">
          {{
            patientSite.site.municipality + ', ' + patientSite.site.postalCode
          }}
        </dd>
      </div>
    </dl>
  </div>
</div>
