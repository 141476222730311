<div class="flex">
  <svg
    *ngIf="consent?.type === 'family' && consent?.status === 'parent-signed'"
    class="mr-3 h-5 w-5 text-yellow-500"
    viewBox="0 0 20 20"
    fill="currentColor"
    aria-hidden="true"
  >
    <path
      fill-rule="evenodd"
      d="M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495zM10 5a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 5zm0 9a1 1 0 100-2 1 1 0 000 2z"
      clip-rule="evenodd"
    />
  </svg>
  <p class="text-sm font-medium">
    {{ consent?.type === 'patient' ? 'Du har nu samtyckt till studien.' : 'Du har nu signerat.' }}
  </p>
</div>
<div class="mx-auto my-8 w-1/2 border border-sky-600"></div>

<div *ngIf="consent?.type === 'family'" class="mt-2">
  <p *ngIf="consent?.status === 'parent-signed'" class="text-sm text-gray-500">
    Observera att båda vårdnadshavarna måste signera för barn som ni har gemensam vårdnad för. Uppmana gärna den andra
    vårdnadshavaren att logga in för att kunna ge sitt samtycke.
  </p>

  <p *ngIf="consent?.status === 'complete'" class="text-sm text-gray-500">
    {{ dependent?.firstName + ' ' + dependent?.lastName + ' är nu med i studien.' }}
  </p>
  <div class="mx-auto my-8 w-1/2 border border-sky-600"></div>
</div>

<ng-container *ngIf="settings?.symptomUrl && consent?.status === 'complete' && consent">
  <p class="text-sm">Du behöver nu ge information till studien i en digital enkät i systemet Symptoms.</p>

  <div class="mt-6">
    <app-button
      (onClick)="onOpenLinkInNewWindow('https://' + settings.symptomUrl)"
      [label]="'Klicka här för att komma till den digitala enkäten'"
      [fullWidth]="true"
      [color]="true"
      [btnColor]="'bg-sky-600 text-white hover:bg-sky-500 active:opacity-75'"
    >
    </app-button>
  </div>

  <div class="mx-auto my-8 w-1/2 border border-sky-600"></div>
</ng-container>

<h3 class="border-l border-sky-600 pl-6 text-base font-medium text-gray-900">Har du några frågor</h3>
<div class="border-l border-gray-200 pl-6 pt-2 text-sm not-italic text-gray-700">
  <p>{{ site?.localContactName }}</p>
  <p>{{ site?.localContactEmail }}</p>
  <p>{{ site?.localContactPhone }}</p>
</div>

<div *ngIf="consentFlowType === 'family'" class="mt-6">
  <app-button
    (onClick)="signNext()"
    [label]="consent?.type === 'patient' ? 'Vill du signera för ditt barn?' : 'Gå vidare med nästa signatur'"
    [fullWidth]="true"
    [color]="false"
  >
  </app-button>
</div>

<div *ngIf="consent?.status === 'complete'" class="mt-6">
  <app-button
    (onClick)="downloadConsentPDF()"
    [label]="'Ladda ner signerat samtycke'"
    [fullWidth]="true"
    [color]="true"
    [btnColor]="'bg-sky-600 text-white hover:bg-sky-500 active:opacity-75'"
  >
  </app-button>
</div>
