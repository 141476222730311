import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalComponent } from './components/modal/modal.component';
import { IconComponent } from './components/icon/icon.component';
import { TranslateRolePipe } from './pipes/translate-role.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { ConsentFilterPipe } from './pipes/consent-filter.pipe';
import { SiteFilterPipe } from './pipes/site-filter.pipe';
import { SignatureFilterPipe } from './pipes/signature-filter.pipe';
import { BadgeComponent } from './components/badge/badge.component';
import { ButtonComponent } from './components/button/button.component';
import { AlertsComponent } from './components/alerts/alerts.component';
import { TextButtonComponent } from './components/text-button/text-button.component';
import { JsonParsePipe } from './pipes/json-parse.pipe';
import { StudyAuthComponent } from './components/study-auth/study-auth.component';
import { RouterModule } from '@angular/router';
import { FpiViewerComponent } from './components/fpi-viewer/fpi-viewer.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { InputFieldComponent } from './components/input-field/input-field.component';
import { ReactiveFormsModule } from '@angular/forms';
import { QrCodeComponent } from './components/qr-code/qr-code.component';
import { DynamicStepHostDirective } from './directives/dynamic-step-host.directive';

@NgModule({
  declarations: [
    ModalComponent,
    IconComponent,
    TranslateRolePipe,
    SafePipe,
    ConsentFilterPipe,
    SiteFilterPipe,
    SignatureFilterPipe,
    BadgeComponent,
    ButtonComponent,
    AlertsComponent,
    TextButtonComponent,
    JsonParsePipe,
    StudyAuthComponent,
    FpiViewerComponent,
    InputFieldComponent,
    QrCodeComponent,
    DynamicStepHostDirective,
  ],
  imports: [CommonModule, RouterModule, ReactiveFormsModule, PdfViewerModule],
  exports: [
    CommonModule,
    ModalComponent,
    IconComponent,
    TranslateRolePipe,
    SafePipe,
    ConsentFilterPipe,
    SiteFilterPipe,
    SignatureFilterPipe,
    BadgeComponent,
    ButtonComponent,
    AlertsComponent,
    TextButtonComponent,
    JsonParsePipe,
    StudyAuthComponent,
    FpiViewerComponent,
    InputFieldComponent,
    QrCodeComponent,
    DynamicStepHostDirective,
  ],
})
export class SharedModule {}
