import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
})
export class LoginComponent {
  constructor(
    private readonly keycloakService: KeycloakService,
    private router: Router,
  ) {}

  login() {
    const urlTree = this.router.parseUrl(this.router.url);
    urlTree.fragment = 'consent-flow';
    const redirectUri = window.location.origin + this.router.serializeUrl(urlTree);
    localStorage.setItem('redirectUri', redirectUri);

    this.keycloakService.login({
      idpHint: 'GrandId-BankId',
      redirectUri: redirectUri,
    });
  }

  ngOnDestroy(): void {
    localStorage.removeItem('redirectUri');
  }
}
