<ng-container *ngIf="dependentsConsents.length > 0">
  <div class="mb-2 block pb-2">
    <h3 class="text-lg font-medium leading-6 text-gray-900">Påbörjade signeringar</h3>
    <p class="mt-1 max-w-2xl text-sm text-gray-500">
      Här ser du barn där en vårdnadshavaren redan lämnat samtycke. Vid gemensam vårdnad behöver alla vårdnadshavare
      signera för att barnet ska kunna delta i studien.
    </p>
  </div>
  <div
    [ngClass]="loadingDependents ? 'hidden' : 'visible'"
    class="mb-8 overflow-hidden border-b border-gray-200 bg-white shadow sm:rounded-md"
  >
    <ul role="list" class="divide-y divide-gray-200">
      <li *ngFor="let data of dependentsConsents; let i = index">
        <a
          [routerLink]="['/study', studyName, data.consents[0].studyId]"
          [queryParams]="{ site: data.consents[0].siteId }"
          fragment="consent-flow"
          class="block hover:bg-gray-50"
        >
          <div class="flex items-center px-4 py-4 sm:px-6">
            <div class="flex min-w-0 flex-1 items-center">
              <div class="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                <div>
                  <p class="truncate text-sm font-medium text-gray-600">
                    {{ data.dependent.firstName }} {{ data.dependent.lastName }}
                  </p>
                  <p class="mt-2 text-sm text-gray-500">
                    <span class="truncate">
                      {{ data.dependent.personalNumber | slice: 0 : 8 }}
                      {{ ' - ' }}
                    </span>
                    <span *ngFor="let _ of [].constructor(4)" class="text-lg tracking-wide text-gray-900">&bull;</span>
                  </p>
                </div>
                <div class="hidden md:block">
                  <div>
                    <p class="text-sm text-gray-900">
                      Samtycket skapades
                      {{ data.consents[0]?.createdAt | date: 'YYYY-MM-dd' }}
                    </p>
                    <p class="mt-2 flex items-center text-sm text-gray-500">
                      <svg
                        *ngIf="data.consents[0]?.signatures.length > 1"
                        class="mr-1.5 h-5 w-5 flex-shrink-0 text-green-400"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                          clip-rule="evenodd"
                        />
                      </svg>
                      <svg
                        *ngIf="data.consents[0]?.signatures.length === 1"
                        class="mr-1.5 h-5 w-5 flex-shrink-0 text-blue-400"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm8.706-1.442c1.146-.573 2.437.463 2.126 1.706l-.709 2.836.042-.02a.75.75 0 01.67 1.34l-.04.022c-1.147.573-2.438-.463-2.127-1.706l.71-2.836-.042.02a.75.75 0 11-.671-1.34l.041-.022zM12 9a.75.75 0 100-1.5.75.75 0 000 1.5z"
                          clip-rule="evenodd"
                        />
                      </svg>
                      {{
                        data.consents[0]?.signatures.length === 1
                          ? 'Samtycke behöver signeras'
                          : 'Samtycke har signerats av alla vårdnadshavare'
                      }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <svg
                class="h-5 w-5 text-gray-400"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
          </div>
        </a>
      </li>
    </ul>
  </div>
</ng-container>

<div class="overflow-hidden bg-white shadow sm:rounded-lg">
  <div class="flex items-center justify-between px-4 py-5 sm:px-6">
    <div>
      <h3 class="text-lg font-medium leading-6 text-gray-900">Samtycke</h3>
      <p class="mt-1 max-w-2xl text-sm text-gray-500">
        Giltiga och återkallade samtycken för studien. Kontakta din vårdenhet för att genomföra ett återkallande.
        Kontaktuppgifterna finner du om du klickar på fliken ”Vårdenhet”.
      </p>
    </div>
  </div>
  <div
    *ngIf="studySettings?.symptomUrl && studySettings?.symptomUrl != 'null'"
    class="border-t border-gray-200 px-4 py-5 sm:px-6"
  >
    <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
      <div class="sm:col-span-2">
        <dt class="text-sm font-medium text-gray-500">Digital enkät</dt>
        <dd class="mt-1 text-sm text-gray-900">
          <ul class="divide-y divide-gray-200 rounded-md border border-gray-200">
            <li class="flex flex-wrap items-center justify-between space-y-1 py-3 pl-3 pr-4 text-sm">
              <div class="w-0 min-w-[50%] flex-1 items-center">
                <span class="text-sm font-medium text-gray-500">
                  Du behöver i denna studie ge information i en digital enkät i systemet Symptoms.
                </span>
              </div>
              <div>
                <a
                  [href]="'https://' + studySettings?.symptomUrl"
                  target="_blank"
                  class="flex items-center font-medium text-sky-600 hover:text-sky-500"
                >
                  Klicka här för att komma till den digitala enkäten
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="ml-2 h-4 w-4 flex-shrink-0 text-sky-600"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                    />
                  </svg>
                </a>
              </div>
            </li>
          </ul>
        </dd>
      </div>
    </dl>
  </div>
  <div
    *ngIf="(patientConsent | consentFilter: false : 'complete').length > 0"
    class="border-t border-gray-200 px-4 py-5 sm:px-6"
  >
    <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
      <div class="sm:col-span-2">
        <dt class="text-sm font-medium text-gray-500">Giltiga samtycken</dt>
        <dd class="mt-1 text-sm text-gray-900">
          <ul role="list" class="divide-y divide-gray-200 rounded-md border border-gray-200">
            <li
              *ngFor="let consent of patientConsent | consentFilter: false : 'complete'"
              class="flex items-center justify-between py-3 pl-3 pr-4 text-sm"
            >
              <div class="flex w-0 flex-1 items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="h-5 w-5 flex-shrink-0 text-gray-400"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M18.375 12.739l-7.693 7.693a4.5 4.5 0 01-6.364-6.364l10.94-10.94A3 3 0 1119.5 7.372L8.552 18.32m.009-.01l-.01.01m5.699-9.941l-7.81 7.81a1.5 1.5 0 002.112 2.13"
                  />
                </svg>
                <span class="ml-2 text-sm font-medium text-sky-800">
                  {{ consent.consent.patientName }}
                </span>
                <span class="ml-2 w-0 flex-1 truncate">
                  {{ 'samtycke_' + consent.study.name + '.pdf' }}
                </span>
              </div>
              <div class="ml-4 flex-shrink-0">
                <button
                  type="button"
                  (click)="downloadConsentPDF(consent.consent.studyId, consent.consent.siteId, consent.consent.id)"
                  class="font-medium text-sky-600 hover:text-sky-500"
                >
                  Ladda ner
                </button>
              </div>
            </li>
          </ul>
        </dd>
      </div>
    </dl>
  </div>
  <div
    *ngIf="(patientConsent | consentFilter: true).length > 0"
    class="border-t border-gray-200 bg-gray-50 px-4 py-5 sm:px-6"
  >
    <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
      <div class="sm:col-span-2">
        <dt class="text-sm font-medium text-gray-500">Återkallade samtycken</dt>
        <dd class="mt-1 text-sm text-gray-900">
          <ul class="divide-y divide-gray-200 rounded-md border border-gray-200">
            <li
              *ngFor="let consent of patientConsent | consentFilter: true"
              class="flex items-center justify-between py-3 pl-3 pr-4 text-sm"
            >
              <div class="flex w-0 flex-1 items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="h-5 w-5 flex-shrink-0 text-gray-400"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M18.375 12.739l-7.693 7.693a4.5 4.5 0 01-6.364-6.364l10.94-10.94A3 3 0 1119.5 7.372L8.552 18.32m.009-.01l-.01.01m5.699-9.941l-7.81 7.81a1.5 1.5 0 002.112 2.13"
                  />
                </svg>
                <span class="ml-2 text-sm font-medium text-sky-800">
                  {{ consent.consent.patientName }}
                </span>
                <span class="ml-2 w-0 flex-1 truncate">
                  {{ 'samtycke_' + consent.study.name + '.pdf' }}
                </span>
              </div>
              <div class="ml-4 flex-shrink-0">
                <button
                  type="button"
                  (click)="downloadConsentPDF(consent.consent.studyId, consent.consent.siteId, consent.consent.id)"
                  class="font-medium text-sky-600 hover:text-sky-500"
                >
                  Ladda ner
                </button>
              </div>
            </li>
          </ul>
        </dd>
      </div>
    </dl>
  </div>
</div>
