import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { environment } from 'src/environments/environment';
import { HeadersService } from '../shared/headers.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private apiUrlEic: string = environment.apiUrlEic + '/studies';
  private apiUrl: string = environment.apiUrl;

  constructor(
    private http: HttpClient,
    private _headersService: HeadersService,
    private keycloak: KeycloakService
  ) {}

  // ---- CURRENT USER API´S
  // -- Check user auth
  async userAuth() {
    let auth = await this.keycloak.isLoggedIn();
    if (auth) {
      return true;
    } else {
      return false;
    }
  }
  // -- Get user info
  getUserInfo(username: string) {
    return this.http.get(`${this.apiUrl}/user/user-info/${username}`);
  }
  // -- User update account
  updateProfile(data: any) {
    return this.http.put(`${this.apiUrl}/user/update`, data);
  }
  // -- User Account Complete
  async accountComplete() {
    let user = await this.keycloak.loadUserProfile(true);
    if (user.firstName && user.lastName && user.email) {
      return true;
    } else {
      return false;
    }
  }
  // -- User Specific studies
  getSpecificStudies() {
    return this.http.get<any>(`${this.apiUrlEic}/specific`);
  }
  // -- User Roles
  getSitesRoles(studyId: string, username: string) {
    return this.http.get<any>(`${this.apiUrlEic}/${studyId}/${username}/sites`);
  }
  // -- Add Identity providers to user
  addIdpProviders() {
    return this.http.get<any>(`${this.apiUrl}/user/idp`);
  }
  // -- Add attributes to user
  addAttributes(data: any) {
    return this.http.put<any>(`${this.apiUrl}/user/attributes`, data);
  }
  // -- User can create, read, update, delete
  userCRUD(currentPermission: string, permissionList: any) {
    if (permissionList.includes(currentPermission)) {
      return {
        create: true,
        read: true,
        update: true,
        delete: true,
      };
    } else {
      return {
        create: false,
        read: false,
        update: false,
        delete: false,
      };
    }
  }
  // ---- GENERAL USER API´S
  // -- Get Users for the Study and Site
  getStudySiteUsers(studyId: string, siteId: string) {
    let headers = this._headersService.createSiteIdHeaders(siteId);
    return this.http.get<any>(
      `${this.apiUrl}/user/${studyId}/${siteId}/users`,
      {
        headers: headers,
      }
    );
  }
  // -- Create User (invite) (xlsx)
  createUser(siteId: string, data: any) {
    let headers = this._headersService.createSiteIdHeaders(siteId);
    return this.http.post<any>(`${this.apiUrl}/user/create`, data, {
      headers: headers,
    });
  }
  // -- Assign role (doctor) to invited User
  assignRoleToUser(siteId: string, username: string, role: any) {
    let headers = this._headersService.createSiteIdHeaders(siteId);
    return this.http.put(
      `${this.apiUrl}/user/assign-roles/${username}/${role}`,
      role,
      {
        headers: headers,
      }
    );
  }
  // -- Active/Inactive user for site
  userActivation(siteId: string, data: any) {
    let headers = this._headersService.createSiteIdHeaders(siteId);
    return this.http.put(`${this.apiUrl}/user/activation`, data, {
      headers: headers,
    });
  }
  // -- Update User permissions
  updatePermissions(siteId: string, userId: string, permissions: any) {
    let headers = this._headersService.createSiteIdHeaders(siteId);
    return this.http.put(
      `${this.apiUrl}/user/assign-site-roles/${userId}`,
      permissions,
      { headers: headers }
    );
  }
  // -- Export Users (xlsx)
  exportStudySiteUsers(studyId: string, siteId: string, data: any) {
    let headers = this._headersService.createSiteIdHeaders(siteId);
    return this.http.post<any>(
      `${this.apiUrlEic}/${studyId}/sites/${siteId}/users/export`,
      data,
      { headers: headers, observe: 'response', responseType: 'blob' as 'json' }
    );
  }
  // -- Export User log (xlsx)
  exportStudySiteUserLog(siteId: string, userId: string) {
    let headers = this._headersService.createSiteIdHeaders(siteId);
    return this.http.get<any>(
      `${this.apiUrl}/history-log-export/${userId}/${siteId}`,
      { headers: headers, observe: 'response', responseType: 'blob' as 'json' }
    );
  }
}
