import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'translateRole',
})
export class TranslateRolePipe implements PipeTransform {
  transform(value: string, ...args: unknown[]): unknown {
    var role: string;

    switch (value) {
      case 'StudyAdministrator':
        role = 'Studieadministratör';
        break;
      case 'SiteAdministrator':
        role = 'Siteadministratör';
        break;
      case 'StudyDoctor':
        role = 'Studieläkare';
        break;
      case 'StudyPersonal':
        role = 'Studiepersonal';
        break;
      case 'Monitor':
        role = 'Monitor';
        break;
      default:
        role = '';
        break;
    }

    return role;
  }
}
