import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { StudiesRoutingModule } from './studies-routing.module';
import { SharedModule } from 'src/app/shared/shared.module';

import { QuillModule } from 'ngx-quill';

import { StudiesComponent } from './studies.component';
import { StudyComponent } from 'src/app/layout/portal/study/study.component';

import { StudyJourneyComponent } from './study-journey/study-journey.component';
import { StudyDescriptionComponent } from './study-journey/components/study-description/study-description.component';
import { ElectronicConsentComponent } from './study-journey/components/electronic-consent/electronic-consent.component';
import { ContactFormConsentComponent } from './study-journey/components/contact-form-consent/contact-form-consent.component';
import { StudyLoadingComponent } from './study-loading/study-loading.component';
import { FamilyElectronicConsentComponent } from './study-journey/components/family-electronic-consent/family-electronic-consent.component';
import { ConsentStepperComponent } from './study-journey/components/consent-stepper/consent-stepper.component';
import { LoginComponent } from './study-journey/components/consent-flow/login/login.component';
import { FpiQrcodeComponent } from './study-journey/components/consent-flow/fpi-qrcode/fpi-qrcode.component';

import { StudyFpiComponent } from './study-fpi/study-fpi.component';
import { StudyContactComponent } from './study-contact/study-contact.component';
import { StudyConsentComponent } from './study-consent/study-consent.component';
import { ConsentComponent } from './study-consent/components/consent/consent.component';
import { ResultComponent } from './study-consent/components/result/result.component';
import { SiteComponent } from './study-consent/components/site/site.component';

import { StepperIconsComponent } from 'src/app/shared/components/stepper-icons/stepper-icons.component';
import { FinalStepComponent } from './study-journey/components/consent-flow/final-step/final-step.component';
import { DoctorSignatureComponent } from './study-journey/components/consent-flow/doctor-signature/doctor-signature.component';
import { FamilyComponent } from './study-journey/components/consent-flow/family/family.component';

@NgModule({
  declarations: [
    StudiesComponent,
    StudyComponent,
    StudyDescriptionComponent,
    StudyFpiComponent,
    StudyContactComponent,
    StudyJourneyComponent,
    StudyConsentComponent,
    ConsentComponent,
    ResultComponent,
    SiteComponent,
    ElectronicConsentComponent,
    ContactFormConsentComponent,
    StudyLoadingComponent,
    FamilyElectronicConsentComponent,
    ConsentStepperComponent,
    StepperIconsComponent,
    LoginComponent,
    FpiQrcodeComponent,
    DoctorSignatureComponent,
    FinalStepComponent,
    FamilyComponent,
  ],
  imports: [CommonModule, FormsModule, StudiesRoutingModule, SharedModule, QuillModule.forRoot()],
})
export class StudiesModule {}
