<div *ngIf="cookiesModal" class="flex min-h-full flex-col bg-white pt-16 pb-12">
  <main
    class="mx-auto flex w-full max-w-7xl flex-grow flex-col justify-center px-4 sm:px-6 lg:px-8"
  >
    <div class="flex flex-shrink-0 justify-center">
      <a [routerLink]="'/'" class="inline-flex">
        <span class="text-lg font-medium text-gray-900">Min forskning</span>
      </a>
    </div>
    <div class="py-16">
      <div class="text-center">
        <h1
          class="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl"
        >
          Innan du kan använda minforskning.se behöver du godkänna kakor (cookies).
        </h1>
      </div>
    </div>
  </main>
</div>

<app-modal *ngIf="cookiesModal" [largeDialog]="false" [closeButton]="false">
  <div
    content
    class="mx-auto max-w-sm flex flex-col py-4 space-y-2 text-center"
  >
    <h1 class="text-2xl font-bold text-[#15141a] tracking-wide">
      Min forskning
    </h1>
    <h1 class="text-lg font-semibold text-gray-700">Vi använder cookies</h1>
    <p class="text-base text-gray-600">
      Minforskning.se vid Uppsala universitet använder kakor (cookies) för att webbplatsen ska fungera
      bra för dig.
    </p>
    <a
      href="https://www.uu.se/om-webbplatsen"
      target="_blank"
      class="pt-2 pb-4 text-base text-blue-500 underline"
    >
      Personuppgifts- och cookiepolicy
    </a>
    <app-button
      [label]="'Godkänn cookies'"
      [fullWidth]="true"
      [color]="true"
      [btnColor]="'bg-[#15141a] text-white hover:bg-[#180c3c]'"
      [textLarge]="true"
      (onClick)="onAcceptCookies()"
    >
    </app-button>
  </div>
</app-modal>
<router-outlet *ngIf="!cookiesModal"></router-outlet>
