import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DownloadFileService {

  constructor() { }
  //TODO: 
  //getFilename
  getFileName(response: HttpResponse<Blob>) {
    let filename: string;
    try {
      const contentDisposition: string = response.headers.get(
        'content-disposition'
      )!;
      filename = contentDisposition.split('=')[1];
    } catch (e) {
      filename = 'samtycken.pdf';
    }
    return filename;
  }
  //download fpi
  //download consent
  //export consent
  //export users 
}
