import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { ISettings } from 'src/app/core/models/study-settings.model';
import { ConsentService } from 'src/app/core/services/portal/consent.service';
import { StudyService } from 'src/app/core/services/portal/study.service';
import { UserService } from 'src/app/core/services/portal/user.service';
import { DownloadFileService } from 'src/app/core/services/shared/download-file.service';

@Component({
  selector: 'app-consent',
  templateUrl: './consent.component.html',
})
export class ConsentComponent implements OnInit {
  private studyId: string;
  public isPatientLoggedIn = false;
  public studySettings?: ISettings;
  studyName: string;
  loadingDependents: boolean = false;
  dependentsConsents: any[] = [];
  patientConsent: any = [];

  constructor(
    private router: Router,
    private _studyService: StudyService,
    private _consentService: ConsentService,
    private _userService: UserService,
    private _downloadFileService: DownloadFileService,
    private keycloakService: KeycloakService,
  ) {
    this.studyId = this.router.url.split('/')[3];
    this.studyName = this.router.url.split('/')[2];
  }

  public async ngOnInit() {
    this.getStudySettings();
    this.getDependentsConsents();
    this.isPatientLoggedIn = await this.keycloakService.isLoggedIn();
    if (this.isPatientLoggedIn) {
      this.getPatientConsent();
    }
  }
  /* 
    ---- 
    APIs
    ---- 
  */
  getStudySettings() {
    this._studyService.getStudySetting(this.studyId).subscribe((settings: ISettings) => {
      this.studySettings = settings;
    });
  }
  getPatientConsent() {
    this._userService.getMyStudy(this.studyId).subscribe((response) => {
      this.patientConsent = response;
    });
  }
  getDependentsConsents() {
    this.loadingDependents = true;
    this._consentService.getDependents({ studyId: this.studyId }).subscribe({
      next: (response: any) => {
        this.dependentsConsents = response.dependentsWithConsents.filter(
          (data: { consents: string | any[] }) => data.consents.length > 0 && data.consents[0]?.status != 'complete',
        );
        this.loadingDependents = false;
      },
      error: () => {
        this.loadingDependents = false;
      },
    });
  }
  downloadConsentPDF(studyId: string, siteId: string, consentId: string) {
    this._consentService.getConsentPDF(studyId, siteId, consentId).subscribe((result: any) => {
      const filename: string = this._downloadFileService.getFileName(result);
      const binaryData = [];
      binaryData.push(result.body);
      const downloadLink = document.createElement('a');
      const blob = new Blob(binaryData, { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      downloadLink.href = url;
      downloadLink.setAttribute('download', filename);
      document.body.appendChild(downloadLink);
      downloadLink.click();
    });
  }
}
