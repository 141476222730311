import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';
import { UserService } from '../services/eic/user.service';
import { AppConfigService } from '../services/shared/app-config.service';

@Injectable({
  providedIn: 'root',
})
export class EicGuard extends KeycloakAuthGuard {
  constructor(
    protected override readonly router: Router,
    protected readonly keycloak: KeycloakService,
    protected readonly appConfig: AppConfigService,
    protected readonly _userService: UserService
  ) {
    super(router, keycloak);
  }

  public async isAccessAllowed(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    // Force the user to log in if currently unauthenticated.
    if (!this.authenticated) {
      await this.keycloak.login({
        idpHint: 'GrandId-BankId',
        redirectUri: window.location.origin + state.url,
      });
    }

    // Get the roles required from the route.
    const requiredRoles = route.data['roles'];

    // Allow the user to proceed if no additional roles are required to access the route.
    if (!(requiredRoles instanceof Array) || requiredRoles.length === 0) {
      return true;
    }

    // Allow the user to proceed if all the required roles are present.
    if (requiredRoles.every((role) => this.roles.includes(role))) {
      // First time user log in
      let userCompletedAccount: boolean =
        await this._userService.accountComplete();

      if (userCompletedAccount) {
        return true;
      } else {
        state.url === '/eic/account'
          ? null
          : this.router.navigate(['/eic/account']);
        return true;
      }
    } else {
      this.router.navigate(['/not-authorized']);
      return false;
    }
  }
}
