import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'signatureFilter',
})
export class SignatureFilterPipe implements PipeTransform {
  transform(array: any, filter: string): any[] {
    if (!array || !filter) return array;

    let filteredArray = array.filter((data: any) => data.type === filter);
    return filteredArray;
  }
}
