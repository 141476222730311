import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';

@Component({
  selector: 'app-study-auth',
  templateUrl: './study-auth.component.html',
})
export class StudyAuthComponent implements OnInit {
  @Input() message!: string;
  private currentUrl: string;
  loginMethods = [
    {
      id: 1,
      hint: 'GrandId-BankId',
      name: 'BankID',
      type: 'button',
      logo: '/assets/images/logos/bank-id.svg',
      class: 'text-sky-600 shadow-sm ring-1 ring-sky-600/10 hover:ring-sky-600/20',
    },
  ];
  constructor(
    private router: Router,
    private keycloakService: KeycloakService,
  ) {
    const urlTree = this.router.url.split('/');
    this.currentUrl = '/' + urlTree[1] + '/' + urlTree[2] + '/' + urlTree[3];
    //localStorage.setItem('redirectUri', this.currentUrl);
  }

  ngOnInit(): void {}

  login(idpHint: string) {
    this.keycloakService.login({
      idpHint: idpHint,
      redirectUri: window.location.origin + this.currentUrl,
    });
  }
}
