import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { StudyComponent } from '../../../layout/portal/study/study.component';
import { StudyConsentComponent } from './study-consent/study-consent.component';
import { StudyContactComponent } from './study-contact/study-contact.component';
import { StudiesComponent } from './studies.component';
import { ConsentComponent } from './study-consent/components/consent/consent.component';
import { ResultComponent } from './study-consent/components/result/result.component';
import { SiteComponent } from './study-consent/components/site/site.component';
import { StudyLoadingComponent } from './study-loading/study-loading.component';
import { StudyJourneyComponent } from './study-journey/study-journey.component';

const routes: Routes = [
  {
    path: '',
    component: StudiesComponent,
  },
  {
    path: 'study/:name',
    component: StudyLoadingComponent,
  },
  {
    path: 'study/:name/:studyId',
    component: StudyComponent,
    children: [
      {
        path: '',
        component: StudyJourneyComponent,
      },
      {
        path: 'contact-information',
        component: StudyContactComponent,
      },
      {
        path: 'my-participation',
        component: StudyConsentComponent,
        children: [
          {
            path: 'consent',
            component: ConsentComponent,
          },
          {
            path: 'results',
            component: ResultComponent,
          },
          {
            path: 'site',
            component: SiteComponent,
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class StudiesRoutingModule {}
