import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { GuardianSearchResponse, IFamilyTreeRequest, IFamilyTreeWithConsents } from '../../models/family.model';
import { IConsent, IConsentFlowsRequest, IConsentsRequest } from '../../models/consent.model';
import { ConsentFlowService } from './consent-flow.service';
import { catchError, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ConsentService {
  private apiUrlPortal: string = environment.apiUrlPortal + '/studies';
  private apiUrl: string = environment.apiUrlPortal;

  constructor(
    private http: HttpClient,
    private _consentFlowService: ConsentFlowService,
  ) {}

  // ---- Get Consents (My studies)

  // ---- Download Consent PDF
  getConsentPDF(studyId: string, siteId: string, consentId: string) {
    return this.http.get(`${this.apiUrlPortal}/${studyId}/sites/${siteId}/consents/${consentId}/pdf`, {
      observe: 'response',
      responseType: 'blob',
    });
  }
  //get result

  //consent flow
  initializeConsentFlow(studyId: string, siteId: string, context: any) {
    return this.http.post<IConsentFlowsRequest>(
      `${this.apiUrlPortal}/${studyId}/sites/${siteId}/consent-flows`,
      context,
    );
  }
  //  -- patient only
  patientSignature(studyId: string, siteId: string, consentFlowId: string, patientSignatureData: any) {
    return this.http.post(
      `${this.apiUrlPortal}/${studyId}/sites/${siteId}/consent-flows/${consentFlowId}/transitions/patient-signature`,
      patientSignatureData,
    );
  }
  collectPatientSignature(studyId: string, siteId: string, consentFlowId: string) {
    return this.http.get(
      `${this.apiUrlPortal}/${studyId}/sites/${siteId}/consent-flows/${consentFlowId}/transitions/collect`,
      { observe: 'body' },
    );
  }
  getPatientConsent(data: IConsentsRequest) {
    return this.http.post<IConsent[]>(`${this.apiUrl}/consents`, data);
  }

  //  -- family
  guardianSignature(studyId: string, siteId: string, consentFlowId: string, dependentSignatureData: any) {
    return this.http.post(
      `${this.apiUrlPortal}/${studyId}/sites/${siteId}/consent-flows/${consentFlowId}/family-transitions/parent-signature`,
      dependentSignatureData,
    );
  }
  otherGuardianSignature(studyId: string, siteId: string, consentFlowId: string, signatureMethod: any) {
    return this.http.post(
      `${this.apiUrlPortal}/${studyId}/sites/${siteId}/consent-flows/${consentFlowId}/family-transitions/other-parent-signature`,
      signatureMethod,
    );
  }

  collectGuardianSignature(studyId: string, siteId: string, consentFlowId: string) {
    return this.http.get(
      `${this.apiUrlPortal}/${studyId}/sites/${siteId}/consent-flows/${consentFlowId}/family-transitions/collect`,
      { observe: 'body' },
    );
  }
  // ---- family tree
  getGuardians(personalNumber: string) {
    return this.http.post<GuardianSearchResponse>(`${this.apiUrl}/family-tree/guardian-searches`, {
      personalNumber: personalNumber,
    });
  }
  getDependents(request?: IFamilyTreeRequest): Observable<IFamilyTreeWithConsents> {
    const url = `${this.apiUrl}/family-tree/dependents-with-consents`;

    return this.http.post<IFamilyTreeWithConsents>(url, request).pipe(
      map((response) => {
        if (response && response.dependentsWithConsents.length > 0) {
          this._consentFlowService.setUserChildren(response);
        }
        return response;
      }),
      catchError(() => {
        return of({
          personalNumber: '',
          firstName: '',
          middleName: '',
          lastName: '',
          dependentsWithConsents: [],
        });
      }),
    );
  }
}
