<div *ngIf="eSignatureStep === 1">
  <div class="block">
    <p class="mt-2 text-lg font-semibold text-gray-900">Gå med i studien</p>
    <p class="mt-3 text-sm text-gray-500">
      Det är bara du som vuxen som kan samtycka till att delta i en forskningsstudie. Du som vårdnadshavare kan också
      samtycka till deltagande för dina barn. För att ett samtycke för ett barn ska bli giltigt behöver samtliga
      vårdnadshavare samtycka till deltagandet för barnet.
    </p>
  </div>
  <div class="mx-auto mt-3 max-w-xl text-sm text-gray-500">
    <ul role="list" class="list-disc space-y-2">
      <li>
        Om du och ett eller flera av dina barn lämnat blodprov och en familjeutredning gjorts, behöver du samtycka både
        för dig själv och för ditt/dina barn.
      </li>
      <li>Om endast barnet lämnat blodprov, samtyck då endast som vårdnadshavare.</li>
    </ul>
  </div>

  <div class="mx-auto max-w-2xl py-8">
    <fieldset>
      <legend class="sr-only">Select flow</legend>
      <div class="space-y-4">
        <label
          *ngFor="let option of flowOptions"
          [ngClass]="[
            selectedOption === option.value
              ? 'border-transparent border-sky-500 ring-2 ring-sky-500'
              : 'border-gray-300'
          ]"
          class="relative block cursor-pointer rounded-lg border bg-white px-6 py-4 shadow-sm focus:outline-none sm:flex sm:justify-between"
        >
          <input type="radio" name="flow-option" (change)="selectFlow(option)" [value]="option.value" class="sr-only" />
          <span class="flex items-center">
            <span class="flex flex-col text-sm">
              <span class="font-medium text-gray-900">{{ option.title }}</span>
              <span class="text-gray-500">
                <span class="block sm:inline">{{ option.text }}</span>
              </span>
            </span>
          </span>
          <span
            [ngClass]="[selectedOption === option.value ? 'border border-sky-500' : 'border-2 border-transparent']"
            class="pointer-events-none absolute -inset-px rounded-lg"
            aria-hidden="true"
          ></span>
        </label>
      </div>
    </fieldset>
    <app-alerts
      *ngIf="errorMessage.age != ''"
      [margin]="'mt-2 mb-2'"
      [type]="'danger'"
      [message]="errorMessage.age"
      (onClick)="errorMessage.age = ''"
    ></app-alerts>
    <div class="mt-6 text-right">
      <span class="relative inline-flex">
        <app-button [label]="'Nästa steg'" (onClick)="nextStep()" [disabled]="!selectedOption"> </app-button>
        <span *ngIf="selectedOption" class="absolute top-0 right-0 -mt-1 -mr-1 flex h-3 w-3">
          <span class="absolute inline-flex h-full w-full animate-ping rounded-full bg-sky-400 opacity-75"></span>
          <span class="relative inline-flex h-3 w-3 rounded-full bg-sky-500"></span>
        </span>
      </span>
    </div>
  </div>
</div>
<!-- 
    * Family consent step 
  -->
<div *ngIf="eSignatureStep === 2">
  <div class="text-right">
    <app-button [label]="'Tillbaka ett steg'" (onClick)="prevStep()"> </app-button>
  </div>
  <div class="mt-2 block">
    <p class="text-sm text-gray-500">
      Här kan du lämna samtycke för barn som du är vårdnadshavare för. För barnet måste båda vårdnadshavarna signera
      samtycket (om du inte har ensam vårdnad). Om den andra vårdnadshavaren redan lämnat samtycke för barnet så ser du
      detta under ”Påbörjade signeringar”.
      <br /><br />
      Ibland har endast barnet lämnat blodprov, men vid en familjeutredning så ingår ofta båda föräldrarna i utredningen
      och som förälder behöver du i så fall lämna samtycke för dig själv och för de barn som ska delta i studien.
    </p>
  </div>

  <div class="mb-2 block pt-10 pb-2">
    <p class="text-base font-semibold text-gray-700">Påbörjade signeringar</p>
    <p class="mt-3 text-sm text-gray-500">
      Här ser du barn där den andra vårdnadshavaren redan lämnat samtycke. Vid gemensam vårdnad behöver även du signera
      för att barnet ska kunna delta i studien.
    </p>
  </div>
  <app-alerts
    *ngIf="errorMessage.signatureExists != ''"
    [margin]="'mb-2'"
    [type]="'danger'"
    [message]="errorMessage.signatureExists"
    (onClick)="errorMessage.signatureExists = ''"
  ></app-alerts>
  <div *ngIf="loadingDependents" class="mt-2 animate-pulse py-4">
    <div *ngFor="let _ of [].constructor(4); let i = index" class="h-12 border border-gray-200 bg-slate-100"></div>
  </div>
  <div [ngClass]="loadingDependents ? 'hidden' : 'visible'" class="overflow-hidden bg-white shadow sm:rounded-md">
    <ul role="list" class="divide-y divide-gray-200">
      <li *ngFor="let data of dependentsConsents; let i = index">
        <a role="button" (click)="onContinueGuardianTwo(data)" type="button" class="block hover:bg-gray-50">
          <div class="flex items-center px-4 py-4 sm:px-6">
            <div class="flex min-w-0 flex-1 items-center">
              <div class="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                <div>
                  <p class="truncate text-sm font-medium text-gray-600">
                    {{ data.dependent.firstName }} {{ data.dependent.lastName }}
                  </p>
                  <p class="mt-2 text-sm text-gray-500">
                    <span class="truncate">
                      {{ data.dependent.personalNumber | slice: 0 : 8 }}
                      {{ ' - ' }}
                    </span>
                    <span *ngFor="let _ of [].constructor(4)" class="text-lg tracking-wide text-gray-900">&bull;</span>
                  </p>
                </div>
                <div class="hidden md:block">
                  <div>
                    <p class="text-sm text-gray-900">
                      Samtycket skapades
                      {{ data.consents[0]?.createdAt | date: 'YYYY-MM-dd' }}
                    </p>
                    <p class="mt-2 flex items-center text-sm text-gray-500">
                      <svg
                        *ngIf="data.consents[0]?.signatures.length > 1"
                        class="mr-1.5 h-5 w-5 flex-shrink-0 text-green-400"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                          clip-rule="evenodd"
                        />
                      </svg>
                      <svg
                        *ngIf="data.consents[0]?.signatures.length === 1"
                        class="mr-1.5 h-5 w-5 flex-shrink-0 text-blue-400"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm8.706-1.442c1.146-.573 2.437.463 2.126 1.706l-.709 2.836.042-.02a.75.75 0 01.67 1.34l-.04.022c-1.147.573-2.438-.463-2.127-1.706l.71-2.836-.042.02a.75.75 0 11-.671-1.34l.041-.022zM12 9a.75.75 0 100-1.5.75.75 0 000 1.5z"
                          clip-rule="evenodd"
                        />
                      </svg>
                      {{
                        data.consents[0]?.signatures.length === 1
                          ? 'Samtycke behöver signeras'
                          : 'Samtycke har signerats av alla vårdnadshavare'
                      }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <svg
                class="h-5 w-5 text-gray-400"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
          </div>
        </a>
      </li>
      <li *ngIf="dependentsConsents.length < 1">
        <p class="py-4 text-center text-base font-medium text-gray-700">Det finns inga påbörjade samtycken</p>
      </li>
    </ul>
  </div>

  <div class="mb-2 block pt-10 pb-2">
    <p class="text-base font-semibold text-gray-700">Välj det barn som du vill samtycka för</p>
    <p class="mt-1 text-sm italic">OBS! Du kan endast samtycka för ett barn åt gången</p>
  </div>

  <div *ngIf="loadingDependents" class="mx-auto max-w-2xl animate-pulse px-4 sm:px-6 lg:px-8">
    <div
      *ngFor="let _ of [].constructor(4); let i = index"
      [ngClass]="[i === 0 ? 'rounded-tl-md rounded-tr-md' : '', i === _ - 1 ? 'rounded-bl-md rounded-br-md' : '']"
      class="h-12 border border-gray-200 bg-slate-100"
    ></div>
  </div>

  <div *ngIf="error" class="py-16">
    <div class="text-center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="mx-auto h-12 w-12 text-yellow-500"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z"
        />
      </svg>

      <h3 class="mt-2 text-base font-medium text-gray-900">Något gick fel</h3>
      <p class="mt-1 text-sm text-gray-500">Det gick inte att hämta dina barn.</p>
      <div class="mt-4">
        <app-button [label]="'Försök igen'" (onClick)="onGetDependents()"> </app-button>
      </div>
    </div>
  </div>
  <div [ngClass]="success ? 'visible' : 'hidden'" class="mx-auto max-w-2xl px-4 sm:px-6 lg:px-8">
    <fieldset>
      <div class="relative mt-2 -space-y-px rounded-md bg-white">
        <label
          *ngFor="let data of dependents; let i = index"
          [ngClass]="[
            selectedDependent === data ? 'z-10 border-sky-200 bg-sky-50' : 'border-gray-200',
            i === 0 ? 'rounded-tl-md rounded-tr-md' : '',
            i === data.dependent.length - 1 ? 'rounded-bl-md rounded-br-md' : '',
            selectedDependent === data ? 'z-10 border-indigo-200 bg-indigo-50' : 'border-gray-200'
          ]"
          class="relative flex cursor-pointer flex-col border p-4 focus:outline-none md:grid md:grid-cols-3 md:pl-4 md:pr-6"
        >
          <span class="flex items-center text-sm">
            <input
              type="radio"
              name="child"
              (change)="selectDependent(data)"
              [value]="data.dependent"
              class="h-4 w-4 border-gray-300 text-sky-600 focus:ring-sky-500"
            />
            <span
              [id]="data.dependent.personalNumber"
              [ngClass]="[selectedDependent === data ? 'text-sky-900' : 'text-gray-900']"
              class="ml-3 font-medium"
              >{{ data.dependent.firstName }} {{ data.dependent.lastName }}</span
            >
          </span>
          <span [id]="data.dependent.personalNumber" class="ml-6 pl-1 text-sm md:ml-0 md:pl-0 md:text-center">
            <span
              [ngClass]="[selectedDependent === data ? 'text-sky-900' : 'text-gray-900']"
              class="font-medium tracking-wide"
              >{{ data.dependent.personalNumber | slice: 0 : 8 }} {{ ' - ' }}
            </span>
            <span *ngFor="let _ of [].constructor(4)" class="text-lg tracking-wide text-gray-900">&bull;</span>
          </span>
        </label>
      </div>
    </fieldset>
    <div class="mt-6 text-right">
      <app-alerts
        *ngIf="errorMessage.age != ''"
        [margin]="'mb-2'"
        [type]="'danger'"
        [message]="errorMessage.age"
        (onClick)="errorMessage.age = ''"
      ></app-alerts>
      <span class="relative inline-flex">
        <app-button
          [label]="'Nästa steg'"
          (onClick)="nextStep(); errorMessage.signatureExists = ''"
          [disabled]="!selectedDependent.dependent"
        >
        </app-button>
        <span *ngIf="selectedDependent.dependent" class="absolute top-0 right-0 -mt-1 -mr-1 flex h-3 w-3">
          <span class="absolute inline-flex h-full w-full animate-ping rounded-full bg-sky-400 opacity-75"></span>
          <span class="relative inline-flex h-3 w-3 rounded-full bg-sky-500"></span>
        </span>
      </span>
    </div>
  </div>
</div>
<!-- 
    * FPI step
  -->
<div *ngIf="eSignatureStep === 3">
  <div class="text-right">
    <app-button [label]="'Tillbaka ett steg'" (onClick)="prevStep()"> </app-button>
  </div>
  <div class="mt-2 block">
    <p class="text-lg font-semibold text-gray-900">Läs igenom forskningspersonsinformationen</p>
    <p class="mt-3 text-sm text-gray-500">
      Det här pdf-dokumentet innehåller den formella forskningspersonsinformation som utgör grunden för samtycke att
      delta i studien. Läs hela dokumentet noga innan du väljer att signera för dig själv eller för ett barn du är
      vårdnadshavare för (texten är densamma för både vuxna och barn). Nedanför pdf-dokumentet på denna sida har du
      möjlighet att gå vidare med signering av ditt samtycke.
    </p>
  </div>
  <div *ngIf="studySites.length > 0" class="mx-auto mt-8 max-w-xl">
    <div class="pb-4 text-center">
      <h2 class="mt-2 text-base font-medium text-gray-900">
        Välj din vårdenhet för att läsa forskningspersonsinformationen
      </h2>
    </div>
    <form class="flex">
      <select
        id="site"
        [ngModel]="siteId"
        (ngModelChange)="onChangeSite($event)"
        name="site"
        class="mt-1 block w-full rounded-md border border-gray-300 py-2 pl-3 pr-10 text-lg focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
      >
        <option value="" disabled selected>Välj vårdenhet</option>
        <option [disabled]="otherGuardianSignature" *ngFor="let site of studySites" [value]="site.id">
          {{ site.name }}
        </option>
      </select>
    </form>
  </div>
  <div *ngIf="fpis.length">
    <app-fpi-viewer
      [studyName]="study.name"
      [fpiDownloadLink]="fpiDownloadLink"
      [loadingEmbedPdf]="loadingEmbedPdf"
      [fpiPDF]="fpiPDF"
    ></app-fpi-viewer>
    <div *ngIf="!loadingEmbedPdf" class="mx-auto max-w-xl">
      <fieldset>
        <div class="relative flex items-start">
          <div class="flex">
            <input
              type="checkbox"
              id="read-document"
              aria-describedby="read-document-description"
              name="read-document"
              [checked]="readFpi"
              (change)="readFpi = !readFpi"
              class="mt-1 h-6 w-6 rounded border-gray-300 text-sky-600 focus:ring-sky-500"
            />
            <div class="ml-3 text-base">
              <label for="read-document" class="font-medium text-gray-900"
                >Jag har läst forskningspersonsinformationen och samtycker till att gå med i studien på det sättet som
                det anges i forskningspersonsinformationen.
              </label>
            </div>
          </div>
        </div>
      </fieldset>
      <div *ngIf="readFpi">
        <form class="mt-6">
          <div class="overflow-hidden shadow-lg sm:rounded-md">
            <div class="space-y-6 bg-white px-4 py-5 sm:p-6">
              <fieldset>
                <div>
                  <legend class="text-base font-medium text-gray-900">Kontaktuppgifter</legend>
                  <p class="mt-1 text-sm text-gray-500">
                    Dessa kontaktuppgifter kommer att delas med studien. Stämmer inte uppgifterna? Gå tillbaka till din
                    profil och uppdatera informationen. Därefter behöver du klicka på "Gå med" igen.
                  </p>
                </div>
                <div class="mt-4 space-y-4">
                  <div *ngIf="userProfile" class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <label class="block text-sm font-medium text-gray-700"> E-post </label>
                    <label *ngIf="userProfile.email" class="ml-3 block text-sm text-gray-500">
                      {{ userProfile.email }}
                    </label>
                  </div>
                </div>
                <div class="mt-4 space-y-4">
                  <div *ngIf="userProfile" class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <label class="block text-sm font-medium text-gray-700"> Telefonnummer </label>
                    <label class="ml-3 block text-sm text-gray-700">
                      {{ userProfile.phone }}
                    </label>
                  </div>
                </div>
              </fieldset>
            </div>
          </div>
        </form>
        <form class="mt-6">
          <div class="overflow-hidden shadow-lg sm:rounded-md">
            <div class="space-y-6 bg-white px-4 py-5 sm:p-6">
              <fieldset>
                <div>
                  <legend class="text-base font-medium text-gray-900">Signera ditt samtycke med e-signatur</legend>
                  <p class="mt-1 text-sm text-gray-500">
                    Genom att signera elektroniskt så samtycker du till att gå med i studien för dig själv eller för ett
                    barn du är vårdnadshavare för.
                  </p>
                </div>
              </fieldset>

              <app-alerts
                *ngIf="signatureLoading.bankid || signatureLoading.siths"
                [type]="'info'"
                [margin]="'my-2'"
                [message]="'Öppna ' + chosenIdentityProvider + ' för att signera'"
                [closeable]="false"
                [largeText]="false"
              ></app-alerts>

              <app-alerts
                *ngIf="errorMessage.signatureExists != ''"
                [type]="'danger'"
                [largeText]="false"
                [margin]="'my-2'"
                [message]="errorMessage.signatureExists"
                (onClick)="errorMessage.signatureExists = ''"
              ></app-alerts>

              <app-alerts
                *ngIf="errorSigning"
                [type]="'danger'"
                [message]="'Något gick fel. Försök igen eller testa senare.'"
                [closeable]="false"
                [largeText]="false"
                class="col-span-2"
              ></app-alerts>

              <button
                (click)="onInitConsentFlow('bankid')"
                type="button"
                class="group relative flex w-full justify-center rounded-md border border-sky-600 bg-white py-3 px-4 text-base font-medium text-sky-600 hover:bg-sky-50 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2"
              >
                <span class="absolute inset-y-0 left-2 flex items-center pl-3">
                  <img class="h-7 w-7" src="/assets/images/logos/bank-id.svg" alt="BankID" />
                </span>
                <svg
                  *ngIf="signatureLoading.bankid"
                  class="-ml-1 mr-3 h-5 w-5 animate-spin text-sky-500"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                  <path
                    class="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
                BankID
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<!-- 
    * Complete consent flow step
  -->
<div *ngIf="eSignatureStep === 4">
  <div class="mx-auto max-w-md rounded-xl px-4 pt-5 pb-4 ring-1 ring-inset ring-slate-900/[0.08]">
    <div>
      <div
        [ngClass]="
          selectedOption === 'patient' || (selectedOption === 'family' && otherGuardianSignature)
            ? 'bg-green-100'
            : 'bg-yellow-100'
        "
        class="mx-auto flex h-12 w-12 items-center justify-center rounded-full"
      >
        <app-icon
          *ngIf="selectedOption === 'patient' || (selectedOption === 'family' && otherGuardianSignature)"
          [name]="'check'"
          class="h-6 w-6 text-green-600"
        ></app-icon>
        <svg
          *ngIf="selectedOption === 'family' && !otherGuardianSignature"
          class="h-6 w-6 text-yellow-600"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fill-rule="evenodd"
            d="M8.485 3.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 3.495zM10 6a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 6zm0 9a1 1 0 100-2 1 1 0 000 2z"
            clip-rule="evenodd"
          />
        </svg>
      </div>
      <div class="mt-3 text-center sm:mt-5">
        <h3 class="text-lg font-medium leading-6 text-gray-900" id="modal-title">
          {{ selectedOption === 'patient' ? 'Du har nu gått med i studien' : 'Du har nu signerat' }}
        </h3>
        <div class="mt-2">
          <p class="text-sm text-gray-500">
            {{
              selectedOption === 'family' && otherGuardianSignature
                ? selectedDependent.dependent?.firstName +
                  ' ' +
                  selectedDependent.dependent?.lastName +
                  ' är nu med i studien.'
                : ''
            }}
          </p>
          <p *ngIf="selectedOption === 'family' && !otherGuardianSignature" class="text-sm text-gray-500">
            Observera att båda vårdnadshavarna måste signera för barn som ni har gemensam vårdnad för. Uppmana gärna den
            andra vårdnadshavaren att logga in för att kunna ge sitt samtycke.
          </p>
        </div>
      </div>
    </div>
    <div
      *ngIf="studySettings.symptomUrl && studySettings.symptomUrl != 'null'"
      class="divide-y divide-gray-200 rounded-md border border-gray-200"
    >
      <div class="flex flex-col py-3 pl-3 pr-4 text-sm">
        <div>
          <p class="text-sm font-medium text-gray-500">
            Du behöver nu ge information till studien i en digital enkät i systemet Symptoms
          </p>
        </div>

        <div>
          <div *ngIf="newConsent?.status === 'complete'" class="mt-3 sm:mt-4">
            <app-button
              (onClick)="onOpenLinkInNewWindow('https://' + studySettings.symptomUrl)"
              [label]="'Klicka här för att komma till den digitala enkäten'"
              [fullWidth]="true"
              [color]="true"
              [btnColor]="'bg-sky-600 text-white hover:bg-sky-500 active:opacity-75'"
            >
            </app-button>
          </div>
        </div>
      </div>
    </div>

    <div>
      <div class="mt-4 grid grid-cols-1 gap-12 sm:grid-cols-2 sm:gap-x-8 sm:gap-y-12 lg:col-span-2">
        <div>
          <h3 class="text-base font-medium leading-6 text-gray-900">Har du några frågor?</h3>
          <dl class="mt-2 text-sm text-gray-500">
            <div class="mt-1">
              <dt class="sr-only">Namn</dt>
              <dd>{{ site.localContactName }}</dd>
            </div>
            <div>
              <dt class="sr-only">Email</dt>
              <dd>{{ site.localContactEmail }}</dd>
            </div>
            <div class="mt-1">
              <dt class="sr-only">Telefonnummer</dt>
              <dd>{{ site.localContactPhone }}</dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
    <div *ngIf="studySettings.allowFamilyConsent">
      <div class="mt-5 sm:mt-6">
        <app-button
          (onClick)="resetFlow()"
          [label]="selectedOption === 'patient' ? 'Vill du signera för ditt barn?' : 'Gå vidare med nästa signatur'"
          [fullWidth]="true"
        >
        </app-button>
      </div>
      <div *ngIf="newConsent?.status === 'complete'" class="mt-3 sm:mt-4">
        <app-button
          (onClick)="downloadConsentPDF(newConsent?.siteId, newConsent?.id)"
          [label]="'Ladda ner signerat samtycke'"
          [fullWidth]="true"
          [color]="true"
          [btnColor]="'bg-sky-600 text-white hover:bg-sky-500 active:opacity-75'"
        >
        </app-button>
      </div>
    </div>
  </div>
</div>
