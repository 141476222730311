<div *ngIf="isPatientLoggedIn" class="pb-32">
  <h2 class="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">Mina studier</h2>
  <!-- Pending -->
  <div *ngIf="dependentsStudies.length" class="mt-4">
    <app-badge [label]="'Ännu ej giltiga samtycken'" [color]="'yellow'"></app-badge>
    <p class="mt-2 text-sm text-gray-500">
      Här ser du samtycken för barn som väntar på att bli giltiga. För att ett samtycke för ett barn ska bli giltigt
      behöver samtliga vårdnadshavare samtycka till deltagandet för barnet.
    </p>
    <div *ngIf="dependentsStudies.length === 0" class="mt-3 rounded-md border border-gray-300 py-8">
      <p class="mt-2 text-center text-xl text-gray-500">Inga väntande signeringar</p>
    </div>
    <div class="mt-5 overflow-hidden bg-white shadow sm:rounded-md" *ngFor="let item of dependentsStudies">
      <ul role="list" class="divide-y divide-gray-200">
        <li>
          <a
            [routerLink]="['/study', item.study.slug, item.site.studyId]"
            [queryParams]="{ site: item.site.id }"
            fragment="consent-flow"
            class="block hover:bg-gray-50"
          >
            <div class="flex items-center px-4 py-4 sm:px-6">
              <div class="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                <div class="flex space-x-4">
                  <div class="flex-shrink-0">
                    <img class="h-8 w-auto rounded-full lg:h-12" [src]="item.study.logo" alt="" />
                  </div>
                  <div class="truncate text-sm">
                    <div class="flex text-sm">
                      <p class="truncate font-medium text-gray-700">
                        {{ item.study.title }}
                      </p>
                    </div>
                    <div class="mt-2 flex">
                      <div class="flex items-center text-sm text-gray-500">
                        <p>
                          {{ item.site.name }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mt-4 flex-shrink-0 sm:mt-0 sm:ml-5">
                  <div>
                    <p class="flex text-sm font-medium text-blue-500 sm:justify-end">
                      {{ item.consent.patientName }}
                    </p>
                    <p class="mt-2 text-sm text-gray-900">
                      Samtycke registrerat
                      {{ item.consent.createdAt | date: 'YYYY-MM-dd' }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="ml-5 flex-shrink-0">
                <svg
                  class="h-5 w-5 text-gray-400"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
            </div>
          </a>
        </li>
      </ul>
    </div>
  </div>
  <!-- Active -->
  <div class="mt-4">
    <app-badge [label]="'Giltiga samtycken'" [color]="'sky'"></app-badge>
    <p class="mt-2 text-sm text-gray-500">
      Här ser du samtycken som är giltiga och där du eller ditt barn är deltagare i forskningsstudien.
    </p>
    <div
      *ngIf="(patientStudies | consentFilter: false : 'complete').length === 0"
      class="mt-3 rounded-md border border-gray-300 py-8"
    >
      <p class="mt-2 text-center text-xl text-gray-500">Du har inga aktiva studier</p>
    </div>
    <div
      class="mt-5 overflow-hidden bg-white shadow sm:rounded-md"
      *ngFor="let item of patientStudies | consentFilter: false : 'complete'"
    >
      <ul role="list" class="divide-y divide-gray-200">
        <li>
          <a [routerLink]="getStudyLink(item)" class="block hover:bg-gray-50">
            <div class="flex items-center px-4 py-4 sm:px-6">
              <div class="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                <div class="flex space-x-4">
                  <div class="flex-shrink-0">
                    <img class="h-8 w-auto rounded-full lg:h-12" [src]="item.study.logo" alt="" />
                  </div>
                  <div class="truncate text-sm">
                    <div class="flex text-sm">
                      <p class="truncate font-medium text-gray-700">
                        {{ item.study.title }}
                      </p>
                    </div>
                    <div class="mt-2 flex">
                      <div class="flex items-center text-sm text-gray-500">
                        <p>
                          {{ item.site.name }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mt-4 flex-shrink-0 sm:mt-0 sm:ml-5">
                  <div>
                    <p class="flex text-sm font-medium text-blue-500 sm:justify-end">
                      {{ item.consent.patientName }}
                    </p>
                    <p class="mt-2 text-sm text-gray-900">
                      Samtycke registrerat
                      {{ item.consent.createdAt | date: 'YYYY-MM-dd' }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="ml-5 flex-shrink-0">
                <svg
                  class="h-5 w-5 text-gray-400"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
            </div>
          </a>
        </li>
      </ul>
    </div>
  </div>
  <!-- Inactive -->
  <div *ngIf="(patientStudies | consentFilter: true).length" class="mt-4">
    <app-badge [label]="'Återkallade samtycken'" [color]="'gray'"></app-badge>
    <p class="mt-2 text-sm text-gray-500">Här ser du samtycken som har återkallats.</p>
    <div
      class="mt-5 overflow-hidden bg-gray-100 shadow sm:rounded-md"
      *ngFor="let item of patientStudies | consentFilter: true"
    >
      <ul role="list" class="divide-y divide-gray-200">
        <li>
          <a [routerLink]="getStudyLink(item)" class="block hover:bg-gray-50">
            <div class="flex items-center px-4 py-4 sm:px-6">
              <div class="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                <div class="flex space-x-4">
                  <div class="flex-shrink-0">
                    <img class="h-8 w-auto rounded-full lg:h-12" [src]="item.study.logo" alt="" />
                  </div>
                  <div class="truncate text-sm">
                    <div class="flex text-sm">
                      <p class="truncate font-medium text-gray-700">
                        {{ item.study.title }}
                      </p>
                    </div>
                    <div class="mt-2 flex">
                      <div class="flex items-center text-sm text-gray-500">
                        <p>
                          {{ item.site.name }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mt-4 flex-shrink-0 sm:mt-0 sm:ml-5">
                  <div>
                    <p class="flex text-sm font-medium text-blue-500 sm:justify-end">
                      {{ item.consent.patientName }}
                    </p>
                    <p class="mt-2 text-sm text-gray-700">
                      Samtycke återkallat
                      {{ item.consent.revokedAt | date: 'YYYY-MM-dd' }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="ml-5 flex-shrink-0">
                <svg
                  class="h-5 w-5 text-gray-400"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
            </div>
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>

<!-- List of other studies -->
<div class="divide-y-2 divide-gray-200">
  <div>
    <h2 class="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
      {{ isPatientLoggedIn ? 'Andra studier' : 'Studier' }}
    </h2>
    <div class="mt-3 sm:mt-4 lg:grid lg:grid-cols-3 lg:items-center lg:gap-5">
      <p class="text-lg text-gray-500 lg:col-span-3">
        Här finner du de studier som använder minforskning.se. För att gå med i en studie behöver du skapa en personlig
        profil på minforskning.se. Det sker när du klickar på "Logga in" för första gången.
        <br /><br />
        Vissa studier behöver du ha en personlig profil på minforskning.se för att kunna läsa mer om.
      </p>
    </div>
  </div>

  <div class="mt-6 grid gap-6 pt-10 lg:grid-cols-1 lg:gap-y-8">
    <div *ngFor="let study of studies">
      <a
        [routerLink]="study.setting.eIdentification && !isPatientLoggedIn ? '' : 'study/' + study.slug + '/' + study.id"
        [ngClass]="
          study.setting.eIdentification && !isPatientLoggedIn
            ? 'pointer-events-none cursor-default'
            : 'transition-all duration-300 ease-in-out hover:shadow-lg'
        "
        class="flex rounded-md border border-gray-200 px-4 py-6"
      >
        <div class="relative flex w-full flex-col lg:flex-row">
          <div class="mr-4 flex-shrink-0">
            <img class="h-16 w-16 object-contain" [src]="study.logo" [alt]="study.logo" />
          </div>
          <button
            type="button"
            (click)="login(study)"
            *ngIf="study.setting.eIdentification && !isPatientLoggedIn"
            class="pointer-events-auto absolute top-0 right-0 rounded-md hover:ring-1 hover:ring-sky-500 hover:ring-offset-1"
          >
            <app-badge
              [label]="'Logga in för att läsa studien'"
              [color]="'sky'"
              [large]="false"
              [rounded]="false"
            ></app-badge>
          </button>
          <div>
            <h3 class="text-lg font-semibold text-gray-900 sm:text-xl">
              {{ study.title }}
            </h3>
            <p *ngIf="study.description" class="mt-3 text-base text-gray-500">
              {{ study.shortDescription }}
            </p>
          </div>
        </div>
      </a>
    </div>
  </div>
</div>
