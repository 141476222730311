<div *ngIf="studySites.length > 0" class="mx-auto max-w-xl">
  <div class="pb-4 text-center">
    <h2 class="mt-4 text-lg font-medium text-gray-900">Välj vårdenhet för att läsa forskningspersonsinformation</h2>
  </div>
  <form class="flex">
    <select
      id="site"
      [(ngModel)]="selectedSite"
      (ngModelChange)="changeSite($event)"
      name="site"
      class="mt-1 block w-full rounded-md border border-gray-300 py-2 pl-3 pr-10 text-sm focus:border-sky-500 focus:outline-none focus:ring-sky-500"
    >
      <option [ngValue]="null" [disabled]="true">Välj vårdenhet</option>
      <option *ngFor="let site of studySites" [ngValue]="site">
        {{ site.name }}
      </option>
    </select>
  </form>
  <div *ngIf="selectedSite" class="mt-1 flex justify-end">
    <button
      type="button"
      (click)="openContactModal()"
      class="rounded bg-white px-2 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
    >
      Kontakt
    </button>
  </div>
</div>

<div *ngIf="errorMessage" class="my-8 rounded-md bg-red-50 p-4">
  <div class="flex">
    <div class="flex-shrink-0">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="h-5 w-5 text-red-400">
        <path
          fill-rule="evenodd"
          d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zM12 8.25a.75.75 0 01.75.75v3.75a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75zm0 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z"
          clip-rule="evenodd"
        />
      </svg>
    </div>
    <div class="ml-3">
      <h3 class="text-sm font-medium text-red-800">
        Det finns ingen aktuell versionen av Forskningspersonsinformation för vald vårdenhet
      </h3>
    </div>
  </div>
</div>

<ng-container *ngIf="siteId && !errorMessage">
  <app-fpi-viewer
    [studyName]="study?.name"
    [fpiDownloadLink]="fpiDownloadLink"
    [loadingEmbedPdf]="loadingEmbedPdf"
    [fpiPDF]="fpiPDF"
  ></app-fpi-viewer>
</ng-container>

<app-modal *ngIf="showModal" (close)="closeModal()" [largeDialog]="false">
  <app-contact-form-consent content [settings]="settings" [selectedSite]="selectedSite"></app-contact-form-consent>
</app-modal>
