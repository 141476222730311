<app-alerts
  *ngIf="errorMessage.age != ''"
  [margin]="'mt-2 mb-2'"
  [type]="'danger'"
  [message]="errorMessage.age"
  (onClick)="errorMessage.age = ''"
></app-alerts>

<div class="mx-auto max-w-2xl -space-y-px rounded-md bg-white">
  <div *ngFor="let step of currentSteps; let stepIdx = index">
    <div
      [ngClass]="{
        'rounded-tl-md rounded-tr-md': stepIdx === 0,
        'rounded-bl-md rounded-br-md': stepIdx === currentSteps.length - 1,
        'border p-2 sm:p-4': true
      }"
    >
      <div class="flex justify-between">
        <span class="flex items-center">
          <app-stepper-icons *ngIf="step.status" [status]="step.status"></app-stepper-icons>
          <span
            class="ml-1 block text-sm font-medium sm:ml-2 sm:text-base"
            [ngClass]="{ 'text-gray-900': currentStepIndex === stepIdx, 'text-gray-400': currentStepIndex !== stepIdx }"
          >
            {{ step.title }}
          </span>
        </span>

        <span
          class="text-sm font-medium sm:text-base"
          [ngClass]="{ 'text-gray-900': currentStepIndex === stepIdx, 'text-gray-400': currentStepIndex !== stepIdx }"
        >
          0{{ stepIdx + 1 }}
        </span>
      </div>
      <div class="relative mt-4" [ngClass]="{ hidden: stepIdx !== currentStepIndex }">
        <ng-template appDynamicStepHost></ng-template>
      </div>
    </div>
  </div>
</div>
