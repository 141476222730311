import { HttpClient } from '@angular/common/http';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { Subscription } from 'rxjs';
import { ISettings } from 'src/app/core/models/study-settings.model';
import { StudyService } from 'src/app/core/services/portal/study.service';
import { UserService } from 'src/app/core/services/portal/user.service';
import { HelperService } from 'src/app/core/services/shared/helper.service';
import { environment } from 'src/environments/environment';

interface Route {
  url: string;
  title: string;
  description: string;
}

@Component({
  selector: 'app-study',
  templateUrl: './study.component.html',
})
export class StudyComponent implements OnInit, OnDestroy {
  private apiUrl: string = environment.apiUrlCommon;
  private studyId!: string;
  public isPatientLoggedIn = false;
  patientStudies: any = [];
  studyNavigation: Route[] = [];
  study: any = {};
  studySettings?: ISettings;
  mobileNavigation: boolean = false;
  currentRouteUrl: Route | null = null;
  userHasJoined!: boolean;
  set userJoined(value: boolean) {
    if (value) {
      this.userHasJoined = value;
      this.addNavItem();
    }
  }
  subscription: Subscription;

  constructor(
    private router: Router,
    private http: HttpClient,
    private _userService: UserService,
    private keycloakService: KeycloakService,
    private _studyService: StudyService,
    private _helperService: HelperService,
    private activatedRoute: ActivatedRoute,
  ) {
    this.activatedRoute.params.subscribe((params) => {
      this.studyId = params['studyId'];
    });
    this.getStudy();
    this.subscription = this._helperService.userCompletedSignature$.subscribe((value) => (this.userJoined = value));
  }

  public async ngOnInit() {
    this.getStudySettings();
    this.router.events.subscribe(async (event) => {
      if (event.constructor.name === 'NavigationEnd') {
        this.isPatientLoggedIn = await this.keycloakService.isLoggedIn();
        if (!this.isPatientLoggedIn) {
          this.removeNavItem();
        }
      }
    });
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
    this._helperService.updateUserCompletedSignature(false);
  }
  /* 
    ---- 
    Functions
    ---- 
  */
  updateCurrentRouteUrl() {
    const currentUrl = this.router.url.split('/')[4] || './';
    const urlIndex = this.studyNavigation.findIndex((item) => item.url.includes(currentUrl));
    this.currentRouteUrl = this.studyNavigation[urlIndex] || null;
  }

  showNavigation(settings: ISettings) {
    this.studyNavigation = [
      {
        url: './',
        title: 'Introduktion',
        description:
          settings?.fromAge >= settings?.childAgeLimit
            ? 'Här kan du läsa mer om vad studien innebär. Om du tycker att den passar dig har du möjlighet att genom ett aktivt val gå med i studien'
            : 'Här kan du läsa mer om vad studien innebär och vad du lämnar samtycke till. Här finns också information om hur du går med i studien för dig själv och/eller för dina barn',
      },
      {
        url: 'contact-information',
        title: 'Kontakt',
        description: 'Här finner du kontaktuppgifter till studien',
      },
    ];
    this.updateCurrentRouteUrl();
  }

  addNavItem() {
    const navItem = {
      url: 'my-participation/consent',
      title: 'Mitt deltagande',
      description:
        'Här kan du titta på eller ladda ner de samtycken som du signerat. För vissa studier, men inte alla, kan du ta del av resultat och meddelanden från studien. Här finns också kontaktuppgifter till din vårdenhet',
    };
    if (this.studyNavigation.length < 4) {
      this.studyNavigation.push(navItem);
      this.updateCurrentRouteUrl();
    }
  }
  removeNavItem() {
    const navIndex = this.studyNavigation.findIndex((el) => el.url === 'my-participation/consent');
    if (navIndex > -1) {
      this.studyNavigation.splice(navIndex, 1);
      this.updateCurrentRouteUrl();
    }
  }
  onMobileNavigation() {
    this.mobileNavigation = !this.mobileNavigation;
  }
  onSelectRoute(navItem: Route) {
    this.currentRouteUrl = navItem;
    this.onMobileNavigation();
  }
  /* 
    ---- 
    APIs
    ---- 
  */
  getStudy() {
    this.http.get<any>(`${this.apiUrl}/study/${this.studyId}`).subscribe({
      next: (response) => {
        this.study = response;
      },
      error: () => {
        this.router.navigate(['404']);
      },
    });
  }
  getStudySettings() {
    this._studyService.getStudySetting(this.studyId).subscribe(async (settings: ISettings) => {
      this.studySettings = settings;
      this.showNavigation(settings);
      this.isPatientLoggedIn = await this.keycloakService.isLoggedIn();
      if (this.isPatientLoggedIn) {
        this.getPatientStudies();
      }
    });
  }
  getPatientStudies() {
    this._userService.getMyStudies().subscribe((response) => {
      this.patientStudies = response.filter((data: any) => data.site.studyId === this.studyId);
      if (this.patientStudies.length > 0) {
        this.addNavItem();
      }
    });
  }
}
