import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';

import { StudiesModule } from './routes/portal/studies/studies.module';
import { SharedModule } from './shared/shared.module';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { NavbarComponent } from './layout/portal/navbar/navbar.component';
import { FaqComponent } from './routes/portal/faq/faq.component';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { initializeKeycloak } from './core/utils/app.init';
import { ProfileComponent } from './routes/portal/profile/profile.component';
import { PageNotFoundComponent } from './routes/errors/page-not-found.component';
import { NotAuthorizedComponent } from './routes/errors/not-authorized.component';
import { AboutComponent } from './routes/portal/about/about.component';
import { AppConfigService } from './core/services/shared/app-config.service';

const appInit =
  (appConfigService: AppConfigService) =>
    async () => {
      const appConfig = await appConfigService.load();
    };


@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    ProfileComponent,
    FaqComponent,
    PageNotFoundComponent,
    NotAuthorizedComponent,
    AboutComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    KeycloakAngularModule,
    FormsModule,
    ReactiveFormsModule,
    StudiesModule,
    SharedModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInit,
      multi: true,
      deps: [AppConfigService],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
