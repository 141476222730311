<div
  class="fixed z-10 inset-0 overflow-y-auto"
  aria-labelledby="modal-title"
  role="dialog"
  aria-modal="true"
>
  <div
    class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
  >
    <div
      (click)="close.emit()"
      class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
      aria-hidden="true"
    ></div>

    <span
      class="hidden sm:inline-block sm:align-middle sm:h-screen"
      aria-hidden="true"
      >&#8203;</span
    >

    <div
      [ngClass]="largeDialog ? 'sm:max-w-7xl' : 'sm:max-w-xl'"
      class="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-full sm:p-6"
    >
      <!-- Close modal -->
      <div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
        <button
          *ngIf="closeButton"
          (click)="close.emit()"
          type="button"
          class="bg-white rounded-full p-2 text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none"
        >
          <span class="sr-only">Close</span>
          <svg
            class="h-6 w-6"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
      <ng-content select="[tabs]"></ng-content>
      <ng-content select="[header]"></ng-content>
      <ng-content select="[helpText]"></ng-content>
      <ng-content select="[content]"></ng-content>
    </div>
  </div>
</div>
