import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StudyService } from 'src/app/core/services/portal/study.service';

@Component({
  selector: 'app-study-loading',
  templateUrl: './study-loading.component.html',
})
export class StudyLoadingComponent implements OnInit {
  private studySlug: string;
  constructor(
    private router: Router,
    private _studyService: StudyService,
  ) {
    this.studySlug = this.router.url.split('/')[2];
  }

  ngOnInit(): void {
    this.findStudy();
  }

  findStudy() {
    this._studyService.getStudySlug(this.studySlug).subscribe({
      next: (response: any) => {
        const url = this.router.url + '/' + response.id;
        this.router.navigate([url]);
      },
      error: () => {
        this.router.navigate(['404']);
      },
    });
  }
}
