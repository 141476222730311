import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
})
export class ButtonComponent implements OnInit {
  @Output() onClick = new EventEmitter();
  @Input() label!: string;
  @Input() isPrimary: boolean = false;
  @Input() fullWidth?: boolean = false;
  @Input() type?: string = 'button';
  @Input() disabled?: boolean = false;
  @Input() textLarge?: boolean = false;
  @Input() color?: boolean = false;
  @Input() btnColor?: string;
  constructor() {}

  ngOnInit(): void {}
}
