import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { EMPTY, TimeoutError, combineLatest } from 'rxjs';
import { Subscription } from 'rxjs/internal/Subscription';
import { interval } from 'rxjs/internal/observable/interval';
import { throwError } from 'rxjs/internal/observable/throwError';
import { delay } from 'rxjs/internal/operators/delay';
import { retryWhen } from 'rxjs/internal/operators/retryWhen';
import { startWith } from 'rxjs/internal/operators/startWith';
import { switchMap } from 'rxjs/internal/operators/switchMap';
import { take } from 'rxjs/internal/operators/take';
import { of, timer } from 'rxjs';
import { catchError, concatMap, takeUntil, takeWhile } from 'rxjs/operators';
import { IFpi } from 'src/app/core/models/fpi.model';
import { ConsentFlowService } from 'src/app/core/services/portal/consent-flow.service';
import { ConsentService } from 'src/app/core/services/portal/consent.service';
import { FpiService } from 'src/app/core/services/portal/fpi.service';
import {
  EFlowType,
  ESignatureMethod,
  IConsent,
  IConsentFlowInitiate,
  IConsentFlowsRequest,
  IdpData,
} from 'src/app/core/models/consent.model';
import { IPerson } from 'src/app/core/models/family.model';

@Component({
  selector: 'app-fpi-qrcode',
  templateUrl: './fpi-qrcode.component.html',
})
export class FpiQrcodeComponent implements OnInit, OnDestroy {
  private _fpi?: IFpi;
  private _patientPersonalNumber!: string;
  private _transitionLink?: string;
  private _consentFlowType!: EFlowType;
  private subscriptions = new Subscription();

  @Input()
  set fpi(value: IFpi | undefined) {
    this._fpi = value;
  }

  get fpi() {
    return this._fpi;
  }

  @Input()
  set patientPersonalNumber(value: string) {
    this._patientPersonalNumber = value;
  }

  get patientPersonalNumber() {
    return this._patientPersonalNumber;
  }

  @Input()
  set consentFlowType(value: EFlowType) {
    this._consentFlowType = value;
  }

  get consentFlowType() {
    return this._consentFlowType;
  }

  @Input()
  set transitionLink(value: string | undefined) {
    this._transitionLink = value;
  }

  get transitionLink() {
    return this._transitionLink;
  }

  @Output() stepComplete: EventEmitter<IConsentFlowsRequest> = new EventEmitter<IConsentFlowsRequest>();

  userReadFpi: boolean = false;
  showRestartButton: boolean = false;
  consentSessionCancelled: boolean = false;
  consentInitiated: IConsent | null = null;
  consentFlowData: IConsentFlowsRequest | null = null;
  errorSigning: boolean = false;
  signatureLoading: boolean = false;
  timeInterval = new Subscription();
  autoStartToken?: string;
  svgQrCode: string = '';
  dependent?: IPerson;
  dependentPersonalNumber: string | null = null;
  idpData: IdpData | null = null;

  constructor(
    private _consentService: ConsentService,
    private _consentFlowService: ConsentFlowService,
    private _fpiService: FpiService,
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      combineLatest([
        this._consentFlowService.fpiRead$,
        this._consentFlowService.consentInitiated$,
        this._consentFlowService.dependent$,
      ]).subscribe(([fpiRead, consentInitiated, dependent]) => {
        this.userReadFpi = fpiRead;
        this.consentInitiated = consentInitiated;
        this.dependent = dependent?.dependent;
        this.dependentPersonalNumber = dependent?.dependent?.personalNumber;
      }),
    );
    if (this.fpi) {
      this.reactToFpiAndConsentState(this.userReadFpi, this.consentInitiated);
    }
  }

  ngOnDestroy() {
    this.stopPolling();
    this.subscriptions.unsubscribe();
  }

  /* 

    Functions
  
  */
  completeStep(newConsent: IConsentFlowsRequest): void {
    this.stepComplete.emit(newConsent);
    this._consentFlowService.setConsentInitiated(null);
  }

  reactToFpiAndConsentState(fpiRead: boolean, consentInitiated: IConsent | null) {
    if (!fpiRead) return;

    const isDoctorFlow = this.consentFlowType.includes('doctor');
    const isPatientFlow = this.consentFlowType === 'patient';
    const isFamilyFlow = this.consentFlowType === 'family';

    if (isPatientFlow) {
      consentInitiated
        ? this.onSignature(consentInitiated.transitionLink, consentInitiated.type)
        : this.onInitiateConsentFlow();
      return;
    }

    if (isFamilyFlow) {
      consentInitiated
        ? this.onSignature(consentInitiated.transitionLink, consentInitiated.type, consentInitiated.status)
        : this.onInitiateConsentFlow();
      return;
    }

    if (isDoctorFlow) {
      consentInitiated
        ? this.onSignature(consentInitiated.transitionLink, consentInitiated.type)
        : this.pollForDoctorInitiatedConsent();
    }
  }

  stopPolling() {
    if (this.timeInterval) {
      this.timeInterval.unsubscribe();
    }
  }

  /* 

    APIS
  
  */

  onFPI_Read() {
    if (this.fpi) {
      const data = {
        studyId: this.fpi.studyId,
        siteId: this.fpi.siteId,
        fpiId: this.fpi.id,
      };

      this._fpiService.setReadFPI(data).subscribe((res) => {
        if (res) {
          this._consentFlowService.setFpiRead(true);
          if (this.consentFlowType === 'doctor-patient') {
            this.pollForDoctorInitiatedConsent();
          } else {
            this.signatureLoading = true;
            this.consentInitiated
              ? this.onSignature(
                  this.consentInitiated.transitionLink,
                  this.consentInitiated.type,
                  this.consentInitiated.status,
                )
              : this.onInitiateConsentFlow();
          }
        }
      });
    }
  }

  pollForDoctorInitiatedConsent() {
    if (!this.fpi || !this.patientPersonalNumber) {
      console.error('Missing required patient or FPI information.');
      return;
    }

    const patientConsentData = {
      studyId: this.fpi.studyId,
      siteId: this.fpi.siteId,
      fpiId: this.fpi.id,
      personalNumber: this.patientPersonalNumber,
    };

    const pollinterVal = 1000;
    const pollingDuration = 60000;

    const stopPolling$ = timer(pollingDuration);

    const fetchConsent = () =>
      this._consentService.getPatientConsent(patientConsentData).pipe(
        catchError((error) => {
          if (error.status === 404) {
            return of(null);
          } else {
            console.error('Error fetching consent status', error);
            return throwError(() => new Error('Non-recoverable error encountered'));
          }
        }),
        takeWhile((response) => !(response && response[0] && response[0].status === 'initiated'), true),
      );

    this.timeInterval = timer(0, pollinterVal)
      .pipe(
        switchMap(fetchConsent),
        takeUntil(stopPolling$),
        catchError((error) => {
          if (error instanceof TimeoutError) {
            return of('timeout');
          }
          return throwError(() => error);
        }),
      )
      .subscribe({
        next: (response) => {
          if (response === 'timeout') {
            this.showRestartButton = true;
            this.stopPolling();
          } else if (Array.isArray(response) && response[0]?.status === 'initiated') {
            this.onSignature(response[0].transitionLink, response[0].type);
            this.stopPolling();
          }
        },
        complete: () => {
          this.showRestartButton = true;
          this.stopPolling();
        },
        error: (error) => {
          console.error('Polling error:', error);
        },
      });
  }

  restartPolling() {
    this.showRestartButton = false;
    this.consentSessionCancelled = false;
    this.pollForDoctorInitiatedConsent();
  }

  onInitiateConsentFlow() {
    if (!this.fpi) {
      console.error('Missing required patient or FPI information.');
      return;
    }
    let consentData: IConsentFlowInitiate;
    if (this.consentFlowType !== 'family') {
      consentData = {
        fpiId: this.fpi?.id,
        type: this.consentFlowType,
        personalNumber: this.patientPersonalNumber,
      };
    } else {
      consentData = {
        fpiId: this.fpi?.id,
        type: this.dependentPersonalNumber ? EFlowType.Family : EFlowType.Patient,
        personalNumber: this.dependentPersonalNumber ? this.dependentPersonalNumber : this.patientPersonalNumber,
      };
    }

    this._consentFlowService.initiate(this.fpi.studyId, this.fpi.siteId, consentData).subscribe((response) => {
      if (response && response.id) {
        this.consentFlowData = response;
        this.onSignature(response.transitionLink, response.type, response.status);
      }
    });
  }

  onSignature(transitionLink: string, flowType: string, consentStatus?: string) {
    this.errorSigning = false;
    this.signatureLoading = true;

    const patientSignatureData = {
      signatureMethod: ESignatureMethod.BankId,
    };

    let signatureType = '';
    if (flowType === 'patient') {
      signatureType = 'complete';
    } else if (flowType.includes('family')) {
      signatureType = consentStatus === 'parent-signed' ? 'complete' : 'parent-signed';
    } else {
      signatureType = 'patient-signed';
    }

    this._consentFlowService.signature(transitionLink, patientSignatureData).subscribe({
      next: (response) => this.onCollectSignature(response.transitionLink, signatureType),
      error: () => {
        this.stopPolling();
        this.signatureLoading = false;
        this.errorSigning = true;
      },
    });
  }

  onCollectSignature(transitionLink: string, signatureType: string) {
    this.timeInterval = interval(2000)
      .pipe(
        startWith(0),
        switchMap(() =>
          this._consentFlowService.collectSignature(transitionLink).pipe(
            catchError((error) => {
              if (error.status === 404) {
                this.stopPolling();
                this.signatureLoading = false;
                this.showRestartButton = true;
                this.consentSessionCancelled = true;
                return EMPTY;
              }
              return throwError(() => error);
            }),
          ),
        ),
        retryWhen((errors) =>
          errors.pipe(
            delay(1000),
            take(60),
            concatMap(() => throwError(() => `There was no result after 60 attempts`)),
          ),
        ),
      )
      .subscribe({
        next: (response) => {
          if (response.body?.status !== signatureType && response.body?.idpData?.qrCode) {
            this.idpData = response.body?.idpData;
            if (this.idpData?.pollingMessage?.hintCode === 'userCancel') {
              this.stopPolling();
            }
            if (this.idpData?.pollingMessage.hintCode === 'startFailed') {
              this.reactToFpiAndConsentState(this.userReadFpi, this.consentInitiated);
            }

            this.svgQrCode = response.body?.idpData?.qrCode;
            this.autoStartToken = response.body?.idpData?.autoStartToken;
          }
          if (response.body?.status === signatureType) {
            this.signatureLoading = false;

            if (this.consentFlowType.includes('doctor')) {
              this._consentFlowService.setConsentTransitionLink(response.body?.transitionLink);
            }
            this.stopPolling();
            this.completeStep(response.body);
          }
        },
        error: () => {
          this.stopPolling();
          this.signatureLoading = false;
        },
      });
  }
}
