<div *ngIf="dataLoaded | async" class="space-y-8">
  <!-- Profile Header -->
  <div class="rounded-lg bg-white overflow-hidden shadow">
    <h2 class="sr-only">Profile Overview</h2>
    <div class="bg-white p-6">
      <div class="sm:flex sm:items-center sm:justify-between">
        <div class="sm:flex sm:space-x-5">
          <div class="flex-shrink-0 flex justify-center">
            <span
              class="inline-flex items-center justify-center h-20 w-20 rounded-full bg-sky-600"
            >
              <span
                *ngIf="userProfile"
                class="text-xl font-medium leading-none text-white"
              >
                {{ userProfile?.firstName[0] + userProfile?.lastName[0] }}
              </span>
            </span>
          </div>
          <div class="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left">
            <p class="text-xl font-bold text-gray-900 sm:text-2xl">
              {{ userProfile?.firstName + ' ' + userProfile?.lastName }}
            </p>
          </div>
        </div>
        <div class="mt-5 flex justify-center sm:mt-0">
          <app-button
            [label]="'Mina studier'"
            [routerLink]="'/'"
            [color]="true"
            [btnColor]="
              'shadow-none text-sky-600 hover:bg-sky-100 hover:text-sky-800'
            "
          ></app-button>
        </div>
      </div>
    </div>
  </div>
  <!-- Contact details -->
  <form
    #formContactDetails="ngForm"
    class="bg-white shadow overflow-hidden sm:rounded-lg"
  >
    <div class="px-4 py-5 sm:p-6">
      <div class="sm:flex sm:items-start sm:justify-between">
        <div class="shrink">
          <h3 class="text-lg font-medium leading-6 text-gray-900">
            Kontaktuppgifter
          </h3>
          <div class="mt-2 text-sm text-gray-500">
            <p>
              Ange dina kontaktuppgifter. Kontrollera gärna så att de är
              korrekta, innan du klickar på "Spara".
            </p>
          </div>
        </div>
        <div
          class="mt-4 flex items-center justify-end space-x-3 sm:mt-0 sm:ml-6 sm:flex-shrink-0"
        >
          <div
            [ngClass]="success.profile ? 'opacity-100' : 'opacity-0'"
            class="transition duration-300 ease-in-out stroke-[1.5px] h-6 w-6 text-green-500"
          >
            <app-icon [name]="'check-circle'"></app-icon>
          </div>
          <app-button
            *ngIf="!showInput"
            [label]="'Ändra'"
            (onClick)="toggleInput()"
            [color]="true"
            [btnColor]="
              'border border-sky-600 text-sky-800 hover:bg-sky-50 active:bg-sky-100'
            "
          ></app-button>
          <app-button
            *ngIf="showInput"
            [label]="'Spara'"
            (onClick)="updateContactInfo()"
            [color]="true"
            [disabled]="!formContactDetails.valid"
            [btnColor]="
              'bg-sky-600 text-white hover:bg-sky-500 active:opacity-75'
            "
          ></app-button>
          <app-button
            *ngIf="showInput"
            [label]="'Avbryt'"
            (onClick)="toggleInput()"
          ></app-button>
        </div>
      </div>
    </div>
    <div class="border-t border-gray-200">
      <dl>
        <div
          class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
        >
          <dt class="text-sm font-medium text-gray-500">E-post</dt>
          <dd class="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            <span *ngIf="!showInput" class="flex-grow">{{
              userProfile?.email
            }}</span>
            <div *ngIf="showInput" class="flex-grow">
              <input
                type="email"
                name="email"
                id="email"
                [(ngModel)]="userProfile!.email"
                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
                class="block w-full sm:w-4/5 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                required
                placeholder="you@example.com"
              />
            </div>
          </dd>
        </div>
        <div
          class="bg-white-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
        >
          <dt class="text-sm font-medium text-gray-500">
            Telefonnummer
            <span *ngIf="showInput" class="flex-grow"> (endast siffror) </span>
          </dt>
          <dd class="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            <span *ngIf="!showInput" class="flex-grow">
              {{ userProfile?.phone }}
            </span>
            <div *ngIf="showInput" class="flex-grow">
              <input
                type="text"
                name="phone"
                minlength="8"
                maxlength="13"
                required
                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                id="phone"
                [(ngModel)]="userProfile!.phone"
                class="block w-full sm:w-4/5 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                placeholder="T.ex. 07..."
              />
            </div>
          </dd>
        </div>
        <div
          *ngIf="this.userRoles.includes('doctor')"
          class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
        >
          <dt class="text-sm font-medium text-gray-500">HSAID</dt>
          <dd class="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            <span
              *ngIf="!showInput"
              [ngClass]="
                userProfile?.customAttributes['GrandId-Siths']
                  ? ''
                  : 'font-semibold'
              "
              class="flex-grow"
            >
              {{
                userProfile?.customAttributes['GrandId-Siths']
                  ? userProfile?.customAttributes['GrandId-Siths'][0]
                  : 'SAKNAS'
              }}
            </span>
            <div *ngIf="showInput" class="flex-grow">
              <input
                type="text"
                name="hsaid"
                maxlength="50"
                id="hsaid"
                [(ngModel)]="hsaid"
                class="block w-full sm:w-4/5 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                placeholder="T.ex. SE..."
              />
            </div>
          </dd>
        </div>
      </dl>
    </div>
  </form>

  <!-- Notification -->
  <form #form="ngForm" (ngSubmit)="updateUserAttributes(form)">
    <div class="shadow sm:rounded-md sm:overflow-hidden">
      <div class="bg-white py-6 px-4 space-y-6 sm:p-6">
        <div>
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Kontakt med andra studier
          </h3>
        </div>
        <fieldset class="mt-2">
          <div class="space-y-4">
            <div class="flex items-start">
              <div class="h-5 flex items-center">
                <input
                  [ngModel]="
                    userProfile?.customAttributes.notificationStudyPhone &&
                    userProfile?.customAttributes.notificationPortalEmail[0] ===
                      'true'
                      ? true
                      : false
                  "
                  id="notification-portal-email"
                  #notificationPortalEmail="ngModel"
                  name="notificationPortalEmail"
                  type="checkbox"
                  class="h-4 w-4 border-gray-300 rounded text-sky-600 focus:ring-sky-500"
                />
              </div>
              <div class="ml-3 text-sm">
                <label
                  for="notification-portal-email"
                  class="font-medium text-gray-700"
                  >Få inbjudningar från andra studier via e-post (valfritt)
                </label>
              </div>
            </div>
          </div>
        </fieldset>
      </div>
      <div class="flex items-center space-x-3 px-4 py-3 sm:px-6">
        <app-button
          [label]="'Spara'"
          [type]="'submit'"
          [color]="true"
          [btnColor]="
            'bg-sky-600 text-white hover:bg-sky-500 active:opacity-75'
          "
        ></app-button>
        <div
          [ngClass]="success.notifications ? 'opacity-100' : 'opacity-0'"
          class="transition duration-300 ease-in-out stroke-[1.5px] h-6 w-6 text-green-500"
        >
          <app-icon [name]="'check-circle'"></app-icon>
        </div>
      </div>
    </div>
  </form>

  <!-- Delete account -->
  <div class="bg-white shadow sm:max-w-3xl sm:rounded-lg">
    <div class="px-4 py-5 sm:p-6">
      <h3 class="text-lg leading-6 font-medium text-gray-900">
        Radera min personliga profil på minforskning.se
      </h3>
      <p class="mt-2 text-sm text-gray-500 leading-6 break-words">
        Här kan du radera din personliga profil pá minforskning.se. Efter raderingen kan du
        inte ta del av personlig information fran den studie som du är deltagare
        i. Att du raderar din personliga profil på minforskning.se innebär inte att du
        slutar vara med i studien. Dina kontaktuppgifter kommer att finnas
        lagrade i samtyckesdatabasen, så att studiepersonalen kan kontakta dig
        vid behov. Vill du göra ett återtagande av ditt samtycke till att delta
        i studien (alltså gå ur studien) så kontaktar du din vårdenhet för mer
        information. Kontaktuppgifterna finner du bland annat om du går till
        studien du har samtyckt för och klickar på fliken "Ditt deltagande" ->
        "Din vårdenhet". Du kan vid ett senare tillfälle skapa en ny personlig profil
        och då erhålla samma personliga information kopplat till studien som
        tidigare.
      </p>
      <div class="mt-5">
        <app-button
          [label]="'Radera personlig profil'"
          [disabled]="this.userRoles.includes('doctor')"
          [color]="true"
          [btnColor]="
            'bg-sky-600 text-white hover:bg-sky-500 active:opacity-75'
          "
          (onClick)="openDeleteAccountModal()"
        ></app-button>
        <p
          *ngIf="this.userRoles.includes('doctor')"
          class="mt-2 text-sm text-red-500"
        >
          Som studiepersonal kan du inte radera din personliga profil. Kontakta den
          ansvariga på din vårdenhet.
        </p>
      </div>
    </div>
  </div>
</div>
<app-modal
  *ngIf="deleteAccountModal"
  (close)="openDeleteAccountModal()"
  [largeDialog]="false"
>
  <div content class="mt-6">
    <div class="sm:flex sm:items-start">
      <div
        class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10"
      >
        <svg
          class="h-6 w-6 text-red-600"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M12 10.5v3.75m-9.303 3.376C1.83 19.126 2.914 21 4.645 21h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 4.88c-.866-1.501-3.032-1.501-3.898 0L2.697 17.626zM12 17.25h.007v.008H12v-.008z"
          />
        </svg>
      </div>
      <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
        <h3
          class="text-lg font-medium leading-6 text-gray-900"
          id="modal-title"
        >
          Radera personlig profil
        </h3>
        <div class="mt-2">
          <p class="text-sm text-gray-500">
            Är du säker på att du vill radera din personliga profil? Efter raderingen kan
            du inte ta del av personlig information från den studie som du är
            deltagare i. Denna åtgärd kan inte ångras.
          </p>
        </div>
      </div>
    </div>
    <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
      <button
        type="button"
        (click)="deleteAccount()"
        class="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
      >
        Radera personlig profil
      </button>
      <button
        type="button"
        (click)="openDeleteAccountModal()"
        class="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
      >
        Avbryt
      </button>
    </div>
  </div>
</app-modal>
