<div class="py-8">
  <dt class="mt-4 text-sm font-medium text-gray-500">
    Forskningspersonsinformation
  </dt>
  <dd class="mt-1 text-sm text-gray-900">
    <ul role="list" class="border border-gray-200 rounded-md">
      <li class="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
        <div class="w-0 flex-1 flex items-center">
          <svg
            class="flex-shrink-0 h-5 w-5 text-gray-400"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fill-rule="evenodd"
              d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z"
              clip-rule="evenodd"
            />
          </svg>
          <span class="ml-2 flex-1 w-0 truncate">
            {{ 'forskningspersonsinformation_' + studyName + '.pdf' }}
          </span>
        </div>
        <div class="ml-4 flex-shrink-0">
          <button
            (click)="viewFpiPdf()"
            class="font-medium text-sky-600 hover:text-sky-500 mr-2"
          >
            Öppna
          </button>
          <a
            [href]="fpiDownloadLink"
            class="font-medium text-sky-600 hover:text-sky-500"
          >
            Ladda ner
          </a>
        </div>
      </li>
    </ul>
  </dd>
  <!-- FPI pdf embedded on page -->
  <div *ngIf="loadingEmbedPdf" class="animate-pulse mt-6">
    <div class="h-[1000px] bg-slate-200 rounded"></div>
  </div>
  <div [ngClass]="loadingEmbedPdf ? 'invisible' : 'visible'" class="mt-6">
    <pdf-viewer
      [src]="fpiPDF"
      [rotation]="0"
      [original-size]="true"
      [show-all]="true"
      [fit-to-page]="true"
      [zoom]="1"
      [zoom-scale]="'page-width'"
      [stick-to-page]="false"
      [render-text]="true"
      [external-link-target]="'blank'"
      [autoresize]="true"
      [show-borders]="false"
      [style]="{
        width: '100%',
        height: viewerHeight,
        'background-color': '#f1f5f9'
      }"
    >
    </pdf-viewer>
  </div>
</div>
