<div class="relative overflow-hidden bg-white">
  <h1 class="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">
    Min forskning
  </h1>
  <p class="mt-8 text-xl leading-8 text-gray-500">
    Minforskning.se är utvecklad av Uppsala universitet. Minforskning.se är en
    applikation för att kunna samtycka elektroniskt för deltagande i
    forskningsstudier. Här finns också information om de forskningsstudier där
    den samtyckesprocessen ingår. För dig som planerar att genomföra en
    forskningsstudie och som önskar använda minforskning.se för
    samtyckesprocessen i den studien, kontakta
    <a href="mailto:info@minforskning.se" class="text-blue-500 hover:underline"
      >info@minforskning.se</a
    >.
  </p>
</div>
