<div class="relative flex min-h-full flex-col">
  <div class="flex">
    <!--  Desktop study sidebar navigation & main wrapper -->
    <div class="min-w-0 flex-1 bg-white lg:flex">
      <div class="w-80 bg-white">
        <div class="hidden lg:flex">
          <div class="flex-1 space-y-8">
            <nav>
              <ol class="overflow-hidden rounded-md border border-gray-200">
                <div *ngFor="let item of studyNavigation">
                  <li class="relative overflow-hidden">
                    <a [routerLink]="item.url" (click)="onSelectRoute(item)" class="group">
                      <span
                        [routerLinkActive]="['bg-sky-600']"
                        [routerLinkActiveOptions]="{ exact: currentRouteUrl?.url === './' ? false : true }"
                        class="absolute top-0 left-0 h-full w-1 group-hover:bg-gray-200"
                        aria-hidden="true"
                      ></span>
                      <span class="flex items-start px-2 py-5 pl-6 text-sm font-medium">
                        <span class="mt-0.5 flex min-w-0 flex-col">
                          <span
                            [routerLinkActive]="['text-sky-600']"
                            [routerLinkActiveOptions]="{ exact: currentRouteUrl?.url === './' ? false : true }"
                            class="text-base font-semibold tracking-wide text-gray-600"
                          >
                            {{ item.title }}
                          </span>
                          <span
                            [routerLinkActive]="['text-sky-600']"
                            [routerLinkActiveOptions]="{ exact: currentRouteUrl?.url === './' ? false : true }"
                            class="text-sm font-normal text-gray-500"
                          >
                            {{ item.description }}
                          </span>
                        </span>
                      </span>
                    </a>
                  </li>
                </div>
              </ol>
            </nav>
          </div>
        </div>
      </div>

      <!-- Study header -->
      <div class="bg-white lg:min-w-0 lg:flex-1">
        <div class="pb-6 sm:pl-6 lg:pl-8 xl:pl-6">
          <div class="flex items-center">
            <div class="mr-4 hidden flex-shrink-0 sm:block">
              <img class="h-20 w-20 object-contain" [src]="study.logo" alt="Studie logga" />
            </div>
            <h1 class="flex-1 text-lg font-medium sm:text-2xl">
              {{ study.title }}
            </h1>
          </div>

          <!--  Mobile study navigation -->
          <div class="flex items-center pt-4 lg:hidden">
            <div class="mr-2 block flex-none sm:hidden">
              <img class="h-10 w-auto object-contain" [src]="study.logo" alt="Study Logo" />
            </div>
            <div class="relative w-full">
              <button
                (click)="onMobileNavigation()"
                type="button"
                class="w-full rounded-md border-0 py-1.5 px-2.5 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-sky-600 sm:text-sm sm:leading-6"
              >
                <span class="flex items-center justify-between">
                  <span class="flex flex-col text-left text-sm font-medium">
                    <span class="text-base font-medium tracking-wide text-sky-600">
                      {{ currentRouteUrl?.title }}
                    </span>
                  </span>
                  <app-icon [name]="'chevron-up-down'" class="h-6 w-6 stroke-[1.5px] text-sky-600"></app-icon>
                </span>
              </button>
              <ul
                *ngIf="mobileNavigation"
                class="absolute right-0 z-10 mt-2 w-full origin-top-right divide-y divide-gray-200 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
              >
                <li
                  *ngFor="let nav of studyNavigation"
                  class="relative cursor-default select-none p-4 text-sm text-gray-900"
                >
                  <a [routerLink]="nav.url" (click)="onSelectRoute(nav)" class="flex flex-col">
                    <span class="flex justify-between">
                      <span
                        [routerLinkActive]="['bg-sky-600']"
                        [routerLinkActiveOptions]="{ exact: currentRouteUrl?.url === './' ? false : true }"
                        class="absolute top-0 left-0 h-full w-1"
                      ></span>
                      <p
                        [routerLinkActive]="['text-sky-600', 'font-semibold']"
                        [routerLinkActiveOptions]="{ exact: currentRouteUrl?.url === './' ? false : true }"
                      >
                        {{ nav.title }}
                      </p>
                    </span>
                  </a>
                </li>
              </ul>
            </div>
            <div></div>
          </div>
        </div>
        <!--  Main content -->
        <div class="min-h-full">
          <div class="relative bg-white">
            <div class="relative px-0 sm:px-6 lg:px-8">
              <router-outlet></router-outlet>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
