import { Component } from '@angular/core';
import { LocalStorageService } from './core/services/shared/local-storage.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'ffm';
  cookiesModal: boolean;

  constructor(private _localStorageService: LocalStorageService) {
    let cookies: any =
      this._localStorageService.getData<string>('accepted-cookies') || 'none';

    if (cookies === 'none') {
      this.cookiesModal = true;
    } else {
      let today = new Date();
      let validTo = new Date(cookies.validTo);
      let notSame = today.getTime() <= validTo.getTime();

      switch (notSame) {
        case true:
          this.cookiesModal = cookies.accepted ? false : true;
          break;
        case false:
          this.cookiesModal = true;
          break;
        default:
          this.cookiesModal = true;
          break;
      }
    }
  }

  onAcceptCookies() {
    const validFromDate = new Date();
    const toDate = new Date();
    const validToDate = new Date(
      new Date(toDate).setDate(toDate.getDate() + 30)
    );
    let cookiesData = {
      accepted: true,
      validFrom: validFromDate,
      validTo: validToDate,
    };
    this._localStorageService.setData('accepted-cookies', cookiesData);
    this.cookiesModal = false;
  }
}
