import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'siteFilter',
})
export class SiteFilterPipe implements PipeTransform {
  transform(array: any, term: string): any[] {
    if (!array || !term) return array;

    return array.filter(
      (data: any) =>
        data.name.toLowerCase().includes(term.toLowerCase()) ||
        data.city.toLowerCase().includes(term.toLowerCase())
    );
  }
}
