import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class HeadersService {
  constructor() {}
  createSiteIdHeaders(siteId: string) {
    return new HttpHeaders({ 'site-id': siteId });
  }
}
