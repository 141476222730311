<div class="text-center sm:col-span-2">
  <ng-container *ngIf="qrCode; else loadingTemplate">
    <div
      class="mx-auto mb-4 flex h-[200px] w-[200px] items-center justify-center rounded-xl border-[6px] border-sky-600"
      [innerHTML]="qrCode"
    ></div>
    <a class="font-medium text-sky-600 hover:underline" [href]="generateBankIDUrl()">Starta BankID på denna enhet</a>
  </ng-container>
 
  <ng-template #loadingTemplate>
    <div class="mx-auto h-[200px] w-[200px] animate-pulse rounded-xl border-[6px] border-sky-600 p-3"></div>
  </ng-template>
</div>
