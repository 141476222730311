<ng-container *ngIf="dataLoaded | async; then consentFlow"></ng-container>

<ng-template #consentFlow>
  <!--ng-container
    *ngIf="
      studySettings?.openFpi || (!studySettings?.openFpi && isPatientLoggedIn);
      
    "
  -->
  <div *ngIf="eSignatureStep === 1">
    <div class="mt-2 block">
      <p class="text-lg font-semibold text-gray-900">Läs igenom forskningspersonsinformationen</p>
      <p class="mt-3 text-sm text-gray-500">
        Det här pdf-dokumentet innehåller den formella forskningspersonsinformation som utgör grunden för samtycke till
        att delta i studien. Läs hela dokumentet noga.
      </p>
    </div>

    <div *ngIf="studySites.length > 0" class="mt-8 max-w-xl mx-auto">
      <div class="text-center pb-4">
        <h2 class="mt-2 text-base font-medium text-gray-900">
          Välj din vårdenhet för att läsa forskningspersonsinformationen
        </h2>
      </div>
      <form class="flex">
        <select
          id="site"
          [ngModel]="siteId"
          (ngModelChange)="onChangeSite($event)"
          name="site"
          class="mt-1 block w-full rounded-md border border-gray-300 py-2 pl-3 pr-10 text-lg focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
        >
          <option value="" disabled selected>Välj vårdenhet</option>
          <option *ngFor="let site of studySites" [value]="site.id">
            {{ site.name }}
          </option>
        </select>
      </form>
      <div class="flex justify-center py-2">
        <button
          type="button"
          class="w-full py-2.5 px-5 me-2 mb-2 text-sm font-medium text-blue-500 focus:outline-none bg-white rounded-full border border-blue-500 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
        >
          Kontakt
        </button>
      </div>
    </div>
    <div *ngIf="fpis.length">
      <app-fpi-viewer
        [studyName]="study.name"
        [fpiDownloadLink]="fpiDownloadLink"
        [loadingEmbedPdf]="loadingEmbedPdf"
        [fpiPDF]="fpiPDF"
      ></app-fpi-viewer>
      <!--div
          *ngIf="!loadingEmbedPdf && isPatientLoggedIn; else showLoginMessage"
          class="max-w-xl mx-auto"
        >
          <fieldset>
            <div class="relative flex items-start">
              <div class="flex">
                <input
                  type="checkbox"
                  id="read-document"
                  aria-describedby="read-document-description"
                  name="read-document"
                  [checked]="readFpi"
                  (change)="readFpi = !readFpi"
                  class="mt-1 h-6 w-6 rounded border-gray-300 text-sky-600 focus:ring-sky-500"
                />
                <div class="ml-3 text-base">
                  <label for="read-document" class="font-medium text-gray-900"
                    >Jag har läst forskningspersonsinformationen och samtycker till att gå med i studien på det sättet
                    som det anges i forskningspersonsinformationen.
                  </label>
                </div>
              </div>
            </div>
          </fieldset>
          <div *ngIf="readFpi">
          <div>
            <pre>
              {{fpi|json}}
            </pre>
            <form class="mt-6">
              <div class="overflow-hidden shadow-lg sm:rounded-md">
                <div class="space-y-6 bg-white px-4 py-5 sm:p-6">
                  <fieldset>
                    <div>
                      <legend class="text-base font-medium text-gray-900">Kontaktuppgifter</legend>
                      <p class="mt-1 text-sm text-gray-500">
                        Dessa kontaktuppgifter kommer att delas med studien. Stämmer inte uppgifterna? Gå tillbaka till
                        din profil och uppdatera informationen. Därefter behöver du klicka på "Gå med" igen.
                      </p>
                    </div>
                    <div class="mt-4 space-y-4">
                      <div *ngIf="userProfile" class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <label class="block text-sm font-medium text-gray-700"> E-post </label>
                        <label *ngIf="userProfile.email" class="ml-3 block text-sm text-gray-500">
                          {{ userProfile.email }}
                        </label>
                      </div>
                    </div>
                    <div class="mt-4 space-y-4">
                      <div *ngIf="userProfile" class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <label class="block text-sm font-medium text-gray-700"> Telefonnummer </label>
                        <label class="ml-3 block text-sm text-gray-700">
                          {{ userProfile.phone }}
                        </label>
                      </div>
                    </div>
                  </fieldset>
                </div>
              </div>
            </form>
            <form class="mt-6">
              <div class="overflow-hidden shadow-lg sm:rounded-md">
                <div class="space-y-6 bg-white px-4 py-5 sm:p-6">
                  <fieldset>
                    <div>
                      <legend class="text-base font-medium text-gray-900">Signera ditt samtycke med e-signatur</legend>
                      <p class="mt-1 text-sm text-gray-500">
                        Genom att signera elektroniskt så samtycker du till att gå med i studien.
                      </p>
                    </div>
                  </fieldset-->

      <app-alerts
        *ngIf="errorMessage.age != ''"
        [margin]="'mt-2 mb-2'"
        [type]="'danger'"
        [message]="errorMessage.age"
        (onClick)="errorMessage.age = ''"
      ></app-alerts>

      <!--app-alerts
                    *ngIf="
                      signatureLoading.bankid ||
                      signatureLoading.frejaid ||
                      signatureLoading.siths
                    "
                    [type]="'info'"
                    [margin]="'my-2'"
                    [message]="'Öppna ' + chosenIdentityProvider + ' för att signera'"
                    [closeable]="false"
                    [largeText]="false"
                  ></app-alerts-->

      <app-alerts
        *ngIf="errorMessage.signatureExists != ''"
        [type]="'danger'"
        [largeText]="false"
        [margin]="'my-2'"
        [message]="errorMessage.signatureExists"
        (onClick)="errorMessage.signatureExists = ''"
      ></app-alerts>

      <app-alerts
        *ngIf="errorSigning"
        [type]="'danger'"
        [message]="'Något gick fel. Försök igen eller testa senare.'"
        [closeable]="false"
        [largeText]="false"
        class="col-span-2"
      ></app-alerts>

      <!--button
                    (click)="onInitConsentFlow('bankid')"
                    type="button"
                    class="group relative flex w-full justify-center rounded-md border border-sky-600 bg-white py-3 px-4 text-base font-medium text-sky-600 hover:bg-sky-50 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2"
                  >
                    <span class="absolute inset-y-0 left-2 flex items-center pl-3">
                      <img class="h-7 w-7" src="/assets/images/logos/bank-id.svg" alt="BankID" />
                    </span>
                    <svg
                      *ngIf="signatureLoading.bankid"
                      class="-ml-1 mr-3 h-5 w-5 animate-spin text-sky-500"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                      <path
                        class="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                    BankID
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div-->

      <!--div *ngIf="!isPatientLoggedIn" class="border rounded-md">
          <div class="p-3">
            <p class="font-bold">Logga in på minforskning.se</p>
          <div class="py-2">
            Du behöver logga in för att fortsätta. Loggar du in för första gången på minforskning.se får du skapa en personlig profil och ange dina kontaktuppgifter.
          </div>
          <div class="py-2 flex justify-center">
            <button type="button" (click)="login()" class="w-1/3 text-white bg-blue-500 hover:bg-blue-400 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Logga in på minforskning.se</button>
          </div>
          </div>
        </div-->
    </div>
  </div>
  <!--/ng-container-->
</ng-template>

<div>
  <app-consent-stepper [userProfile]="userProfile" type="patient" [fpi]="fpi"></app-consent-stepper>
</div>
