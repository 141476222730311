<div class="flex min-h-full flex-col bg-white pt-16 pb-12">
  <main
    class="mx-auto flex w-full max-w-7xl flex-grow flex-col justify-center px-4 sm:px-6 lg:px-8"
  >
    <div class="flex flex-shrink-0 justify-center">
      <a [routerLink]="'/'" class="inline-flex">
        <span class="text-lg font-medium text-gray-900">Min forskning</span>
      </a>
    </div>
    <div class="py-16">
      <div class="text-center">
        <p class="text-base font-semibold text-sky-500">401</p>
        <h1
          class="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl"
        >
          Not authorized.
        </h1>
        <p class="mt-2 text-base text-gray-500">
          Du har inte tillåtelse att komma åt denna sida.
        </p>
        <div class="mt-6">
          <a
            [routerLink]="'/'"
            class="text-base font-medium text-sky-500 hover:text-sky-600"
          >
            Gå till startsidan
            <span aria-hidden="true"> &rarr;</span>
          </a>
        </div>
      </div>
    </div>
  </main>
</div>
