<div class="bg-white shadow overflow-hidden sm:rounded-lg">
  <div class="px-4 py-5 sm:px-6">
    <h3 class="text-lg leading-6 font-medium text-gray-900">
      Resultat och meddelanden
    </h3>
    <p class="mt-1 max-w-2xl text-sm text-gray-500">
      Här visas resultat (t.ex. provsvar) och meddelanden från studien. Om du
      har frågor om ditt resultat, kontakta din vårdenhet.
    </p>
  </div>
  <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
    <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
      <div class="sm:col-span-2">
        <dd class="mt-1 text-sm text-gray-900">
          <ul
            role="list"
            class="px-2 border border-gray-200 rounded-md divide-y divide-gray-200"
          >
            <li
              *ngFor="let data of results"
              class="group bg-white pl-3 pr-4 py-3 flex items-center justify-between text-sm"
            >
              <div
                class="relative focus-within:ring-2 focus-within:ring-indigo-500"
              >
                <h3 class="text-base font-semibold text-sky-800">
                  {{
                    data.analysisDate != null
                      ? 'Resultat'
                      : 'Meddelande ' + (data.createdAt | date : 'YYYY-MM-dd')
                  }}
                </h3>
                <ng-container
                  *ngIf="data.analysisDate != null; else onlyComment"
                >
                  <p class="mt-1 text-sm text-gray-600">
                    Vi har den
                    <span class="group-hover:font-medium">
                      {{ data.analysisDate | date : 'd' }}
                      {{ data.analysisDate | date : 'MMMM' | lowercase }}
                      {{ data.analysisDate | date : 'YYYY' }}
                    </span>
                    analyserat ditt prov och ditt
                    <span class="group-hover:font-medium">
                      {{ data.assay }}
                    </span>
                    värde är
                    <span class="group-hover:font-medium">
                      {{ data.result | number }} {{ data.unit }}.
                      {{ data.comments }}
                    </span>
                  </p>
                </ng-container>
                <ng-template #onlyComment>
                  <p class="mt-1 text-sm text-gray-600 group-hover:font-medium">
                    {{ data.comments }}
                  </p>
                </ng-template>
              </div>
            </li>
          </ul>
        </dd>
      </div>
    </dl>
  </div>
</div>
