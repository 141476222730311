import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
})
export class AlertsComponent implements OnInit {
  @Input() type!: string;
  @Input() message!: string;
  @Input() margin: string = 'm-0';
  @Input() closeable?: boolean = true;
  @Input() largeText?: boolean = true;
  @Input() showLink?: boolean = false;
  @Input() link!: string;
  @Input() linkText!: string;
  @Output() onClick = new EventEmitter();

  ngOnInit(): void {}
}
