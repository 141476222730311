export enum EStudyAvailability {
  OPEN = 'OPEN',
  INVITATION = 'INVITATION',
  CLOSED = 'CLOSED',
}

export enum EParticipantNumber {
  NONE = 'NONE',
  AUTOMATIC = 'AUTOMATIC',
  MANUAL = 'MANUAL',
}

export enum EConsentFlowMethod {
  InterestForm = 'interest-form',
  Contact = 'contact',
  ESignature = 'e-signature',
}

export interface ISettings {
  id: string;
  studyId: string;
  openFpi: boolean;
  withdrawConsent: boolean;
  fromAge: number;
  toAge: number;
  olderThan: boolean;
  youngerThan: boolean;
  studyAvailability: EStudyAvailability;
  resultReportable: boolean;
  eIdentification: boolean;
  participantNumber: EParticipantNumber;
  multipleConsentOnSameVersion: boolean;
  allowFamilyConsent: boolean;
  investigatorContactSettingid: string;
  coordinatorContactSettingId: string;
  localContactSettingId: string;
  showInvestigatorName: boolean;
  showInvestigatorEmail: boolean;
  showInvestigatorPhone: boolean;
  show_coordinatorName: boolean;
  show_coordinatorEmail: boolean;
  show_coordinatorPhone: boolean;
  showLocalContactName: boolean;
  showLocalContactEmail: boolean;
  showLocalContactPhone: boolean;
  consentFlowMethod: EConsentFlowMethod;
  showContactOption: boolean;
  showInterestOption: boolean;
  createdAt: string;
  createdBy: string | null;
  symptomUrl: string | null;
  childAgeLimit: number;
  textForChildConsent: string;
}
