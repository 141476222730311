import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { KeycloakService } from 'keycloak-angular';
import { UserService } from 'src/app/core/services/portal/user.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  public isPatientLoggedIn = false;
  dataLoaded!: Promise<boolean>;
  userProfile: any;
  public userRoles: any;
  hsaid!: string | null;
  showInput: boolean = false;
  success = {
    profile: false,
    notifications: false,
  };
  deleteAccountModal: boolean = false;  

  constructor(
    private readonly keycloakService: KeycloakService,
    private _userService: UserService
  ) {}

  public async ngOnInit() {
    this.isPatientLoggedIn = await this.keycloakService.isLoggedIn();

    if (this.isPatientLoggedIn) {
      this.getUserProfileInfo();      
    }
  }
  /* 
    ---- 
    Functions
    ---- 
  */
  toggleInput() {
    this.showInput = !this.showInput;
    if (this.userRoles.includes('doctor')) {
      this.hsaid = this.userProfile.customAttributes['GrandId-Siths']
        ? this.userProfile.customAttributes['GrandId-Siths'][0]
        : null;
    }
  }
  openDeleteAccountModal() {
    if (this.userRoles.includes('doctor')) {
      return;
    }
    this.deleteAccountModal = !this.deleteAccountModal;
  }
  /* 
    ---- 
    APIs
    ---- 
  */
  getUserProfileInfo() {
    this._userService
      .getUserInfo(this.keycloakService.getUsername())
      .subscribe((response: any) => {
        this.userProfile = response.data;
        this.userRoles = this.keycloakService.getUserRoles();
        this.dataLoaded = Promise.resolve(true);
      });
  }
  updateContactInfo() {
    var userAttributes: any = {
      email: [this.userProfile.email],
      phone: [this.userProfile.phone],
    };
    if (this.userRoles.includes('doctor')) {
      userAttributes['GrandId-Siths'] = [this.hsaid];
    }
    this._userService
      .addAttributes(userAttributes)
      .subscribe((response: any) => {
        this.getUserProfileInfo();
        this.success.profile = true;
        setTimeout(() => {
          this.success.profile = false;
        }, 5000);
        this.showInput = false;
      });
  }
  updateUserAttributes(form: NgForm) {
    let userAttributes = {
      notificationPortalEmail: [form.value.notificationPortalEmail ?? false],
    };
    this._userService
      .addAttributes(userAttributes)
      .subscribe((response: any) => {
        this.success.notifications = true;
        setTimeout(() => {
          this.success.notifications = false;
        }, 5000);
      });
  }
  // DANGER: Delete account
  deleteAccount() {
    if (this.userRoles.includes('doctor')) {
      return;
    }
    this._userService
      .deleteAccount(this.userProfile.username)
      .subscribe((response: any) => {
        this.keycloakService.logout(window.location.origin);
      });
  }
}
