export enum EConsentStatus {
  Initiated = 'initiated',
  PatientSigned = 'patient-signed',
  ParentSigned = 'parent-signed',
  Complete = 'complete',
}

export enum EFlowType {
  Patient = 'patient',
  Family = 'family',
  DoctorPatient = 'doctor-patient',
  DoctorFamily = 'doctor-family',
}

export enum ESignatureType {
  Patient = 'patient',
  Doctor = 'doctor',
  Parent = 'parent',
}

export enum ESignatureMethod {
  BankId = 'bankid',
  Siths = 'siths',
}

interface IStudySiteFpi {
  studyId: string;
  siteId: string;
  fpiId: string;
}

export interface IConsent extends IStudySiteFpi {
  id: string;
  status: EConsentStatus;
  type: EFlowType;
  manual: boolean;
  participantNumber: string;
  patientPersonalNumber: string;
  patientName: string;
  fpiData: string;
  fpiVersion: string;
  nonVisibleData: string;
  localContactName: string;
  localContactEmail: string;
  localContactPhone: string;
  signatures: ISignature[];
  createdAt: string;
  updatedAt: string;
  revoked: boolean;
  revokedBy: string;
  revokedAt: string;
  monitorId: string;
  transitionLink: string;
}

export interface ISignature {
  signature?: string;
  type: ESignatureType;
  createdAt?: string;
  personalNumber: string;
  name: string;
  email: string;
  phone: string;
  method?: ESignatureMethod;
}

export interface IConsentsRequest extends IStudySiteFpi {
  personalNumber: string;
}

export interface IConsentFlowsRequest extends IStudySiteFpi {
  id: string;
  type: EFlowType;
  status: EConsentStatus;
  transitionLink: string;
  personalNumber?: string;
  idpData?: IdpData;
}

export interface IdpData {
  qrCode: string;
  autoStartToken: string;
  pollingMessage: IPollingMessage;
}

export interface IPollingMessage {
  status: string;
  hintCode: string;
}

export interface IConsentFlowInitiate {
  fpiId: string;
  type: EFlowType;
  personalNumber: string;
}

export interface IConsentFlowSignature {
  signatureMethod: ESignatureMethod;
  dependentPersonalNumber?: string;
}

export interface IPatientConsentFlowsRequest {
  consentFlowId: string;
  studyId: string;
  siteId: string;
  email: string;
  phone: string;
  personalNumber: string;
  signatureMethod: ESignatureMethod;
}

export interface IDoctorConsentFlowsRequest {
  consentFlowId?: string;
  studyId?: string;
  siteId?: string;
  signatureMethod: ESignatureMethod;
}

export interface IManualConsentFormRequest {
  fpiId: string;
  type: EFlowType;
  manual: true;
  patientPersonalNumber: string;
  patientName: string;
  fpiData: string;
  fpiVersion: string;
  localContactName: string;
  localContactEmail: string;
  localContactPhone: string;
  signatures: ISignature[];
}

export interface IMonitorConsent {
  id: string;
  studyId: string;
  siteId: string;
  consentIds: string[];
  monitorName: string;
  signature: string;
  signatureMethod: ESignatureMethod;
  createdAt: string;
}

export interface IConsentFlowsMonitor {
  id: string;
  studyId: string;
  siteId: string;
  transitionLink: string;
}

interface ISearch extends IStudySiteFpi {
  patientPersonalNumber: string;
  signerPersonalNumber: string;
  participantNumber: string;
}

interface IFilter {
  revoked: boolean;
  manual: boolean;
  status: EConsentStatus;
  createdAt: string;
}

export interface IConsentSearch {
  search: ISearch;
  filter: IFilter;
}
