export const environment = {
  production: false,
  baseUrl: '/',
  apiUrl: '/api',
  apiUrlCommon: '/api/common',
  apiUrlPortal: '/api/portal',
  apiUrlEic: '/api/eic',
  envName: 'production',
  kc: '/kc',
};
