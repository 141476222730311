import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { map } from 'rxjs/operators';
import { AppConfig } from '../../models/AppConfig';

const API_URL = environment.apiUrl;

@Injectable({ providedIn: 'root' })
export class AppConfigService {
  private appConfig: AppConfig = {} as AppConfig;
  private initialized = false;

  private httpClient = inject(HttpClient);

  get isLoaded(): boolean {
    return this.initialized;
  }

  load(): Promise<AppConfig | undefined> {
    return this.httpClient
      .get<AppConfig>(`${API_URL}/common/frontendconfig`)
      .pipe(
        map(appConfig => {
          this.appConfig = appConfig;
          this.initialized = true;
          return this.appConfig;
        }),
      )
      .toPromise()
      .catch(() => Promise.resolve(undefined));
  }
  getEnvironment = () => this.appConfig.environment;
}
