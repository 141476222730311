<div *ngIf="!userReadFpi" class="flex justify-center">
  <button
    type="button"
    class="rounded-md bg-sky-600 px-4 py-3 text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600 sm:text-base"
    (click)="onFPI_Read()"
  >
    Jag har läst forskningspersonsinformationen
  </button>
</div>

<ng-container *ngIf="consentFlowType?.includes('doctor') && userReadFpi && !signatureLoading">
  <app-alerts
    type="info"
    [closeable]="false"
    message="Tack, du har nu bekräftat att du har tagit del av forskningspersonsinformationen. Vänligen vänta på läkarens instruktioner för nästa steg."
  ></app-alerts>
</ng-container>

<div *ngIf="showRestartButton" class="mt-4">
  <p class="text-sm text-gray-900">
    {{
      consentSessionCancelled
        ? 'Signeringssessionen avbröts. Vänligen starta om processen.'
        : 'Signeringsprocessen tog för lång tid att starta. Försök igen.'
    }}
  </p>
  <div class="mt-6 flex justify-center">
    <button
      type="button"
      (click)="restartPolling()"
      class="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-sky-600 shadow-sm ring-1 ring-inset ring-sky-300 hover:bg-sky-50"
    >
      {{ consentSessionCancelled ? 'Starta om signering' : 'Försök igen' }}
    </button>
  </div>
</div>

<ng-container *ngIf="signatureLoading">
  <p *ngIf="dependent" class="test-sm mb-4 font-medium text-gray-900">
    Forskningsperson: <span class="text-sky-600">{{ dependent.firstName }} {{ dependent.lastName }}</span>
  </p>
  <p
    *ngIf="idpData?.pollingMessage?.hintCode !== 'userCancel' && idpData?.pollingMessage?.hintCode !== 'startFailed'"
    class="text-sm text-gray-900"
  >
    Samtyck till studien genom att signera med BankID. Starta BankID-appen på din mobil eller surfplatta eller skanna
    QR-koden.
  </p>

  <div class="mt-4">
    <div
      *ngIf="idpData?.pollingMessage?.hintCode === 'userCancel' || idpData?.pollingMessage?.hintCode === 'startFailed'"
    >
      <p *ngIf="idpData?.pollingMessage?.hintCode === 'userCancel'" class="mt-3 text-sm text-gray-900">
        Signeringsprocessen avbröts.
      </p>
      <div class="mt-6 flex justify-center">
        <button
          type="button"
          (click)="reactToFpiAndConsentState(userReadFpi, consentInitiated)"
          class="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-sky-600 shadow-sm ring-1 ring-inset ring-sky-300 hover:bg-sky-50"
        >
          <p *ngIf="idpData?.pollingMessage?.hintCode === 'userCancel'">Klicka här för att starta om</p>
          <p *ngIf="idpData?.pollingMessage?.hintCode === 'startFailed'">Klicka här för att försöka igen</p>
        </button>
      </div>
    </div>
    <app-qr-code
      *ngIf="idpData?.pollingMessage?.hintCode !== 'userCancel'"
      [qrCodeBase64]="svgQrCode"
      autoStartToken="{{ autoStartToken }}"
    ></app-qr-code>
  </div>
</ng-container>
