import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConsentFlowService } from 'src/app/core/services/portal/consent-flow.service';
import { StudyService } from 'src/app/core/services/portal/study.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-study-fpi',
  templateUrl: './study-fpi.component.html',
})
export class StudyFpiComponent {
  private apiUrl: string = environment.apiUrlCommon;
  private _study: any;
  private _settings: any;

  @Input()
  set study(value: any) {
    this._study = value;
    if (value) {
      this.getStudySites();
    }
  }
  get study(): any {
    return this._study;
  }

  @Input()
  set settings(value: any) {
    this._settings = value;
  }
  get settings(): any {
    return this._settings;
  }

  @Output() fpiSelected = new EventEmitter<any>();

  siteId: string = '';
  studySites: any = [];
  fpi: any = {};
  fpiPDF: string = '';
  fpiDownloadLink: string = '';
  loadingEmbedPdf: boolean = false;
  errorMessage: boolean = false;
  showModal: boolean = false;
  selectedSite: any = null;
  siteParamId: string = '';

  constructor(
    private _studyService: StudyService,
    private _consentFlowService: ConsentFlowService,
    private _router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
    this.activatedRoute.queryParams.subscribe((qParams) => {
      this.siteParamId = qParams['site'];
    });
  }

  openContactModal() {
    this.showModal = true;
  }

  closeModal() {
    this.showModal = false;
  }

  generateFpiDownloadLink() {
    this.fpiDownloadLink = `${this.apiUrl}/studies/${this.study.id}/sites/${this.siteId}/fpis/${this.fpi.id}/pdf`;
  }

  /* API */
  getStudySites() {
    this._studyService.getStudySites(this.study.id).subscribe({
      next: (response: any) => {
        this.studySites = response.filter((site: any) => site.active);
        if (this.siteParamId) {
          this.selectedSite = this.studySites.find((site: any) => site.id === this.siteParamId);
          this.changeSite(this.selectedSite);
        } else if (this.studySites.length === 1) {
          this.changeSite(this.studySites[0]);
        }
      },
      error: () => {
        console.error('Error fetching study sites');
      },
    });
  }

  changeSite(site: any) {
    this.selectedSite = site;
    this._consentFlowService.setCurrentSiteSelection(site);
    this.siteId = site.id;
    this.errorMessage = false;

    this._studyService.getLatestFpi(this.study.id, site.id).subscribe({
      next: (response: any) => {
        if (response && response.length > 0) {
          this.fpi = response[0];
          this.embedFpiPdf(this.fpi.id);
          this.generateFpiDownloadLink();
          this.fpiSelected.emit(this.fpi);
        } else {
          this.errorMessage = true;
        }
      },
      error: () => {
        this.errorMessage = true;
      },
    });
    this._router.navigate([], {
      queryParams: { site: this.siteId },
      queryParamsHandling: 'merge',
    });
  }

  embedFpiPdf(fpiId: string) {
    this.loadingEmbedPdf = true;
    this._studyService.getFpiPDF(this.study.id, this.siteId, fpiId).subscribe({
      next: (result: Blob) => {
        const blob = new Blob([result], { type: 'application/pdf' });
        this.fpiPDF = window.URL.createObjectURL(blob);
      },
      error: (err) => {
        console.error('Failed to load FPI PDF', err);
        this.loadingEmbedPdf = false;
      },
      complete: () => {
        setTimeout(() => (this.loadingEmbedPdf = false), 1800);
      },
    });
  }
}
