import { KeycloakService } from "keycloak-angular";
import { environment } from 'src/environments/environment';
export function initializeKeycloak(keycloak: KeycloakService) : () => Promise<boolean> {
    var apiUrl: string = environment.kc;
    return () =>
      keycloak.init({
        config: {
          url: apiUrl,
          realm: 'ffm',
          clientId: 'ffm-frontend'
        },
        initOptions: {
            checkLoginIframe: true,
            checkLoginIframeInterval: 25,
            onLoad: 'check-sso',
            silentCheckSsoRedirectUri:
              window.location.origin + '/assets/silent-check-sso.html'
        },
        loadUserProfileAtStartUp: true
      });
  }
