import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
})
export class FaqComponent implements OnInit {
  faqs: any[] = [
    {
      question: 'Vad är är minforskning.se?',
      answer:
        'minforskning.se är ett system som ger möjlighet att elektroniskt läsa om och samtycka till deltagande i forskningsstudier. Systemet är en samlingsplats för forskningsstudier som leds av olika universitet och andra forskningshuvudmän, och är utvecklat vid Uppsala universitet. I systemet finns information om de forskningsstudier där denna samtyckesprocess ingår. För dig som planerar att genomföra en forskningsstudie och som önskar använda minforskning.se för att hantera samtycken, kontakta kontakt@minforskning.se',
    },
    {
      question: 'Hur går jag med i en studie?',
      answer:
        'Du behöver vara inloggad på minforskning.se för att kunna gå med i en studie; klicka på ”Logga in”. Om det är första gången du loggar in så får du skapa en personlig profil. När du loggat in kan du läsa om den studie som du blivit inbjuden till eller är intresserad av. Hur man går med i en studie går sen till på lite olika sätt för olika studier. Vissa studier kan du samtycka till (det vill säga gå med i studien) utan kontakt med forskarna. Andra studier behöver du anmäla intresse för till forskarna.',
    },
    {
      question: 'Hur skapar jag en personlig profil på minforskning.se?',
      answer:
        'Välj ”Logga in” så uppmanas du att skapa en personlig profil. Du behöver ange kontaktuppgifter såsom e-postadress och telefonnummer, och kan välja hur du vill bli kontaktad av minforskning.se. När du fyllt i uppgifterna så signerar du med BankID.',
    },
    {
      question: 'Varför kan jag inte läsa Forskningspersonsinformationen?',
      answer:
        'En del studier har valt att endast visa Forskningspersonsinformationen för personer som har en personlig profil och som loggat in på minforskning.se. Andra studier har valt att visa Forskningspersonsinformationen utan att man behöver logga in.',
    },
    {
      question: 'Hur återkallar jag mitt samtycke från en studie?',
      answer:
        'Önskar du återkalla ditt samtycke/ditt deltagande så kontakta den vårdenheten du tillhör för studien, så hjälper personalen dig. Du hittar kontaktuppgifterna genom att klicka på ”Mitt deltagande” och därefter välja fliken ”Vårdenhet”.',
    },
    {
      question: 'Hur kontaktar jag en studie?',
      answer:
        'Önskar du kontakt med studien så finner du kontaktuppgifterna om du klickar på studien och väljer ”Kontaktinformation”. Där väljer du den vårdenhet som du tillhör eller önskar tillhöra för studien.',
    },
    {
      question: 'Varför måste jag ha BankID?',
      answer:
        'Eftersom minforskning.se hanterar uppgifter om hälsa så ställs höga krav på skyddet av dessa uppgifter. I rollen som personuppgiftsansvarig krävs att minforskning.se kan garantera skyddet för känsliga personuppgifter i systemet. Åtkomst får endast vara möjlig genom stark autentisering (tvåfaktorsautentisering), och vi använder därför BankID.',
    },
    {
      question:
        'Var kan jag läsa mer om hur mina personuppgifter hanteras i en studie?',
      answer:
        'I varje studie på minforskning.se finns en Forskningspersonsinformation. I den finns alltid uppgifter om vem som är forskningshuvudman och personuppgiftsansvarig, vilka personuppgifter som hanteras och hur, samt kontaktuppgifter till det dataskyddsombud som kan svara på fler frågor om personuppgiftshanteringen.',
    },
    {
      question:
        'Jag hittar inte min studie?',
      answer:
        'Efter att en studie har avslutats kan du fortfarande, efter att du loggat in med BankID, ha tillgång till en kopia av ditt signerade samtycke, läsa om studien och ha till gång till eventuella meddelanden.',
    },
  ];

  constructor() {}

  ngOnInit(): void {}
}
