import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
})
export class IconComponent implements OnInit {
  @Input() public name!: string;
  public svg: any;
  
  constructor(private http: HttpClient, private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    this.http.get('assests/name.svg').subscribe;
    this.http
      .get(`assets/icons/${this.name}.svg`, { responseType: 'text' })
      .subscribe((value) => {
        this.svg = this.sanitizer.bypassSecurityTrustHtml(value);
      });
  }
}
