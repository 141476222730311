import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-site',
  templateUrl: './site.component.html',
})
export class SiteComponent implements OnInit {
  private apiUrlPortal: string = environment.apiUrlPortal;
  private studyId: string;
  public isPatientLoggedIn = false;
  patientSite: any = {};

  constructor(
    private router: Router,
    private http: HttpClient,
    private keycloakService: KeycloakService,
  ) {
    this.studyId = this.router.url.split('/')[3];
  }

  public async ngOnInit() {
    this.isPatientLoggedIn = await this.keycloakService.isLoggedIn();
    if (this.isPatientLoggedIn) {
      this.getPatientSite();
    }
  }
  getPatientSite() {
    this.http.get<any>(`${this.apiUrlPortal}/user/my-studies/${this.studyId}`).subscribe((response) => {
      this.patientSite = response[0];
    });
  }
}
