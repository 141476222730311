import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-study-description',
  templateUrl: './study-description.component.html',
})
export class StudyDescriptionComponent {
  private _description: string = '';

  @Input()
  set description(value: string) {
    this._description = value;
  }

  get description(): string {
    return this._description;
  }
}
