<label
  [for]="id"
  [ngClass]="{
    'text-red-600': control.invalid && (control.dirty || control.touched),
    'text-gray-700': !control.invalid
  }"
  class="block text-sm font-medium"
>
  {{ label }}
</label>
<div class="relative mt-1 rounded-md shadow-sm">
  <input
    [type]="type"
    [id]="id"
    [placeholder]="placeholder"
    [formControl]="$any(control)"
    [attr.maxlength]="maxLength"
    class="block w-full rounded-md pr-10 sm:text-sm"
    [ngClass]="
      control.invalid && (control.dirty || control.touched)
        ? 'border-red-300 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500'
        : 'border-gray-300 focus:border-sky-500 focus:ring-sky-500 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200'
    "
    autocomplete="off"
  />
  <div
    *ngIf="control.invalid && (control.dirty || control.touched)"
    class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
  >
    <app-icon [name]="'exclamation-circle-solid'" class="h-5 w-5 text-red-500"></app-icon>
  </div>
</div>
<div *ngIf="control.invalid && (control.dirty || control.touched)" [id]="id" class="mt-2 text-sm text-red-600">
  {{ getErrorMessage() }}
</div>
