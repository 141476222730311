import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'consentFilter',
})
export class ConsentFilterPipe implements PipeTransform {
  transform(array: any, filter: any, status?: string): any[] {
    if (status != undefined) {
      return array.filter(
        (data: any) =>
          data.consent.revoked === filter && data.consent.status === status
      );
    } else {
      return array.filter((data: any) => data.consent.revoked === filter);
    }
  }
}
