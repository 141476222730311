import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import {
  IConsentFlowsRequest,
  IConsentFlowSignature,
  IConsentFlowInitiate,
  IConsent,
} from '../../models/consent.model';
import { ISite } from '../../models/site.model';

const BASE_URL = environment.baseUrl;

@Injectable({
  providedIn: 'root',
})
export class ConsentFlowService {
  private baseUrl = environment.baseUrl;
  private apiUrlPortal: string = environment.apiUrlPortal;

  private consentSource = new BehaviorSubject<IConsent | null>(null);
  consent$ = this.consentSource.asObservable();

  private consentFlow = new BehaviorSubject<IConsentFlowsRequest | null>(null);
  consentFlow$ = this.consentFlow.asObservable();

  private consentInitiatedSource = new BehaviorSubject<any | null>(null);
  consentInitiated$ = this.consentInitiatedSource.asObservable();

  private consentTransitionLinkSource = new BehaviorSubject<string | null>(null);
  consentTransitionLink$ = this.consentTransitionLinkSource.asObservable();

  private fpiReadSource = new BehaviorSubject<boolean>(false);
  fpiRead$ = this.fpiReadSource.asObservable();

  private resetFamilyFlowSource = new BehaviorSubject<any | null>(null);
  resetFamilyFlow$ = this.resetFamilyFlowSource.asObservable();

  private usersChildrenSource = new BehaviorSubject<any | null>(null);
  usersChildren$ = this.usersChildrenSource.asObservable();

  private dependentSource = new BehaviorSubject<any | null>(null);
  dependent$ = this.dependentSource.asObservable();

  private currentSiteSelection = new BehaviorSubject<ISite | null>(null);
  currentSiteSelection$ = this.currentSiteSelection.asObservable();

  private adultAgeCheckSource = new BehaviorSubject<boolean>(false);
  adultAgeCheck$ = this.adultAgeCheckSource.asObservable();

  constructor(private http: HttpClient) {}

  setConsent(consent: IConsent | null) {
    this.consentSource.next(consent);
  }

  setConsentFlow(consentFlow: IConsentFlowsRequest | null) {
    this.consentFlow.next(consentFlow);
  }

  setConsentTransitionLink(transitionLink: string | null) {
    this.consentTransitionLinkSource.next(transitionLink);
  }

  setConsentInitiated(consent: any | null) {
    this.consentInitiatedSource.next(consent);
  }

  setFpiRead(read: boolean) {
    this.fpiReadSource.next(read);
  }

  setResetFamilyFlow(reset: boolean) {
    this.resetFamilyFlowSource.next(reset);
  }

  setUserChildren(dependent: any) {
    this.usersChildrenSource.next(dependent);
  }

  setDependent(dependent: any) {
    this.dependentSource.next(dependent);
  }

  setCurrentSiteSelection(site: ISite | null) {
    this.currentSiteSelection.next(site);
  }

  setAdultAgeCheck(adultAgeCheck: boolean) {
    this.adultAgeCheckSource.next(adultAgeCheck);
  }

  initiate(studyId: string, siteId: string, data: IConsentFlowInitiate) {
    return this.http.post<IConsentFlowsRequest>(
      `${this.apiUrlPortal}/studies/${studyId}/sites/${siteId}/consent-flows`,
      data,
    );
  }

  signature(transitionLink: string, data: IConsentFlowSignature) {
    return this.http.post<IConsentFlowsRequest>(`${this.baseUrl}${transitionLink}`, data);
  }

  collectSignature(transitionLink: string) {
    return this.http.get<IConsentFlowsRequest>(`${BASE_URL}${transitionLink}`, {
      observe: 'response',
    });
  }
}
