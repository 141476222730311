import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StudyService } from 'src/app/core/services/portal/study.service';

@Component({
  selector: 'app-study-contact',
  templateUrl: './study-contact.component.html',
})
export class StudyContactComponent implements OnInit {
  private studyId: string;
  sites: any[] = [];
  settings: any = {};
  study: any = {};
  term: string = '';

  constructor(
    private router: Router,
    private _studyService: StudyService,
  ) {
    this.studyId = this.router.url.split('/')[3];
  }

  ngOnInit(): void {
    this.getStudySites();
    this.getStudySettings();
    this.getStudy();
  }

  getStudy() {
    this._studyService.getStudy(this.studyId).subscribe((response) => {
      this.study = response;
    });
  }
  getStudySettings() {
    this._studyService.getStudySetting(this.studyId).subscribe((response) => {
      this.settings = response;
    });
  }
  getStudySites() {
    this._studyService.getStudySites(this.studyId).subscribe((response: any) => {
      this.sites = response.filter((site: any) => site.active);
    });
  }
}
