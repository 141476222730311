import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-badge',
  templateUrl: './badge.component.html'  
})
export class BadgeComponent implements OnInit {
  @Input() label!: string;
  @Input() rounded? = true;
  @Input() large? = true;
  @Input() color?: string = '';
  constructor() { }

  ngOnInit(): void {
  }

}
