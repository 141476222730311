import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LocalStorageRefService } from './local-storage-ref.service';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  private localStorage: Storage;

  private data$ = new BehaviorSubject<any>(null);
  public data = this.data$.asObservable();

  constructor(private localStorageRefService: LocalStorageRefService) {
    this.localStorage = localStorageRefService.localStorage;
  }

  getData<T>(key: string): T | null {
    const item = <string>this.localStorage.getItem(key) || '';

    if (item === '') return null;

    return <T>JSON.parse(item);
  }

  setData(key: string, data: any): void {
    const jsonData = JSON.stringify(data);
    this.localStorage.setItem(key, jsonData);

    this.data$.next(data);
  }

  loadData(key: string): any {
    try {
      const item = this.localStorage.getItem(key) || '';

      if (item === '') return null;

      const data = JSON.parse(item);

      this.data$.next(data);
    } catch (error) {
      console.error(error);
    }
  }

  clearData(key: string): void {
    this.localStorage.removeItem(key);
    this.data$.next(null);
  }

  clearAllLocalStorage(): void {
    this.localStorage.clear();
    this.data$.next(null);
  }
}
