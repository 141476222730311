import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit {
  @Output() close = new EventEmitter();
  @Input() largeDialog? = true;
  @Input() closeButton? = true;
  constructor() {}

  ngOnInit(): void {}
}
