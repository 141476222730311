<!-- Tabs -->
<div>
  <div class="sm:hidden">
    <label for="tabs" class="sr-only">Select a tab</label>
    <select
      (change)="navigateTo($event)"
      class="block w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
    >
      <option value="consent">Samtycke</option>

      <option *ngIf="settings.resultReportable" value="results">
        Resultat och meddelanden
      </option>

      <option value="site">Din vårdenhet</option>
    </select>
  </div>
  <div class="border-b border-gray-300 pb-4 hidden sm:flex sm:justify-between">
    <nav class="flex space-x-4" aria-label="Tabs">
      <a
        routerLink="consent"
        routerLinkActive="bg-gray-100 text-gray-700"
        class="text-gray-500 hover:text-gray-700 px-3 py-2 font-medium text-sm rounded-md"
      >
        Samtycke
      </a>

      <a
        *ngIf="settings.resultReportable"
        routerLink="results"
        routerLinkActive="bg-gray-100 text-gray-700"
        class="text-gray-500 hover:text-gray-700 px-3 py-2 font-medium text-sm rounded-md"
      >
        Resultat och meddelanden
      </a>

      <a
        routerLink="site"
        routerLinkActive="bg-gray-100 text-gray-700"
        class="text-gray-500 hover:text-gray-700 px-3 py-2 font-medium text-sm rounded-md"
      >
        Din vårdenhet
      </a>
    </nav>
  </div>
</div>

<div class="pt-4 pb-16 space-y-8">
  <router-outlet></router-outlet>
</div>
