import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { IReadFpiRequest, IReadFpiCheckerResponse, IFpiPdfRequest } from '../../models/fpi.model';

@Injectable({
  providedIn: 'root',
})
export class FpiService {
  private apiUrl: string = environment.apiUrlCommon + '/studies';
  private apiUrlPortal: string = environment.apiUrlPortal;

  constructor(private http: HttpClient) {}

  getFpiPDF(request: IFpiPdfRequest) {
    const { studyId, siteId, fpiId } = request;
    return this.http.get(`${this.apiUrl}/${studyId}/sites/${siteId}/fpis/${fpiId}/pdf`, {
      responseType: 'blob',
    });
  }

  getReadFPI(request: IReadFpiRequest) {
    return this.http.post<IReadFpiCheckerResponse>(`${this.apiUrlPortal}/read-fpi-checker`, request);
  }

  setReadFPI(request: IReadFpiRequest) {
    return this.http.post<IReadFpiRequest>(`${this.apiUrlPortal}/read-fpis`, request);
  }

  getFPICheck(data: any) {
    return this.http.post<any>(`${this.apiUrlPortal}/read-fpi-checker`, data);
  }
}
