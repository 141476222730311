<!-- Complete Step -->
<span *ngIf="status === 'complete'" class="relative flex h-6 w-6 flex-shrink-0 items-center justify-center">
  <svg class="h-full w-full text-[#008744]" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
    <path
      fill-rule="evenodd"
      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
      clip-rule="evenodd"
    />
  </svg>
</span>

<!-- Current Step -->
<span
  *ngIf="status === 'current'"
  class="relative flex h-6 w-6 flex-shrink-0 items-center justify-center"
  aria-hidden="true"
>
  <span class="absolute h-5 w-5 rounded-full bg-sky-200"></span>
  <span class="relative block h-3 w-3 rounded-full bg-sky-600"></span>
</span>

<!-- Upcoming Step -->
<div
  *ngIf="status === 'upcoming'"
  class="relative flex h-6 w-6 flex-shrink-0 items-center justify-center"
  aria-hidden="true"
>
  <div class="h-3 w-3 rounded-full bg-gray-300"></div>
</div>

<!-- greencheck Step -->

<svg *ngIf="status === 'greencheck'" class="h-4 w-4 text-[#008744]" viewBox="0 0 20 20" fill="none" aria-hidden="true">
  <path
    fill="none"
    stroke="#008744"
    stroke-width="2"
    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
  />
</svg>
