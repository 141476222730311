<div class="pb-6 border-b-2 border-blue-500">
  <h3 class="text-lg leading-6 font-medium text-gray-900">
    Kontaktuppgifter till studien
  </h3>
  <div class="mt-3 sm:mt-4 lg:grid lg:grid-cols-3 lg:gap-5 lg:items-center">
    <p class="lg:col-span-2 text-sm text-gray-500">
      Här finns en lista på de vårdenheter som ingår i studien. Välj den
      vårdenhet som passar dig bäst för att få veta mer om studien.
    </p>
    <form class="mt-6 flex flex-col sm:flex-row lg:mt-0 lg:justify-end">
      <label for="search" class="sr-only">Search</label>
      <input
        id="search"
        name="term"
        [(ngModel)]="term"
        type="search"
        maxlength="40"
        class="appearance-none w-full px-4 py-2 border border-gray-300 text-base rounded-md text-gray-900 bg-white placeholder-gray-500 focus:outline-none focus:ring-sky-500 focus:border-sky-500 lg:max-w-xs"
        placeholder="Sök vårdenhet"
      />
    </form>
  </div>
</div>
<div class="mt-6 sm:mt-8">
  <div class="divide-y-2 divide-gray-200">
    <div
      *ngFor="let site of sites | siteFilter: term; let i = index"
      [ngClass]="i > 0 ? 'mt-8 pt-8' : ''"
      class="lg:grid lg:grid-cols-3 lg:gap-8"
    >
      <div class="flex flex-col">
        <h2 class="text-base font-medium text-gray-900">
          {{ site.name }}
        </h2>
        <p class="mt-1 text-sm text-gray-500">
          {{ site.city }}
        </p>
      </div>
      <div
        class="mt-8 grid grid-cols-1 gap-12 sm:grid-cols-2 sm:gap-x-8 sm:gap-y-12 lg:mt-0 lg:col-span-2"
      >
        <div
          *ngIf="
            settings.showLocalContactName ||
            settings.showLocalContactEmail ||
            settings.showLocalContactPhone
          "
        >
          <h3 class="text-base font-medium leading-6 text-gray-900">
            Lokala kontaktuppgifter
          </h3>
          <dl class="mt-2 text-sm text-gray-500">
            <div *ngIf="settings.showLocalContactName" class="mt-1">
              <dt class="sr-only">Namn</dt>
              <dd>{{ site.localContactName }}</dd>
            </div>
            <div *ngIf="settings.showLocalContactEmail" class="mt-1">
              <dt class="sr-only">Email</dt>
              <dd>{{ site.localContactEmail }}</dd>
            </div>
            <div *ngIf="settings.showLocalContactPhone" class="mt-1">
              <dt class="sr-only">Telefonnummer</dt>
              <dd>{{ site.localContactPhone }}</dd>
            </div>
          </dl>
        </div>
        <div>
          <h3 class="text-base font-medium leading-6 text-gray-900">
            Besöksadress
          </h3>
          <dl class="mt-2 text-sm text-gray-500">
            <div class="mt-1">
              <dt class="sr-only">Adress</dt>
              <dd>{{ site.streetAddress }}</dd>
            </div>
            <div class="mt-1">
              <dt class="sr-only">Ort och postnummer</dt>
              <dd>
                {{ site.municipality }},
                {{ site.postalCode }}
              </dd>
            </div>
          </dl>
        </div>
        <div
          *ngIf="
            settings.showCoordinatorName ||
            settings.showCoordinatorEmail ||
            settings.showCoordinatorPhone
          "
        >
          <h3 class="text-base font-medium leading-6 text-gray-900">
            Studiekordinator
          </h3>
          <dl class="mt-2 text-sm text-gray-500">
            <div *ngIf="settings.showCoordinatorName" class="mt-1">
              <dt class="sr-only">Namn</dt>
              <dd>{{ study.coordinatorName }}</dd>
            </div>
            <div *ngIf="settings.showCoordinatorEmail" class="mt-1">
              <dt class="sr-only">Email</dt>
              <dd>{{ study.coordinatorEmail }}</dd>
            </div>
            <div *ngIf="settings.showCoordinatorPhone" class="mt-1">
              <dt class="sr-only">Telefonnummer</dt>
              <dd>{{ study.coordinatorPhone }}</dd>
            </div>
          </dl>
        </div>
        <div
          *ngIf="
            settings.showInvestigatorName ||
            settings.showInvestigatorEmail ||
            settings.showInvestigatorPhone
          "
        >
          <h3 class="text-base font-medium leading-6 text-gray-900">
            Ansvarig forskare
          </h3>
          <dl class="mt-2 text-sm text-gray-500">
            <div *ngIf="settings.showInvestigatorName" class="mt-1">
              <dt class="sr-only">Namn</dt>
              <dd>{{ study.investigatorName }}</dd>
            </div>
            <div *ngIf="settings.showInvestigatorEmail" class="mt-1">
              <dt class="sr-only">Email</dt>
              <dd>{{ study.investigatorEmail }}</dd>
            </div>
            <div *ngIf="settings.showInvestigatorPhone" class="mt-1">
              <dt class="sr-only">Telefonnummer</dt>
              <dd>{{ study.investigatorPhone }}</dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  </div>
</div>
