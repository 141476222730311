import { Component, OnInit } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { forkJoin, from, of } from 'rxjs';
import { catchError, filter, map, mergeMap, tap, toArray } from 'rxjs/operators';
import { IConsent } from 'src/app/core/models/consent.model';
import { IDependentWithConsents } from 'src/app/core/models/family.model';
import { ISite } from 'src/app/core/models/site.model';
import { ISettings } from 'src/app/core/models/study-settings.model';
import { IStudy } from 'src/app/core/models/study.model';
import { ConsentService } from 'src/app/core/services/portal/consent.service';
import { StudyService } from 'src/app/core/services/portal/study.service';
import { UserService } from 'src/app/core/services/portal/user.service';

interface IPatientStudy {
  consent: IConsent;
  study: IStudy;
  site: ISite;
}

interface IStudySetting extends IStudy {
  setting: ISettings;
}
@Component({
  selector: 'app-studies',
  templateUrl: './studies.component.html',
})
export class StudiesComponent implements OnInit {
  public isPatientLoggedIn = false;
  patientStudies: IPatientStudy[] = [];
  dependentsStudies: IPatientStudy[] = [];

  studies: IStudySetting[] = [];

  constructor(
    private studyService: StudyService,
    private userService: UserService,
    private consentService: ConsentService,
    private keycloakService: KeycloakService,
  ) {}

  ngOnInit() {
    this.initializeData();
  }

  private initializeData() {
    this.keycloakService.isLoggedIn().then((isLoggedIn) => {
      this.isPatientLoggedIn = isLoggedIn;
      if (this.isPatientLoggedIn) {
        forkJoin([this.getPatientStudies(), this.getPatientDependents()]).subscribe();
      }
      this.getStudies();
    });
  }

  getStudyLink(item: IPatientStudy): string {
    const formattedName = item.study.name.replace(/ /g, '-').toLowerCase();
    return `study/${formattedName}/${item.site.studyId}/my-participation/consent`;
  }

  login(study: IStudy) {
    const redirectUri = `/study/${study.slug}/${study.id}`;

    this.keycloakService.login({
      idpHint: 'GrandId-BankId',
      redirectUri: window.location.origin + redirectUri,
    });
  }

  private getStudies() {
    forkJoin({
      studies: this.studyService.getOpenStudies(),
      settings: this.studyService.getOpenStudiesSettings(),
    })
      .pipe(
        map(({ studies, settings }) =>
          studies.map((study: IStudy) => ({
            ...study,
            setting: settings.find((s: ISettings) => s.studyId === study.id),
          })),
        ),
        catchError((error) => {
          console.error('Error fetching studies', error);
          return of([]);
        }),
      )
      .subscribe((studies) => (this.studies = studies));
  }

  private getPatientStudies() {
    return this.userService.getMyStudies().pipe(
      catchError((error) => {
        console.error('Error fetching patient studies', error);
        return of([]);
      }),
      tap((studies) => (this.patientStudies = studies)),
    );
  }

  private getPatientDependents() {
    return this.consentService.getDependents().pipe(
      mergeMap((response) => from(response.dependentsWithConsents)),
      mergeMap((dependent: IDependentWithConsents) =>
        from(dependent.consents as IConsent[]).pipe(
          filter((consent: IConsent) => consent.status === 'parent-signed'),
          mergeMap((consent) =>
            forkJoin({
              study: this.studyService.getStudy(consent.studyId),
              site: this.studyService.getStudySiteInfo(consent.studyId, consent.siteId),
            }).pipe(map(({ study, site }) => ({ consent, study, site }))),
          ),
          toArray(),
        ),
      ),
      toArray(),
      catchError(() => of([] as IPatientStudy[][])),
      map((results: IPatientStudy[][]) => results.flat()),
      tap((dependentsStudies: IPatientStudy[]) => (this.dependentsStudies = dependentsStudies)),
    );
  }
}
