import { Component, Input, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeUrl } from '@angular/platform-browser';
@Component({
  selector: 'app-qr-code',
  templateUrl: './qr-code.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QrCodeComponent implements OnInit {
  private _autoStartToken!: string;
  private _redirectURL: string | null = null;
  private _qrCodeBase64: string | null = null;
  qrCode: SafeHtml | null = null;
  isMobileDevice: boolean = false;
  private bankIdUrlCached: SafeUrl | null = null;

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    this.isMobileDevice = this.isMobile();
    this.cacheBankIdUrl();
  }
  private updateQrCodeSvg(): void {
    if (this._qrCodeBase64) {
      const decodedSVG = atob(this._qrCodeBase64);
      this.qrCode = this.sanitizer.bypassSecurityTrustHtml(decodedSVG);
    } else {
      this.qrCode = null;
    }
  }
  private isMobile(): boolean {
    const regex = /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
    return regex.test(navigator.userAgent);
  }
  private cacheBankIdUrl(): void {
    const baseUrl = this.isMobileDevice ? 'https://app.bankid.com/?' : 'bankid:///?';
    this.bankIdUrlCached = this.sanitizer.bypassSecurityTrustUrl(
      `${baseUrl}autostarttoken=${this._autoStartToken}&redirect=${this._redirectURL}`,
    );
  }
  @Input() set qrCodeBase64(value: string | null) {
    this._qrCodeBase64 = value;
    this.updateQrCodeSvg();
  }
  @Input() set autoStartToken(value: string) {
    this._autoStartToken = value;
    this.cacheBankIdUrl();
  }
  @Input() set redirectURL(value: string | null) {
    this._redirectURL = value;
    this.cacheBankIdUrl();
  }

  generateBankIDUrl(): SafeUrl {
    return this.bankIdUrlCached!;
  }
}
