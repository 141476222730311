import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})

// Helper functions
export class HelperService {
  private userCompletedSignature = new BehaviorSubject<boolean>(false);
  userCompletedSignature$ = this.userCompletedSignature.asObservable();

  constructor() {}

  updateUserCompletedSignature(value: boolean) {
    this.userCompletedSignature.next(value);
  }

  getAge(dateString: string) {
    const formatDateString = `${dateString.slice(0, 4)}/${dateString.slice(4, 6)}/${dateString.slice(6, 8)}`;
    const today = new Date();
    const birthDate = new Date(formatDateString);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }
}
