<app-study-description [description]="study?.description ?? ''"></app-study-description>

<div *ngIf="settings && settings.textForChildConsent" class="mt-12">
  <quill-view-html [content]="settings.textForChildConsent" theme="snow"></quill-view-html>
</div>

<ng-container *ngIf="!isUserLoggedIn && settings?.eIdentification; else consentFlow">
  <app-login></app-login>
</ng-container>

<ng-template #consentFlow>
  <div class="mt-6">
    <app-study-fpi [study]="study" [settings]="settings" (fpiSelected)="onFpiSelected($event)"></app-study-fpi>
  </div>

  <app-login *ngIf="!isUserLoggedIn && showLogin"></app-login>

  <div id="consent-flow">
    <app-alerts *ngIf="showStepper && isUserLoggedIn" [type]="'info'" [message]="ageNotInSpanMessage"> </app-alerts>
    <app-consent-stepper
      *ngIf="isUserLoggedIn && userProfile && fpiSelected && consentFlowType && !showStepper"
      [consentFlowType]="consentFlowType"
      [fpi]="fpiSelected"
      [settings]="settings"
      [userProfile]="userProfile"
    ></app-consent-stepper>
  </div>
</ng-template>
