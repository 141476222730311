import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';

@Injectable({
  providedIn: 'root',
})
export class PortalGuard extends KeycloakAuthGuard {
  constructor(
    protected override readonly router: Router,
    protected readonly keycloak: KeycloakService
  ) {
    super(router, keycloak);
  }

  public async isAccessAllowed(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    if (this.authenticated) {
      // Get the roles required from the route.
      const requiredRoles = route.data['roles'];
      //Check if user exists and has a role
      if (this.roles.includes('patient') || this.roles.includes('doctor')) {
        return true;
      } else {
        this.router.navigate(['/create-account']);
        return false;
      }
    } else {
      return true;
    }
  }
}
