<div *ngIf="shouldDisplayButtons" class="flex justify-center">
  <span class="relative z-0 inline-flex shadow-sm bg-gray-200 rounded-full p-0.5">
    <span
      [ngClass]="[contactMethod === 'interest-form' ? 'translate-x-44' : 'translate-x-0']"
      class="absolute p-0.5 inline-block h-9 w-44 border border-gray-300 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
    ></span>
    <button
      *ngIf="settings.showContactOption || settings.consentFlowMethod === 'contact'"
      type="button"
      (click)="toggleForm('contact')"
      class="relative inline-flex items-center justify-center w-44 py-2 rounded-full text-sm font-medium text-gray-700 focus:z-10 focus:outline-none"
    >
      Kontaktinformation
    </button>
    <button
      *ngIf="settings.showInterestOption || settings.consentFlowMethod === 'interest-form'"
      type="button"
      (click)="toggleForm('interest-form')"
      class="relative inline-flex items-center justify-center w-44 py-2 rounded-full text-sm font-medium text-gray-700 focus:z-10 focus:outline-none"
    >
      Intresseanmälan
    </button>
  </span>
</div>

<div *ngIf="contactMethod === 'contact'; else registerForm">
  <div class="text-center py-10">
    <h2 class="text-base font-medium text-gray-900">
      {{ selectedSite.name }}
    </h2>
    <p class="mt-1 text-sm text-gray-500">
      {{ selectedSite.city }}
    </p>
    <div
      *ngIf="settings.showLocalContactName || settings.showLocalContactEmail || settings.showLocalContactPhone"
      class="mt-6"
    >
      <h3 class="text-base font-medium leading-6 text-gray-900">Lokala kontaktuppgifter</h3>
      <dl class="mt-2 text-sm text-gray-500">
        <div *ngIf="settings.showLocalContactName" class="mt-1">
          <dt class="sr-only">Namn</dt>
          <dd>{{ selectedSite.localContactName }}</dd>
        </div>
        <div *ngIf="settings.showLocalContactEmail" class="mt-1">
          <dt class="sr-only">Email</dt>
          <dd>{{ selectedSite.localContactEmail }}</dd>
        </div>
        <div *ngIf="settings.showLocalContactPhone" class="mt-1">
          <dt class="sr-only">Telefonnummer</dt>
          <dd>{{ selectedSite.localContactPhone }}</dd>
        </div>
      </dl>
    </div>
    <div class="mt-6">
      <h3 class="text-base font-medium leading-6 text-gray-900">Besöksadress</h3>
      <dl class="mt-2 text-sm text-gray-500">
        <div class="mt-1">
          <dt class="sr-only">Adress</dt>
          <dd>{{ selectedSite.streetAddress }}</dd>
        </div>
        <div class="mt-1">
          <dt class="sr-only">Ort och postnummer</dt>
          <dd>
            {{ selectedSite.municipality }},
            {{ selectedSite.postalCode }}
          </dd>
        </div>
      </dl>
    </div>
  </div>
</div>

<ng-template #registerForm>
  <ng-container *ngIf="!success; else successContent">
    <p class="my-4 text-sm text-gray-500">Här anger du hur du vill bli kontaktad av studien.</p>
    <div class="relative max-w-xl mx-auto">
      <form
        #registerForm="ngForm"
        (ngSubmit)="onSubmitForm(registerForm.value)"
        class="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
      >
        <ng-container *ngFor="let field of fields">
          <div class="col-span-2">
            <label [for]="field.id" class="block text-sm font-medium text-gray-700">
              {{ field.label }}
            </label>
            <div class="mt-1 rounded-md shadow-sm">
              <ng-container *ngIf="field.id === 'phone'; else otherInput"
                ><input
                  [type]="field.type"
                  [name]="field.id"
                  ngModel
                  [maxlength]="field.maxLength"
                  [minlength]="field.minLength"
                  required
                  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                  [id]="field.id"
                  [placeholder]="field.placeholder"
                  class="block w-full sm:text-sm rounded-md pr-10 focus:ring-sky-500 focus:border-sky-500 border-gray-300"
              /></ng-container>
              <ng-template #otherInput
                ><input
                  [type]="field.type"
                  [name]="field.id"
                  ngModel
                  [maxlength]="field.maxLength"
                  [minlength]="field.minLength"
                  required
                  [id]="field.id"
                  [placeholder]="field.placeholder"
                  class="block w-full sm:text-sm rounded-md pr-10 focus:ring-sky-500 focus:border-sky-500 border-gray-300"
              /></ng-template>
            </div>
          </div>
        </ng-container>

        <div class="sm:col-span-2 text-right">
          <app-button [label]="'Skicka'" [type]="'submit'" [disabled]="!registerForm.valid"></app-button>
        </div>
      </form>
    </div>
  </ng-container>

  <ng-template #successContent>
    <div class="max-w-md mx-auto px-4 pt-5 pb-4">
      <div>
        <div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
          <app-icon [name]="'check'" class="h-6 w-6 text-green-600"></app-icon>
        </div>
        <div class="mt-3 text-center sm:mt-5">
          <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">Tack för visat intresse</h3>
          <div class="mt-2">
            <p class="text-sm text-gray-500">Detta är en bekräftelse på att ditt intresse har registrerats.</p>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</ng-template>
