<div class="relative bg-white shadow">
  <div class="relative py-4">
    <div>
      <!--  Desktop navigation for portal -->
      <nav
        class="relative max-w-7xl mx-auto flex items-center justify-between px-4 sm:px-6"
      >
        <div class="flex items-center flex-1">
          <div class="flex items-center justify-between w-full md:w-auto">
            <div class="flex items-center md:hidden">
              <button
                (click)="openMenu()"
                type="button"
                class="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                aria-expanded="false"
              >
                <span class="sr-only">Open main menu</span>
                <app-icon [name]="'bars-3'" class="h-6 w-6 stroke-2"></app-icon>
              </button>
            </div>
          </div>
          <div class="hidden md:block md:space-x-10">
            <a
              [routerLinkActive]="'text-sky-600 font-medium'"
              [routerLinkActiveOptions]="{ exact: true }"
              routerLink="/"
              class="text-gray-500 hover:text-gray-900"
              >Studier</a
            >

            <a
              [routerLinkActive]="'text-sky-600 font-medium'"
              routerLink="/faq"
              class="text-gray-500 hover:text-gray-900"
              >Vanliga frågor</a
            >
          </div>
        </div>
        <div *ngIf="!isPatientLoggedIn" class="hidden md:block text-right">
          <a
            routerLink="/login/portal"
            class="inline-flex px-4 py-2 font-medium text-sky-600 rounded-md hover:bg-sky-100 hover:text-sky-800"
          >
            Logga in
          </a>
        </div>
        <div *ngIf="isPatientLoggedIn" class="hidden md:block">
          <div *ngIf="userProfile" class="relative">
            <div>
              <button
                type="button"
                (click)="onOpenProfile()"
                class="bg-white rounded-full flex text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
              >
                <span
                  class="inline-flex items-center justify-center h-10 w-10 bg-sky-600 rounded-full"
                >
                  <span
                    *ngIf="
                      userProfile.firstName && userProfile.lastName;
                      else defaultInitials
                    "
                    class="text-white font-medium leading-none"
                  >
                    {{ userProfile.firstName[0] }}{{ userProfile.lastName[0] }}
                  </span>
                  <ng-template #defaultInitials>
                    <span class="text-white font-medium leading-none">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-6 h-6"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"
                        />
                      </svg>
                    </span>
                  </ng-template>
                </span>
              </button>
            </div>
            <div
              *ngIf="showProfileDropdown"
              class="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
            >
              <div class="px-4 py-3">
                <p class="text-sm">
                  {{ userProfile.firstName + ' ' + userProfile.lastName }}
                </p>
                <p class="truncate text-sm font-medium text-gray-900">
                  {{ userProfile.email }}
                </p>
              </div>
              <div class="py-1">
                <a
                  (click)="onOpenProfile()"
                  routerLink="/profile"
                  [routerLinkActive]="'bg-sky-100 text-sky-600 font-medium'"
                  [routerLinkActiveOptions]="{ exact: true }"
                  class="text-gray-700 block px-4 py-2 text-sm"
                  >Profil</a
                >
                <a
                  *ngIf="userRoles.includes('doctor')"
                  (click)="onOpenProfile()"
                  routerLink="/eic/select-study"
                  [routerLinkActive]="'bg-sky-100 text-sky-600 font-medium'"
                  class="text-gray-700 block px-4 py-2 text-sm"
                  >Forskningspersonal</a
                >
              </div>
              <div class="py-1">
                <button
                  type="button"
                  (click)="logout()"
                  class="text-gray-700 block w-full px-4 py-2 text-left text-sm"
                >
                  Logga ut
                </button>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <!--  Mobile navigation for portal  -->
      <div *ngIf="mobileNavigation" role="dialog" aria-modal="true">
        <div
          focus="true"
          class="fixed inset-0 z-10 overflow-y-auto bg-white px-6 py-6 lg:hidden"
        >
          <div class="flex h-9 items-center justify-between">
            <div class="flex">
              <a href="#" class="-m-1.5 p-1.5">
                <span class="sr-only">Min forskning</span>
              </a>
            </div>
            <div class="flex">
              <button
                type="button"
                (click)="closeMenu()"
                class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
              >
                <span class="sr-only">Close menu</span>
                <app-icon
                  [name]="'x-mark'"
                  class="h-6 w-6 stroke-[1.5px]"
                ></app-icon>
              </button>
            </div>
          </div>
          <div class="mt-6 flow-root">
            <div class="-my-6 divide-y divide-gray-500/10">
              <div class="space-y-2 py-6">
                <a
                  (click)="closeMenu()"
                  routerLink="/"
                  [routerLinkActive]="'bg-sky-400/10 text-sky-900 font-medium'"
                  [routerLinkActiveOptions]="{ exact: true }"
                  class="-mx-3 block rounded-lg py-2 px-3 text-base font-semibold leading-7 text-sky-900 hover:bg-sky-400/10"
                  >Studier</a
                >

                <a
                  (click)="closeMenu()"
                  routerLink="/faq"
                  [routerLinkActive]="'bg-sky-400/10 text-sky-900 font-medium'"
                  [routerLinkActiveOptions]="{ exact: true }"
                  class="-mx-3 block rounded-lg py-2 px-3 text-base font-semibold leading-7 text-sky-900 hover:bg-sky-400/10"
                  >Vanliga frågor</a
                >
              </div>
              <div *ngIf="!isPatientLoggedIn" class="py-6">
                <a
                  (click)="closeMenu()"
                  routerLink="/login"
                  class="-mx-3 block rounded-lg py-2.5 px-3 text-base font-semibold leading-6 text-sky-900 hover:bg-sky-400/10"
                  >Logga in</a
                >
              </div>
              <div
                *ngIf="isPatientLoggedIn && userProfile"
                class="space-y-2 py-6"
              >
                <div>
                  <p class="text-sm">
                    {{ userProfile.firstName + ' ' + userProfile.lastName }}
                  </p>
                  <p class="truncate text-sm font-medium text-gray-900">
                    {{ userProfile.email }}
                  </p>
                </div>
                <a
                  (click)="closeMenu()"
                  routerLink="/profile"
                  [routerLinkActive]="'bg-sky-400/10 text-sky-900 font-medium'"
                  [routerLinkActiveOptions]="{ exact: true }"
                  class="-mx-3 block rounded-lg py-2.5 px-3 text-base font-semibold leading-6 text-sky-900 hover:bg-sky-400/10"
                  >Profil</a
                >
                <a
                  *ngIf="userRoles.includes('doctor')"
                  (click)="closeMenu()"
                  routerLink="/eic/select-study"
                  class="-mx-3 block rounded-lg py-2.5 px-3 text-base font-semibold leading-6 text-sky-900 hover:bg-sky-400/10"
                  >Forskningspersonal</a
                >
                <a
                  (click)="logout()"
                  role="button"
                  class="-mx-3 block rounded-lg py-2.5 px-3 text-base font-semibold leading-6 text-sky-900 hover:bg-sky-400/10"
                  >Logga ut</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--  Main content portal  -->
<div class="bg-white pt-12 pb-20 px-4 sm:px-6 lg:pt-16 lg:pb-28 lg:px-8">
  <div class="relative mx-auto lg:max-w-7xl">
    <router-outlet></router-outlet>
  </div>
</div>
