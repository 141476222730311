import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-stepper-icons',
  templateUrl: './stepper-icons.component.html',
})
export class StepperIconsComponent implements OnInit {
  @Input() status: 'greencheck' | 'complete' | 'current' | 'upcoming' = 'upcoming';

  constructor() {}

  ngOnInit(): void {}
}
