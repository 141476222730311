<div class="mx-auto max-w-2xl border border-neutral-200 rounded-lg mt-4">
  <div class="p-6">
    <p class="text-base sm:text-lg font-medium">Logga in på minforskning.se</p>
    <p class="mt-4 text-sm text-gray-900">
      Du behöver logga in för att fortsätta. Loggar du in för första gången på minforskning.se får du skapa en personlig
      profil och ange dina kontaktuppgifter.
    </p>
    <div class="mt-8 flex justify-center">
      <button
        type="button"
        (click)="login()"
        class="inline-flex items-center gap-x-1.5 rounded-md bg-sky-600 px-3 py-1 text-sm font-semibold text-white shadow-sm sm:text-base hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600"
      >
        <img class="-ml-0.5 h-10 w-10" src="/assets/images/logos/bank-id-white.svg" alt="BankID" />
        Logga in på minforskning.se
      </button>
    </div>
  </div>
</div>
