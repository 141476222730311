import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Subscription, interval, of, timer } from 'rxjs';
import { switchMap, filter, takeWhile, retryWhen, delay, take, startWith, concatMap, takeUntil } from 'rxjs/operators';
import { throwError } from 'rxjs/internal/observable/throwError';
import { ConsentFlowService } from 'src/app/core/services/portal/consent-flow.service';
import { IConsentFlowsRequest } from 'src/app/core/models/consent.model';

@Component({
  selector: 'app-doctor-signature',
  templateUrl: './doctor-signature.component.html',
})
export class DoctorSignatureComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  isLoading: boolean = false;
  isComplete: boolean = false;
  errorMessage: string | null = null;

  @Output() stepComplete: EventEmitter<IConsentFlowsRequest> = new EventEmitter<IConsentFlowsRequest>();

  constructor(private _consentFlowService: ConsentFlowService) {}

  ngOnInit(): void {
    this.collectDoctorSignature();
  }

  ngOnDestroy(): void {
    this.stopPolling();
    this.subscription.unsubscribe();
  }

  completeStep(newConsent: IConsentFlowsRequest): void {
    this.stepComplete.emit(newConsent);
  }

  collectDoctorSignature(): void {
    this.isLoading = true;
    let statusComplete = false;
    const POLL_TIMEOUT = 60000;
    const polling$ = this._consentFlowService.consentTransitionLink$.pipe(
      filter((transitionLink) => transitionLink !== null),
      switchMap((transitionLink) =>
        interval(2000).pipe(
          startWith(0),
          switchMap(() => this._consentFlowService.collectSignature(transitionLink as string)),
          takeWhile((response) => {
            const isComplete = response.body?.status === 'complete';
            if (isComplete) {
              statusComplete = true;
            }
            return !isComplete;
          }, true),
          takeUntil(timer(POLL_TIMEOUT)),
          retryWhen((errors) =>
            errors.pipe(
              delay(1000),
              take(30),
              concatMap((error, index) =>
                index >= 29 ? throwError(() => new Error(`There was no result after 30 attempts`)) : of(error),
              ),
            ),
          ),
        ),
      ),
    );

    this.subscription.add(
      polling$.subscribe({
        next: (response) => {
          if (response.body?.status === 'complete') {
            this.stopPolling();
            this.isLoading = false;
            this.isComplete = true;
            this.completeStep(response.body);
          }
        },
        error: (error) => {
          console.error('Error while polling for doctor signature:', error);
          this.stopPolling();
          this.isLoading = false;
          this.isComplete = false;
          this.errorMessage =
            'Vi kunde inte hämta uppdateringar på grund av ett tekniskt fel. Vänligen klicka här för att försöka igen.';
        },
      }),
    );

    const timeout$ = timer(POLL_TIMEOUT).subscribe(() => {
      if (!statusComplete) {
        this.isLoading = false;
      }
    });

    this.subscription.add(timeout$);
  }

  stopPolling() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  restartPolling(): void {
    this.collectDoctorSignature();
  }
}
