import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { IConsent } from 'src/app/core/models/consent.model';
import { ConsentFlowService } from 'src/app/core/services/portal/consent-flow.service';
import { ConsentService } from 'src/app/core/services/portal/consent.service';

@Component({
  selector: 'app-family',
  templateUrl: './family.component.html',
})
export class FamilyComponent implements OnInit, OnDestroy {
  @Output() stepComplete: EventEmitter<any> = new EventEmitter();
  @Input() userProfile: any;

  flowOptions: any[] = [];
  selectedOption: string = '';
  children: any[] = [];
  ongoingChildren: any[] = [];
  selectedDependent: any = {};
  childrenWithConsents: any[] = [];
  signatureExists: boolean = false;
  guardianConsent: IConsent | null = null;
  errorMessage = {
    signatureExists: '',
    age: '',
  };

  private subscriptions = new Subscription();

  constructor(
    private _consentService: ConsentService,
    private _consentFlowService: ConsentFlowService,
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this._consentFlowService.consent$.subscribe((consent) => {
        this.guardianConsent = consent;
      }),
    );

    this.subscriptions.add(
      this._consentFlowService.usersChildren$.subscribe((children) => {
        if (children) {
          this.children = children.dependentsWithConsents;
          this.ongoingChildren = this.filterOngoingChildren(this.children);
          this.childrenWithConsents = this.filterSignedChildren(this.children);
        }
      }),
    );

    this.subscriptions.add(
      this._consentFlowService.resetFamilyFlow$.subscribe((resp) => {
        if (resp) {
          this.resetSelections();
        }
      }),
    );

    this.subscriptions.add(
      this._consentFlowService.adultAgeCheck$.subscribe((resp) => {
          this.generateConsentOptions(resp);
      })
    );
   // this.generateConsentOptions(true);
        
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  selectFlow(option: any) {
    this.selectedOption = option.value;
    if (this.selectedOption === 'patient') {
      this.nextStep();
    }
  }

  filterOngoingChildren(children: any[]): any[] {
    return children.filter((child) => child.consents[0]?.status !== 'parent-signed');
  }

  filterSignedChildren(children: any[]): any[] {
    return children.filter((child) => child.consents.length > 0 && child.consents[0].status === 'parent-signed');
  }

  selectDependent(person: any) {
    this.selectedDependent = person;
  }

  onContinueOtherParent(data: any) {
    if (!this.guardianSignatureExists(data)) {
      this.selectedDependent = data;
      this.nextStep();
    }
  }

  guardianSignatureExists(data: any): boolean {
    const signaturesExist = data.consents[0].signatures.some(
      (signature: any) => signature.personalNumber === this.userProfile?.username,
    );
    if (signaturesExist) {
      this.errorMessage.signatureExists = `Du har redan gett samtycke för ${data.dependent.firstName}. Den andra vårdnadshavaren behöver nu också logga in och samtycka för att detta samtycke ska bli giltigt.`;
      this.signatureExists = true;
    }
    return signaturesExist;
  }

  onChangeSelection(): void {
    this.resetSelections();
  }

  resetSelections(): void {
    this.children = [];
    this.selectedOption = '';
    this.selectedDependent.dependent = null;
  }

  nextStep() {
    const data = {
      patientPersonalNumber: this.selectedDependent.dependent?.personalNumber,
      signForOption: this.selectedOption,
      selectedDependent: this.selectedDependent,
    };
    this._consentFlowService.setDependent(this.selectedDependent);
    this.stepComplete.emit(data);
  }

  generateConsentOptions(allowAdult: boolean) {
    this.flowOptions = [
      {
        id: 2,
        title: 'Samtycke som vårdnadshavare',
        text: 'Samtyck för ditt barn att gå med i studien',
        value: 'family',
      },
    ];
    if (allowAdult) {
      this.flowOptions.unshift({
        id: 1,
        title: 'Samtycke för dig',
        text: 'Samtyck för dig själv att gå med i studien',
        value: 'patient',
      });
    }
  }
}
