import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  Type,
  ViewChildren,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ConsentService } from 'src/app/core/services/portal/consent.service';
import { FpiService } from 'src/app/core/services/portal/fpi.service';
import { LoginComponent } from '../consent-flow/login/login.component';
import { DynamicStepHostDirective } from 'src/app/shared/directives/dynamic-step-host.directive';
import { FpiQrcodeComponent } from '../consent-flow/fpi-qrcode/fpi-qrcode.component';
import { FinalStepComponent } from '../consent-flow/final-step/final-step.component';
import { ConsentFlowService } from 'src/app/core/services/portal/consent-flow.service';
import { IUser } from 'src/app/core/models/user.model';
import { IFpi } from 'src/app/core/models/fpi.model';
import { ISettings } from 'src/app/core/models/study-settings.model';
import { EConsentStatus, EFlowType, IConsentFlowsRequest } from 'src/app/core/models/consent.model';
import { DoctorSignatureComponent } from '../consent-flow/doctor-signature/doctor-signature.component';
import { Subscription, firstValueFrom } from 'rxjs';
import { FamilyComponent } from '../consent-flow/family/family.component';

interface IStep<T = any> {
  title: string;
  component: Type<any>;
  status?: 'complete' | 'current' | 'upcoming';
  data?: T;
  condition?: false | (() => boolean | Promise<boolean>) | (() => boolean | Promise<boolean>)[];
  onStepComplete?: (newData: T) => void;
}

@Component({
  selector: 'app-consent-stepper',
  templateUrl: './consent-stepper.component.html',
})
export class ConsentStepperComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChildren(DynamicStepHostDirective) stepHosts!: QueryList<DynamicStepHostDirective>;
  subscriptions: Subscription[] = [];
  private _fpi!: IFpi;
  private _settings?: ISettings;
  _userProfile?: IUser;
  stepsPatientFlow: IStep[] = [];
  stepsFamilyFlow: IStep[] = [];
  stepsDoctorPatientFlow: IStep[] = [];
  currentSteps: IStep[] = [];
  currentStepIndex: number = 0;
  patientPersonalNumber: string = '';
  siteId: string = '';
  errorMessage = {
    signatureExists: '',
    age: '',
  };

  @Input()
  set userProfile(value: IUser | undefined) {
    this._userProfile = value;
  }

  get userProfile(): IUser | undefined {
    return this._userProfile;
  }

  @Input() consentFlowType?: EFlowType;

  @Input()
  set fpi(value: IFpi) {
    this._fpi = value;
  }

  get fpi(): IFpi {
    return this._fpi;
  }

  @Input()
  set settings(value: ISettings | undefined) {
    this._settings = value;
  }

  get settings(): ISettings | undefined {
    return this._settings;
  }

  constructor(
    private fpiService: FpiService,
    private _consentService: ConsentService,
    private _consentFlowService: ConsentFlowService,
    private cdr: ChangeDetectorRef,
    public sanitizer: DomSanitizer,
  ) {}

  ngOnInit(): void {
    this.initializeSteps();
    this.evaluateStepConditions();
  }

  ngAfterViewInit() {
    this.subscriptions.push(
      this.stepHosts.changes.subscribe(() => {
        this.loadComponents();
        this.cdr.detectChanges();
      }),
    );
    this.loadComponents();
  }

  /*
   
    Functions

  */

  private initializeSteps(): void {
    this.stepsPatientFlow = [
      {
        title: 'Logga in på minforskning.se',
        component: LoginComponent, // TODO: Add CreateAccountComponent step
        status: 'current',
        condition: this.accountExists,
      },
      {
        title: 'Samtyck till studien',
        component: FpiQrcodeComponent,
        status: 'upcoming',
        data: {
          fpi: this.fpi,
          patientPersonalNumber: this.userProfile?.username,
          consentFlowType: this.consentFlowType,
        },
        condition: () => this.fpiReadAndConsentInitiated(EConsentStatus.Initiated),
        onStepComplete: this.handleFpiStepComplete,
      },
      {
        title: this.settings?.symptomUrl ? 'Nästa steg i studien' : 'Klar',
        component: FinalStepComponent,
        status: 'upcoming',
        data: { fpi: this.fpi, settings: this.settings, consentFlowType: this.consentFlowType },
      },
    ];
    this.stepsFamilyFlow = [
      {
        title: 'Logga in på minforskning.se',
        component: LoginComponent,
        status: 'upcoming',
        condition: this.accountExists,
      },
      {
        title: 'Välj vem du vill signera för',
        component: FamilyComponent,
        status: 'upcoming',
        condition: [() => this.fpiReadAndConsentInitiated(EConsentStatus.Initiated), () => this.signForSelected()],
        data: { userProfile: this.userProfile },
        onStepComplete: this.handleFamilyStepComplete,
      },
      {
        title: 'Samtyck till studien',
        component: FpiQrcodeComponent,
        status: 'upcoming',
        condition: () => this.fpiReadCheck(),
        data: {
          fpi: this.fpi,
          patientPersonalNumber: this.userProfile?.username,
          consentFlowType: this.consentFlowType,
        },
        onStepComplete: this.handleFpiStepComplete,
      },
      {
        title: 'Nästa steg',
        component: FinalStepComponent,
        status: 'upcoming',
        data: { fpi: this.fpi, settings: this.settings, consentFlowType: this.consentFlowType },
        onStepComplete: this.handleNextSignature,
      },
    ];

    this.stepsDoctorPatientFlow = [
      { title: 'Logga in på minforskning.se', component: LoginComponent, condition: this.accountExists },
      {
        title: 'Samtyck till studien',
        component: FpiQrcodeComponent,
        status: 'upcoming',
        data: {
          fpi: this.fpi,
          patientPersonalNumber: this.userProfile?.username,
          consentFlowType: this.consentFlowType,
        },
        condition: () => this.fpiReadAndConsentInitiated(EConsentStatus.PatientSigned),
        onStepComplete: this.handleFpiStepComplete,
      },
      {
        title: 'Läkaren signerar',
        component: DoctorSignatureComponent,
        status: 'upcoming',
        condition: () => this.consentInitiatedCheck(EConsentStatus.Complete),
        onStepComplete: this.handleDoctorSignatureStepComplete,
      },
      {
        title: this.settings?.symptomUrl ? 'Nästa steg i studien' : 'Klar',
        component: FinalStepComponent,
        status: 'upcoming',
        data: { fpi: this.fpi, settings: this.settings, consentFlowType: this.consentFlowType },
      },
    ];
    this.selectFlow(this.consentFlowType);
    this.updateStepStatuses();
  }

  async evaluateStepConditions(): Promise<void> {
    let stepIndexToShow = 0;
    let allConditionsMet = true;

    for (let i = 0; i < this.currentSteps.length; i++) {
      const step = this.currentSteps[i];
      if (Array.isArray(step.condition)) {
        for (const condition of step.condition) {
          const conditionMet = await Promise.resolve(condition());

          if (!conditionMet) {
            stepIndexToShow = i;
            allConditionsMet = false;
            break;
          }
        }
        if (!allConditionsMet) {
          break;
        }
      } else if (step.condition) {
        const conditionMet = await Promise.resolve(step.condition());
        if (!conditionMet) {
          stepIndexToShow = i;
          allConditionsMet = false;
          break;
        }
      }
      if (allConditionsMet) {
        step.status = 'complete';
      }
    }

    if (allConditionsMet) {
      stepIndexToShow = this.currentSteps.length - 1;
    }

    this.currentStepIndex = stepIndexToShow;
    this.loadComponents();
    this.updateStepStatuses();
  }

  selectFlow(scenario?: EFlowType) {
    switch (scenario) {
      case 'patient':
        this.currentSteps = this.stepsPatientFlow;
        break;
      case 'family':
        this.currentSteps = this.stepsFamilyFlow;
        break;
      case 'doctor-patient':
        this.currentSteps = this.stepsDoctorPatientFlow;
        break;
      default:
        this.currentSteps = this.stepsPatientFlow;
    }
  }

  updateStepStatuses() {
    this.currentSteps = this.currentSteps.map((step, index) => ({
      ...step,
      status: index < this.currentStepIndex ? 'complete' : index === this.currentStepIndex ? 'current' : 'upcoming',
    }));
  }

  loadComponents() {
    const stepHostArray = this.stepHosts.toArray();
    const viewContainerRef = stepHostArray[this.currentStepIndex]?.viewContainerRef;

    if (viewContainerRef && this.currentSteps[this.currentStepIndex]) {
      const step = this.currentSteps[this.currentStepIndex];

      const stepComponent = step.component;

      viewContainerRef.clear();
      const componentRef = viewContainerRef.createComponent(stepComponent);

      if (this.patientPersonalNumber) {
        step.data.patientPersonalNumber = this.patientPersonalNumber;
      }
      if (step.data) {
        Object.assign(componentRef.instance, step.data);
      }

      if (step.onStepComplete) {
        componentRef.instance.stepComplete.subscribe(step.onStepComplete.bind(this));
      }
    }
  }

  goToNextStep() {
    if (this.currentStepIndex < this.currentSteps.length - 1) {
      this.currentStepIndex++;
      this.updateStepStatuses();
      this.loadComponents();
    }
  }

  goToPreviousStep() {
    if (this.currentStepIndex > 0) {
      this.currentStepIndex--;
    }
  }

  accountExists = (): boolean => {
    return !!(this.userProfile?.email && this.userProfile?.phone);
  };

  fpiReadAndConsentInitiated = async (checkConsentStatus: EConsentStatus): Promise<boolean> => {
    const fpiRead = await this.checkFPIReadStatus();
    if (!fpiRead) return false;
    const consentStatus = await this.checkConsentStatus(checkConsentStatus);
    return consentStatus;
  };

  signForSelected = async (): Promise<boolean> => {
    return false;
  };

  fpiReadCheck = async (): Promise<boolean> => {
    return await this.checkFPIReadStatus();
  };

  consentInitiatedCheck = async (checkConsentStatus: EConsentStatus): Promise<boolean> => {
    const consentStatus = await this.checkConsentStatus(checkConsentStatus);
    return consentStatus;
  };

  handleNextSignature = () => {
    this.currentStepIndex = this.currentStepIndex - 2;
    this.updateStepStatuses();
    this.loadComponents();
    this._consentFlowService.setResetFamilyFlow(true);
    this._consentFlowService.setConsentTransitionLink(null);
    this._consentFlowService.setConsentInitiated(null);
    this.getConsent();
    const { id: fpiId, studyId, siteId } = this.fpi;

    this._consentService.getDependents({ studyId, siteId, fpiId }).subscribe();
  };

  handleFpiStepComplete = (newData: IConsentFlowsRequest) => {
    this._consentFlowService.setConsentFlow(newData);
    this.goToNextStep();
  };

  handleDoctorSignatureStepComplete = (newData: IConsentFlowsRequest) => {
    this._consentFlowService.setConsentFlow(newData);
    this.goToNextStep();
  };

  handleFamilyStepComplete = (newData: any) => {
    if (newData.selectedDependent.consents?.length > 0) {
      this._consentFlowService.setConsentTransitionLink(newData.selectedDependent.consents[0].transitionLink);
      this._consentFlowService.setConsentInitiated(newData.selectedDependent.consents[0]);
    }

    this.goToNextStep();
  };

  /*
   
    APIS

  */
  async checkFPIReadStatus(): Promise<boolean> {
    if (!this.fpi || !this.userProfile) {
      return false;
    }

    const request = {
      fpiId: this.fpi.id,
      studyId: this.fpi.studyId,
      siteId: this.fpi.siteId,
      personalNumber: this.userProfile.username,
    };

    try {
      const { exists } = await firstValueFrom(this.fpiService.getReadFPI(request));
      this._consentFlowService.setFpiRead(exists);
      return exists;
    } catch (error) {
      console.error('Error fetching FPI read status', error);
      return false;
    }
  }

  async getConsent() {
    if (!this.fpi || !this.userProfile) {
      return null;
    }
    const data = {
      studyId: this.fpi.studyId,
      siteId: this.fpi.siteId,
      fpiId: this.fpi.id,
      personalNumber: this.userProfile.username,
    };
    const [consent] = await firstValueFrom(this._consentService.getPatientConsent(data));
    try {
      if (consent.transitionLink) {
        this._consentFlowService.setConsentTransitionLink(consent.transitionLink);
        this._consentFlowService.setConsentInitiated(consent);
      }
      if (consent.status === 'complete') {
        this._consentFlowService.setConsent(consent);
      }
    } catch {
      return null;
    }
    return consent;
  }

  async checkConsentStatus(checkForStatus: EConsentStatus): Promise<boolean> {
    if (!this.fpi || !this.userProfile) {
      return false;
    }
    try {
      const consent = await this.getConsent();

      const isDoctorFlow = this.consentFlowType?.includes('doctor');

      if (consent?.status === checkForStatus) {
        return isDoctorFlow ? true : false;
      } else if (consent?.status === 'complete') {
        return true;
      }
      return false;
    } catch (error) {
      return false;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    });
  }
}
