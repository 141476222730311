import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { environment } from 'src/environments/environment';
import { IUserInformationResponse } from '../../models/user.model';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private apiUrlPortal: string = environment.apiUrlPortal;
  private apiUrl: string = environment.apiUrl;

  constructor(
    private http: HttpClient,
    private keycloak: KeycloakService,
  ) {}

  //  -- check user auth
  async isAuthenticated() {
    return await this.keycloak.isLoggedIn();
  }
  //  -- Get user info
  getUserInfo(personalNumber: string): Observable<IUserInformationResponse> {
    return this.http.get<IUserInformationResponse>(`${this.apiUrl}/user/user-info/${personalNumber}`);
  }
  getUserProfileInfo(personalNumber: string) {
    return this.http.get(`${this.apiUrl}/user/profile-info?username=${personalNumber}`);
  }
  //  -- update user info
  updateProfile(data: any) {
    return this.http.put(`${this.apiUrl}/user/update`, data);
  }
  //  -- DANGER: Delete user account
  deleteAccount(personalNumber: string) {
    return this.http.delete(`${this.apiUrl}/user/delete?username=${personalNumber}`);
  }
  //  -- my studies (all)
  getMyStudies() {
    return this.http.get<any>(`${this.apiUrlPortal}/user/my-studies`);
  }
  //  -- my studies (specific)
  getMyStudy(studyId: string) {
    return this.http.get<any>(`${this.apiUrlPortal}/user/my-studies/${studyId}`);
  }
  //  -- get user results
  getUserResults(studyId: string, personalNumber: string) {
    return this.http.get<any>(
      `${this.apiUrlPortal}/studies/studyId/${studyId}/personalNumber/${personalNumber}/results`,
    );
  }
  //  -- assign user role (patient)
  assignUserRole(username: string, data: any) {
    return this.http.put<any>(`${this.apiUrl}/user/assign-roles/${username}/patient`, data);
  }
  //  -- Adds identityproviders to user
  addIdpProviders() {
    return this.http.get<any>(`${this.apiUrl}/user/idp`);
  }
  //  -- Adds identityproviders to user
  addAttributes(data: any) {
    return this.http.put<any>(`${this.apiUrl}/user/attributes`, data);
  }
  //  -- check if email exists
  checkEmailExists(email: string) {
    return this.http.get<any>(`${this.apiUrl}/user/search/${email}`);
  }
}
